import React from 'react'
import { Switch } from "@headlessui/react";
import Tabular from './Tabular';
import Graphical from './Graphical';

const Space = () => {
    const [enabled, setEnabled] = React.useState(false);
    const [selected, setSelected] = React.useState("Graphical view");
      return (
        <div className="flex flex-col font-nunito h-full space-y-8">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row w-1/2 space-x-8 font-bold text-[20px]">
              <h2>{selected}</h2>
            </div>
            <div className="flex flex-row space-x-2">
              <p>Switch view</p>
              <Switch
                checked={enabled}
                onChange={()=>
                  {
                    setEnabled(!enabled)
                    setSelected(enabled ? "Tabular view" : "Graphical view")
                  }}
                className={`${
                  enabled ? "bg-black" : "bg-black"
                } relative inline-flex h-6 w-11 items-center rounded-full border`}
              >
                <span
                  className={`${
                    enabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
          <div className="w-full h-fit">
            {selected === "Graphical view" ? <Graphical /> : <Tabular />}
            </div>
    </div>
    
      )    
}

export default Space