import React from "react";
import Modal from "../../../../components/modal/Modal";

// scheduled hours , i.e dummy data

const hours = [
  { from: "7:00 am", to: "9:00 am" },
  { from: "9:00 am", to: "11:00 am" },
  { from: "11:00 am", to: "1:00 pm" },
  { from: "1:00 pm", to: "3:00 pm" },
];

const pricing = [
  { slot_name: "DJ mention", unit: "seconds", pricePerUnit: "100" },
  { slot_name: "play audio", unit: "seconds", pricePerUnit: "200" },
  { slot_name: "play advertisement", unit: "seconds", pricePerUnit: "300" },
];

const ShowDetails = ({ openDetails, setOpenDetails }) => {
  return (
    <div>
      {/* modal for details of show */}
      {openDetails && (
        <Modal
          isOpen={openDetails}
          onClose={() => setOpenDetails(false)}
          Style={
            "scroll-modal  mt-8 flex mx-auto h-full max-h-[80%] w-max max-w-[60%] overflow-y-scroll rounded-lg"
          }
        >
          <Modal.Header>Show details</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col">
                <h2 className="font-bold">Show title</h2>
                <p>Umuturanyi series</p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold">Show type</h2>
                <p>Entertainment</p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold">Description</h2>
                <p>
                  it is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English
                </p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold">Schedule</h2>
                <div className="flex flex-col border rounded-lg px-4 space-y-2 w-[500px]">
                  <h2 className="font-bold">Everyday</h2>
                  {hours.map((hour, index) => {
                    return (
                      <div className="flex flex-row justify-between items-center w-full">
                        <p>From: {hour.from}</p>
                        <p>To: {hour.to}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold">Pricing</h2>
                <div className="flex flex-col rounded-lg px-4 space-y-2 w-[500px]">
                  <div className="flex flex-row justify-between items-center w-full">
                    <table className="table-fixed w-full">
                      <thead>
                        <tr>
                          <th className="px-4  py-2 text-left">Slot name</th>
                          <th className="px-4 py-2 text-left">Unit</th>
                          <th className="px-4 py-2 text-left">Price/unit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pricing.map((price, index) => {
                          return (
                            <tr className=" px-8">
                              <td className="px-4 py-2">{price.slot_name}</td>
                              <td className="px-4 py-2">{price.unit}</td>
                              <td className="px-4 py-2">
                                {price.pricePerUnit}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <h2 className="font-bold">Sample</h2>
                <div>
                  <video width="320" height="240" controls>
                    <source
                      src="https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.DismissButton>Close</Modal.DismissButton>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ShowDetails;
