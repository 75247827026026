import React, { useState } from "react";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import MultiSelect from "../../components/MultiSelect";
import { AiFillMinusCircle } from "react-icons/ai";
import { HiPlus } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import TextArea from "../../components/TextArea";

const Step5 = ({ profile, setProfile }) => {
  // configure the dynamic form for address
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const options = [
    { value: "option1", label: "Konvey Direct" },
    { value: "option2", label: "MTN-Rwanda" },
    { value: "option3", label: "Airtel-Rwanda" },
    { value: "option4", label: "RBA" },
    { value: "option5", label: "TV1 & R1" },
    { value: "option6", label: "Aflink" },
    { value: "option7", label: "BTN" },
    { value: "option8", label: "Rwanda Foam" },
    { value: "option9", label: "Prime TV" },
    { value: "option10", label: "TV10 & R10" },
  ];


  const addService = () => {
    setProfile((prevData) => {
      return {
        ...prevData,
        services: [...prevData.services, { name: "", description: "" }],
      };
    });
  };

  const handleServiceName = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newService = [...prevData.services];
      newService[index] = {
        ...newService[index],
        name: value,
      };
      return {
        ...prevData,
        services: newService,
      };
    });
  };

  const handleServiceDescription = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newService = [...prevData.services];
      newService[index] = {
        ...newService[index],
        description: value,
      };
      return {
        ...prevData,
        services: newService,
      };
    });
  };

  const handleRemoveService = (index) => {
    setProfile((prevData) => {
      const serviceArray = [...prevData.services];
      serviceArray.splice(index, 1);
      return {
        ...prevData,
        services: serviceArray,
      };
    });
  };

  const handleClients = (clients) => {
    setProfile((prevData) => {
      return {
        ...prevData,
        clients,
      };
    });
  };

  //end of configuration

  return (
    <div className=" bg-secondary ">
      <div className="flex flex-col w-full px-8 py-8 bg-white font-nunito">
        <div className="flex flex-col w-full  my-8 md:my-auto lg:my-4">
          <div className="flex flex-row">
            <MdOutlineMiscellaneousServices size={20} className="mr-[16px]" />
            <h1 className="text-2xl font-normal text-primary">Services</h1>
          </div>
          <div>
            <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
              click add icon to add your services
            </h1>
          </div>

          <div className="w-full flex flex-col space-y-8">
            <div className="w-full relative flex-row">
              <div className="flex flex-col rounded-sm w-full bg-white drop-shadow-lg px-6 pb-12">
                {profile.services.map((item, index) => {
                  return (
                    <div key={index} className="relative flex flex-row ">
                      <div className="h-full w-full flex flex-col">
                        <Input
                          labelText={"Service"}
                          labelFor={`serviceName ${index + 1} `}
                          name={`serviceName ${index + 1} `}
                          type={"text"}
                          placeholder={"enter your service name"}
                          customClass="w-full my-2"
                          register={register}
                          onChange={(e) => handleServiceName(e, index)}
                          value={item.name}
                        />{" "}
                        <TextArea
                          labelText={"Description"}
                          labelFor={`serviceDescr ${index + 1} `}
                          name={`serviceDescr ${index + 1} `}
                          placeholder={"enter description"}
                          customClass="w-full mb-2"
                          inputStyle="h-[90px]"
                          register={register}
                          onChange={(e) => handleServiceDescription(e, index)}
                          value={item.description}
                        />{" "}
                      </div>

                      <div className="w-fit h-fit mt-3">
                        <AiFillMinusCircle
                          color="#BDBDBD"
                          size={25}
                          className={`cursor-pointer ${
                            index === 0 ? "hidden" : "visible"
                          }`}
                          onClick={() => handleRemoveService(index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button
                type="button"
                content={<HiPlus color="#FBFBFB" size={20} />}
                loading={false}
                onClick={addService}
                btnColor="primary"
                Style={
                  "absolute right-[0px] py-[3px] bottom-[0px] text-white w-fit px-[6px] m-2"
                }
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="h-full flex items-center mt-1 mr-[16px]">
                  <IoIosPeople size={20} />
                </div>

                <h1 className="text-2xl font-normal text-primary">Clients</h1>
              </div>
              <div>
                <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
                  select clients to show up on your profile
                </h1>
              </div>
              <MultiSelect
                className="w-full mt-4"
                isLoading={false}
                placeholder={"Select channel"}
                defaultValue={options}
                options={options}
                register={{
                  control,
                  name: "district",
                  rules: { required: "Province is required" },
                }}
                onChange={(e) => handleClients(e)}
                value={profile.clients}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step5;
