// by David
import React, { useState, useEffect, useRef } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useTable,
  useSortBy,
} from 'react-table';
import { BiSearch } from 'react-icons/bi';
import { useReactToPrint } from 'react-to-print';
import DataPagination from './DataPagination';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import TableFilter from 'react-table-filter';
import 'react-table-filter/lib/styles.css';

export default function Table({
  name,
  columns,
  data,
  setAdInfo,
  setBillInfo,
  rowSelected,
  setRowSelected,
  setToggleRowClick,
  title,
  hideSearch = false,
}) {
  const [filteredData, setFilteredData] = useState([]);

  const sortedColumns = React.useMemo(() => [...columns], [columns]);

  const TableInstance = useTable(
    {
      data: filteredData,
      columns: sortedColumns,
      initialState: { pageSize: 3 },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,

    setGlobalFilter,
    getTableBodyProps,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = TableInstance;

  const { globalFilter, pageIndex, pageSize } = state;
  const [show, setShow] = useState(false);

  const updateFilterHandler = (newData) => {
    setFilteredData(newData);
  };

  useEffect(() => {
    filterRef.current.reset(data, true);
    setFilteredData(data);
  }, [data]);

  // limit the number of rows per page

  const contentFx = (id, info) => {
    setRowSelected(id);
    setToggleRowClick();
    if (name === 'Campaigns') {
      return setAdInfo(info);
    } else if (name === 'Billing') {
      return setBillInfo(info);
    } else if (name === '') {
    }
  };

  const filterRef = useRef(null);

  // export to pdf

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: title,
    pageStyle: 'print',
  });

  return (
    <div className="w-full flex flex-col  rounded mt-4  mb-16 p-4 bg-white font-nunito">
      <div
        className={`w-full ${
          hideSearch ? 'hidden' : 'flex '
        }  flex-col lg:flex-row lg:justify-end  mb-8`}
      >
        <div className="flex justify-end gap-2 w-full lg:w-1/2 ">
          <div className="flex  w-full lg:w-[60%] border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
            <BiSearch size={30} />
            <input
              defaultValue={globalFilter || ''}
              placeholder="Search"
              className="w-full rounded outline-none font-nunito"
              onChange={(e) => {
                setGlobalFilter(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-row max-h-[40px] lg:justify-center items-center lg:ml-4 space-x-2">
          <div>
            <p>Export Data:</p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setShow(!show);
              }}
              className="bg-black text-white px-4 w-max rounded "
            >
              Export
            </button>
            <ul
              className={`absolute  mt-2  shadow-lg z-10 ${
                show ? 'block' : 'hidden'
              } group-hover:block w-[100px]`}
            >
              <svg
                className="block fill-current  w-4 h-4 absolute text-white left-0 top-0 ml-3 -mt-3 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              </svg>
              <li className="p-1 whitespace-no-wrap  text-sm text-gray-600  hover:bg-[#D9D9D9] cursor-pointer">
                <a className="px-2 py-1">
                  <span className="">
                    <DownloadTableExcel
                      filename="table"
                      sheet={title}
                      currentTableRef={componentRef.current}
                    >
                      <button>XLS</button>
                    </DownloadTableExcel>
                  </span>
                </a>
              </li>
              <li className="p-1 whitespace-no-wrap text-sm  text-gray-600  hover:bg-[#D9D9D9] cursor-pointer">
                <a className="px-2 py-1">
                  <span className="">
                    <button type="button" onClick={handlePrint}>
                      PDF
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='overflow-x-auto lg:overflow-x-visible'>
        <table
          {...getTableProps()}
          className="w-full overflow-x-auto  border-b border-spacing-2 "
          ref={componentRef}
        >
          <thead className="bg-[#F8F9FA]">
            {headerGroups.map((headerGroup) => (
              <TableFilter
                {...headerGroup.getHeaderGroupProps()}
                style={{ color: 'black' }}
                rows={data}
                onFilterUpdate={updateFilterHandler}
                ref={filterRef}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column)}
                    className="p-[15px] text-start"
                    filterkey={column.Header}
                    showsearch={'true'}
                  >
                    <div className="flex justify-start items-center gap-2">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </TableFilter>
            ))}
          </thead>
          {data.length > 0 && (
            <tbody {...getTableBodyProps()} className="">
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`border border-separate border-spacing-2 border-black cursor-pointer ${
                      rowSelected === row.id ? ' bg-black' : ''
                    }`}
                    onClick={() => contentFx(row.id, row.original)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className="flex  p-[15px]  font-semibold font-nunito">
                            {cell.render('Cell')}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {name !== 'Home' ? (
        <DataPagination
          pageOptions={pageOptions}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          columnLength={columns.length}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
        />
      ) : (
        ''
      )}
    </div>
  );
}
