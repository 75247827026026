import React, { useState } from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

function SubMenu({ heading, body, key, Style }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`${Style}`} key={key}>
      <div
        className="flex items-center justify-between p-1 cursor-pointer  transition-all duration-300 ease-in-out"
        onClick={handleToggle}
      >
        {heading}

        <FaSortDown
          className={` transform duration-[400ms] ${
            isExpanded ? 'rotate-180 mt-2' : 'rotate-0 mb-2'
          }`}
          size={20}
        />
      </div>
      {isExpanded && <div className="p-1">{body}</div>}
    </div>
  );
}

export default SubMenu;
