import React, { useState } from "react";
import Logo from "../../Assets/Konvey.jpeg";
import { RiMoreFill } from "react-icons/ri";
import { Popover } from "@headlessui/react";

const Header = ({ accountProfile }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between">
      <div className="">
        <img src={Logo} alt="logo" />
      </div>
      <div className="w-full lg:w-1/2 flex flex-row  justify-between items-center">
        <h2 className="w-fit font-[700]">{accountProfile}</h2>
        <div className="flex flex-col space-y-8">
          <Popover className="relative flex flex-row font-bold">
            <div>
              <Popover.Button>
                <RiMoreFill size={30} className="cursor-pointer" />
              </Popover.Button>
            </div>
            <div>
              <Popover.Panel className="absolute z-10">
                <div className="flex flex-col h-max text-[16px] bg-[#DAD7CD] bg-opacity-30 border border-lg border-white ">
                  <ul className="flex flex-col space-y-2 ">
                    <li className="hover:bg-[#D9D9D9] bg-cover px-4 py-1 cursor-pointer">
                      <div className="flex flex-row space-x-4">Edit</div>
                    </li>
                    <li className="hover:bg-[#D9D9D9] bg-cover px-4  py-1 cursor-pointer">
                      <div>
                        Share
                      </div>
                    </li>
                  </ul>
                </div>
              </Popover.Panel>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header;

