import { ProfileTypes } from "../types";

const initialState = {
    profile: [],
  };
  
  export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
      case ProfileTypes.ADD_PROFILE_SUCCESS:
        return {
          ...state,
          profile: action.payload,
        };
      case ProfileTypes.ADD_PROFILE_ERROR:
        return {
          ...state,
          profile: action.payload,
        };
      default:
        return state;
    }
  };
  