import React, { useState, useEffect, useRef, Fragment } from 'react';
import Table from '../components/tableComponent/Table';
import CampaignDetails from '../modals/campaignModals/CampaignDetails';
import RejectReason from '../modals/campaignModals/RejectReason';
import Button from '../components/Button';
import { campaigns } from '../seeds/Data';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';

const Campaigns = ({ setActivePage }) => {
  const [detailsModal, setDetailsModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  // let [referenceElement, setReferenceElement] = useState(null);
  // let [popperElement, setPopperElement] = useState();

  // let { styles, attributes } = usePopper(referenceElement, popperElement, {
  //   placement: 'left-start',
  // });

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.name}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Client',
      accessor: 'client',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-2 items-center">
            <div className="h-[30px] w-[60px]">
              <img
                src={row.original.client.logo}
                alt=""
                className="h-[30px] w-[60px]  object-contain"
              />
            </div>
            <h1 className="font-bold">{row.original.client.name}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Space',
      accessor: '',
      Cell: ({ row }) => {
        const end = row.original.space.length - 1;
        return (
          <div className="flex flex-row flex-wrap">
            {row.original.space.map((space, i) => {
              return (
                <h1 className="mr-1">
                  {space}
                  {i === end ? '.' : ','}
                </h1>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => {
        return <h1 className={``}>{row.original.status}</h1>;
      },
    },

    {
      Header: 'Action',
      className: 'action-column',
      Cell: () => {
        return (
          <div className="flex flex-row ">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className="absolute z-10  "
                  // style={styles.popper}
                  // {...attributes.popper}
                >
                  <div className="flex flex-col bg-white shadow-md border border-black/10 w-[100px] rounded-sm text-xs">
                    <h1
                      className="p-2 hover:bg-[#D9D9D9] text-center"
                      onClick={() => setDetailsModal(!detailsModal)}
                    >
                      View details
                    </h1>
                    <h1 className="p-2 hover:bg-[#D9D9D9] text-center">
                      Accept
                    </h1>
                    <h1
                      className="p-2 hover:bg-[#D9D9D9] text-center"
                      onClick={() => setReasonModal(!reasonModal)}
                    >
                      Reject
                    </h1>
                    <h1 className="p-2 hover:bg-[#D9D9D9] text-center">
                      Reconsider
                    </h1>

                    <h1 className="p-2 hover:bg-[#D9D9D9] text-center">
                      Mark as completed
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
            <div className="flex flex-col space-y-8"></div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setActivePage('Campaigns');
  }, []);
  return (
    <>
      <CampaignDetails
        show={detailsModal}
        setShow={() => setDetailsModal(!detailsModal)}
      />
      <RejectReason
        show={reasonModal}
        setShow={() => setReasonModal(!reasonModal)}
      />
      <div className="flex flex-col font-nunito px-4 bg-white">
        <div className="flex flex-col mt-4">
          <h1 className="text-3xl font-bold w-fit">Campaigns</h1>
          <h1 className="text-base font-bold w-fit">
            All campaigns({campaigns.length})
          </h1>
        </div>

        <div className="">
          <Table columns={columns} data={campaigns} />
        </div>
      </div>
    </>
  );
};

export default Campaigns;
