import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import Background from "../../Assets/Background.png";
import Button from "../../components/Button";
import person from "../../Assets/person.jpg";
import { IoMdCamera } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import { HiPlus } from "react-icons/hi";

const Step4 = ({ profile, setProfile }) => {
  const [image, setImage] = useState("");
  const [background, setBackground] = useState("");
  const {
    register,
    // handleSubmit,
    formState: { errors },
    // reset,
  } = useForm();

  const handleBio = (e) => {
    const { value } = e.target;
    setProfile((prevData) => {
      return {
        ...prevData,
        bio: value,
      };
    });
  };

  const addLinks = () => {
    setProfile((prevData) => {
      return {
        ...prevData,
        links: [...prevData.links, { title: "", link: "", description: "" }],
      };
    });
  };

  const handleLinkTitle = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newLink = [...prevData.links];
      newLink[index] = {
        ...newLink[index],
        title: value,
      };
      return {
        ...prevData,
        links: newLink,
      };
    });
  };

  const handleLink = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newLink = [...prevData.links];
      newLink[index] = {
        ...newLink[index],
        link: value,
      };
      return {
        ...prevData,
        links: newLink,
      };
    });
  };

  const handleLinkDescription = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newLink = [...prevData.links];
      newLink[index] = {
        ...newLink[index],
        description: value,
      };
      return {
        ...prevData,
        links: newLink,
      };
    });
  };

  const handleRemoveLink = (index) => {
    setProfile((prevData) => {
      const linkArray = [...prevData.links];
      linkArray.splice(index, 1);
      return {
        ...prevData,
        links: linkArray,
      };
    });
  };

  const handleWebsite = (e) => {
    const { value } = e.target;
    setProfile((prevData) => {
      return {
        ...prevData,
        website: value,
      };
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
      setProfile((prevData) => {
        const newProfileImage = { ...prevData.profile };
        newProfileImage[0].image = reader.result;
        return {
          ...prevData,
          profile: newProfileImage,
        };
      });
      setImage(reader.result);
    };
  };

  const handleBackgroundUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBackground(reader.result);
      setProfile((prevData) => {
        const newBackgroundImage = { ...prevData.profile };
        newBackgroundImage[0].background = reader.result;
        return {
          ...prevData,
          profile: newBackgroundImage,
        };
      });
      setBackground(reader.result);
    };
  };

  return (
    <div className="bg-secondary">
      <div className={`flex flex-col bg-secondary  font-nunito pt-4 w-full `}>
        <div className="relative flex flex-col items-center w-full h-[180px]">
          <img
            src={background ? background : Background}
            alt=""
            className="object-cover h-[180px] w-[700px]"
          />
          <div className="absolute  bottom-[-50px] h-[100px] w-[100px] rounded-full left-1/2 transform -translate-x-1/2">
            <img
              src={image ? image : person}
              alt=""
              className="h-[100px] w-[100px] rounded-full object-cover"
            />
          </div>
          <div className="flex absolute lg:left-[56%] md:left-[66%] left-[70%] cursor-pointer transform -translate-x-1/2 bottom-[-25px] w-sm h-min overflow-hidden">
            <IoMdCamera
              size={25}
              className="cursor-pointer mt-2 w-full "
            />
            <div className="absolute w-2 transform ">
              <input
                type="file"
                name="profile"
                accept="image/*"
                onChange={handleImageUpload}
                size={2}
                className="cursor-pointer relative opacity-0 "
              />
            </div>
          </div>

          <div className="flex absolute right-[2%] cursor-pointer mt-2 overflow-hidden">
            <IoMdCamera size={25} className="w-full" />
            <div className="absolute w-2 transform">
              <input
                type="file"
                name="background"
                accept="image/*"
                onChange={handleBackgroundUpload}
                size={2}
                className="cursor-pointer relative opacity-0"
              />
            </div>
          </div>
        </div>
        <div
          className="w-full relative flex
      flex-col items-center mt-16"
        >
          <h1 className="font-bold text-lg">Kayitare Prince</h1>
          <h2 className="font-bold text-sm">Advertiser</h2>
        </div>

        <div className="flex flex-col mt-16">
          <div className="flex flex-col">
            <h1 className="font-semibold text-lg"> Describe yourself</h1>
            <h2 className="text-sm">
              What makes you special? Don't think too hard, feel free.
            </h2>
            <TextArea
              name={"bio"}
              type={"text"}
              isRequired={false}
              placeholder={"your bio"}
              customClass="w-full  mb-2"
              inputStyle="h-[90px]"
              register={register}
              onChange={(e) => handleBio(e)}
              value={profile.bio}
            />{" "}
          </div>
          <div className="flex flex-col mt-2 ">
            <h1 className="font-semibold">My links</h1>
            <div className="flex flex-row relative ">
              <div className="pt-2 pb-4 px-4 shadow-lg rounded-md bg-secondary w-full">
                {profile.links.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-row mb-12 bg-white p-4 rounded-md"
                    >
                      <div className="flex flex-col w-full">
                        <Input
                          labelText="Title"
                          labelFor={`linkTitle ${index + 1} `}
                          name={`linkTitle ${index + 1} `}
                          type={"text"}
                          isRequired={false}
                          placeholder={"enter link title"}
                          customClass="text-sm w-full mb-2"
                          register={register}
                          onChange={(e) => handleLinkTitle(e, index)}
                          value={item.title}
                        />{" "}
                        <Input
                          labelText={"Link"}
                          labelFor={`link ${index + 1} `}
                          name={`link ${index + 1} `}
                          type={"text"}
                          isRequired={false}
                          placeholder={"enter link"}
                          customClass="text-sm w-full mb-2"
                          register={register}
                          onChange={(e) => handleLink(e, index)}
                          value={item.link}
                        />{" "}
                        <TextArea
                          labelText={"Description"}
                          labelFor={`linkDescription ${index + 1} `}
                          name={`linkDescription ${index + 1} `}
                          type={"text"}
                          isRequired={false}
                          placeholder={"enter the description of your link"}
                          customClass="text-sm w-full mb-2"
                          inputStyle="h-[90px]"
                          register={register}
                          onChange={(e) => handleLinkDescription(e, index)}
                          value={item.description}
                        />{" "}
                      </div>
                      <AiFillMinusCircle
                        color="#BDBDBD"
                        size={25}
                        className={`cursor-pointer ${
                          index === 0 ? "hidden" : "visible"
                        }`}
                        onClick={() => handleRemoveLink(index)}
                      />
                    </div>
                  );
                })}
              </div>

              <Button
                type="button"
                content={<HiPlus color="#FBFBFB" size={20} />}
                loading={false}
                onClick={addLinks}
                btnColor="primary"
                Style={
                  "absolute right-[0px] py-[3px] bottom-[0px] text-white w-fit px-[4px] m-2"
                }
              />
            </div>
            <Input
              labelText={"Website (your personal website)"}
              labelFor={"website"}
              name={"website"}
              type={"text"}
              isRequired={false}
              placeholder={"enter a link for your website"}
              customClass="text-sm w-full my-4"
              register={register}
              onChange={(e) => handleWebsite(e)}
              value={profile.website}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
