/* component author: TYPatrick*/
import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { BsCheckLg } from 'react-icons/bs';

export default function DateRangePicker({ dateRange }) {
  const [value, setValue] = React.useState(dayjs());
  const [highlightedDays, setHighlightedDays] = React.useState([]);


  function toggleHighlightedDays(day, month) {
    const index = highlightedDays.findIndex((highlightedDay) => {
      return highlightedDay.d === day && highlightedDay.m === month;
    });
    
    if (index === -1) {
      // Not present, so add it
      setHighlightedDays((prevDays) => [...prevDays, { d: day, m: month }]);
    } else {
      // Already present, so remove it
      const newDays = [...highlightedDays];
      newDays.splice(index, 1);
      setHighlightedDays(newDays);
    }
  }

  return (
    <div className="w-fit h-fit">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className="w-fit mt-4"
          orientation="portrait"
          openTo="day"
          value={value}
          minDate={dateRange[0].from ? dateRange[0].from : dayjs()}
          maxDate={dateRange[0].to ? dateRange[0].to : dayjs('2023-04-04')}
          onChange={(newValue) => {
            setValue(newValue);
            toggleHighlightedDays(newValue.$D, newValue.$M);
          }}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(day, _value, DayComponentProps) => {
            const isSelected = highlightedDays.some((highlightedDay) => {
              return (
                highlightedDay.d === day.date() &&
                highlightedDay.m === day.month()
              );
            });
            return (
              <Badge
                key={day.toString()}
                overlap="circular"
                badgeContent={isSelected ? <BsCheckLg /> : undefined}
              >
                <PickersDay {...DayComponentProps} />
              </Badge>
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
