import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../components/modal/Modal';
import Input from '../../../../components/Input';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import SingleDay from './SingleDay';
import Multidays from './MultiDays/Multidays';
import axios from 'axios';

const AddEvent = ({ show, setShow }) => {

  const handleScheduleTypeChange = (event) => {
    const { value } = event.target;
    setEvent((prevData) => {
      return {
        ...prevData,
        scheduleType:value
      };
    });
  };
  const [sameLocationValue, setSameLocationValue] = useState('');

  const [event, setEvent] = useState({
    title: '',
    description: '',
    scheduleType: 'single',
    sameLocation: true,
    differentLocation: null,
    singleDaySchedule: [
      {
        location: '',
        venue: '',
        date: '',
        from: '',
        to: '',
        pricing: [
          {
            name: '',
            unit: '',
            ppu: '',
          },
        ],
      },
    ],
    sameLocationSchedule: [
      {
        location : sameLocationValue,
        venue: '',
        date: '',
        from: '',
        to: '',
        pricing: [
          {
            name: '',
            unit: '',
            ppu: '',
          },
        ],
      },
    ],
    diffLocationSchedule: [
      {
        location: '',
        venue: '',
        date: '',
        from: '',
        to: '',
        pricing: [
          {
            name: '',
            unit: '',
            ppu: '',
          },
        ],
      },
    ],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  const onSubmit = (data) => {
    axios
      .post('https://jsonplaceholder.typicode.com/posts', {
        title: '',
        description: '',
        scheduleType: 'single',
        sameLocation: null,
        differentLocation: null,
        schedule: [
          {
            location: '',
            venue: '',
            date: '',
            from: '',
            to: '',
            pricing: [
              {
                name: '',
                unit: '',
                ppu: '',
              },
            ],
          },
        ],
      })
      .then((res) => {
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow()}
      Style={
        'w-[90%] lg:w-1/2 mt-8 max-h-[90%] mx-auto overflow:hidden pb-0 font-nunito'
      }
    >
      <Modal.Body>
        <div className="flex flex-col lg:p-4">
          <h1 className="text-center font-bold mb-8 text-xl">Add Event</h1>
          <Input
            labelText={'Event Title'}
            labelFor={'eventTitle'}
            name={'eventTitle'}
            type={'text'}
            isRequired={true}
            placeholder={'title'}
            customClass="w-full mb-2"
            onChange={(e) => {
              setEvent((prevData) => {
                return {
                  ...prevData,
                  title: e.target.value,
                };
              });
            }}
            register={register}
            errors={errors}
          />
          <TextArea
            labelText={'Description'}
            labelFor={'description'}
            name={'description'}
            isRequired={true}
            placeholder={'Event description'}
            customClass="w-full mb-2"
            onChange={(e) => {
              setEvent((prevData) => {
                return {
                  ...prevData,
                  description: e.target.value,
                };
              });
            }}
            register={register}
            errors={errors}
          />
          <div className="flex flex-col">
            <h1 className="font-bold">Scheduling and pricing</h1>
            <div className="flex flex-row justify-between lg:justify-start lg:gap-12 mt-4">
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  id="scheduleChoice1"
                  name="scheduleChoice1"
                  value="single"
                  checked={event.scheduleType === 'single'}
                  onChange={handleScheduleTypeChange}
                />
                <label for="scheduleChoice1">Single day event</label>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  id="scheduleChoice2"
                  name="scheduleChoice2"
                  value="more"
                  checked={event.scheduleType === 'more'}
                  onChange={handleScheduleTypeChange}
                />
                <label for="scheduleChoice2">more days</label>
              </div>
            </div>

            {event.scheduleType === 'single' ? (
              <SingleDay event={event} setEvent={setEvent} />
            ) : (
              <Multidays event={event} setEvent={setEvent} sameLocationValue={sameLocationValue}
              setSameLocationValue={setSameLocationValue}/>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center gap-4">
        <div className="flex items-center justify-center gap-4">
          <Modal.DismissButton className="py-2 px-4  text-black  rounded-md border font-bold">
            Close
          </Modal.DismissButton>
          <Button
            type="submit"
            content="Submit"
            loading={false}
            btnColor="primary"
            onClick={() => setShow()}
            Style={'text-white h-[40px]  ml-0 border'}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEvent;
