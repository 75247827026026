import React, { useState } from 'react';
import Modal from '../../components/modal/Modal';
import Button from '../../components/Button';
import MultiSelect from '../../components/MultiSelect';
import TextArea from '../../components/TextArea';
import { useForm } from 'react-hook-form';

const reasonOptions = [
  { value: 1, label: 'Low pay' },
  { value: 2, label: 'Reason A' },
  { value: 3, label: 'Reason B' },
  { value: 4, label: 'Reason C' },
  { value: 6, label: 'Other' },
];

const RejectReason = ({ show, setShow }) => {
  const [reasons, setReasons] = useState([]);
  const isOtherReason = (reasonArray) => {
    for (let i = 0; i < reasonArray.length; i++) {
      if (reasonArray[i] === 'Other') {
        return true;
      }
    }

    return false;
  };

  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <div>
      <Modal
        isOpen={show}
        onClose={() => setShow()}
        Style={'w-5/6 lg:w-1/2 m-4 flex m-auto'}
      >
        <Modal.Body>
          <div className="flex flex-col p-4">
            <h1 className="font-bold text-center text-xl">Reject Offer</h1>
            <div className="mt-8">
              <MultiSelect
                label="Select reason"
                className="w-full lg:w-1/2"
                isLoading={false}
                placeholder={'Select reason'}
                defaultValue={reasonOptions}
                onChange={(e) => {
                  setReasons(e.map((e) => e.label));
                }}
                options={reasonOptions}
              />
            </div>

            <TextArea
              labelText={'Reason'}
              labelFor={'reason'}
              name={'reason'}
              isRequired={true}
              placeholder={'Specify other reason ...'}
              customClass={`w-full mt-4 ${
                isOtherReason(reasons) ? 'visible' : 'hidden'
              }`}
              handleChange={(e) => {
                console.log(e.target.value);
              }}
              register={register}
              errors={errors}
            />
            <div className="flex justify-between mt-8">
              <Button
                type="submit"
                content="Cancel"
                loading={false}
                btnColor="secondary"
                onClick={() => {
                  setShow();
                }}
                Style={'text-fontcolor border h-[40px] mt-2 ml-0'}
              />

              <Button
                type="submit"
                content="Submit"
                loading={false}
                btnColor="primary "
                onClick={() => {
                  setShow();
                }}
                Style={'text-white h-[40px] mt-2 ml-0 '}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RejectReason;
