import React, { useState } from 'react';
import radio from '../../../seeds/radio.json';
import { FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { Popover } from '@headlessui/react';
import { Tab } from '@headlessui/react';
import Table from '../../../components/tableComponent/Table';
import Collapsible  from '../../../components/Collapsible';
import { BiSearch } from 'react-icons/bi';
import Button from '../../../components/Button';
import AddTvModal from './Tv_modals/AddTvModal';
import AddTvShow from './Tv_modals/AddTvShow';
import ShowDetails from './Tv_modals/ShowDetails';

// filter array items
export const filterData = (arr, data_id) => {
  return arr.filter((item) => item.id === data_id);
};

// list of tvs

const tvs = [
  { id: 1, name: 'Flash Tv' },
  { id: 2, name: 'Isango Tv' },
  { id: 3, name: 'Rwanda Tv' },
];
   
const TV = () => {
  const columns = [
    {
      Header: <div className="flex items-start p-[15px]">{'Show Title'}</div>,
      accessor: 'Show Title',
    },
    {
      Header: <div className="flex items-start p-[15px]">{'Time'}</div>,
      accessor: 'Time',
    },
    {
      Header: <div className="flex items-start p-[15px]">{'Airtime'}</div>,
      accessor: 'Airtime',
    },
    {
      Header: (
        <div className="flex items-start p-[15px]">{'Price per second'}</div>
      ),
      accessor: 'Price per second',
    },
    {
      Header: <div className="flex items-start p-[15px]">{'Actions'}</div>,
      accessor: 'Actions',
      Cell: () => {
        return (
          <div className="flex flex-row  w-max font-bold font-nunito">
            <Popover className="relative flex flex-row">
              <div>
                <Popover.Button>
                  <FiMoreVertical size={30} className="cursor-pointer" />
                </Popover.Button>
              </div>
              <div>
                <Popover.Panel className="absolute z-10">
                  <div className="flex flex-col h-max w-max bg-[#DAD7CD] bg-opacity-50 border border-lg border-white ">
                    <ul className="flex flex-col space-y-2  ">
                      <li
                        className="hover:bg-[#D9D9D9] bg-cover px-4"
                        onClick={() => setOpenDetails(!openDetails)}
                      >
                        <div>view details</div>
                      </li>
                    </ul>
                  </div>
                </Popover.Panel>
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  //useState definition

  const [isOpen, setIsOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [addTv, setAddTv] = useState(false);
  const [data, setData] = useState(1);

  // onCLickHandle

  const onClickHandle = () => {
    setIsOpen(!isOpen);
  };

  const myData = filterData(radio, data);

  return (
    <div className="mt-[43px] flex flex-col lg:flex-row font-nunito">
      <AddTvModal addTv={addTv} setAddTv={setAddTv} setToggleCreateSpace={setAddTv} />
      <AddTvShow isOpen={isOpen} onClickHandle={setIsOpen} />
      <ShowDetails openDetails={openDetails} setOpenDetails={setOpenDetails} />
      {/* left panel */}

      <Collapsible
        key={1}
        Style={'lg:hidden w-full  h-fit mb-4'}
        heading={
          <h1 className="font-bold mr-2 text-lg text-fontColor">Flash TV</h1>
        }
        body={
          <div className="w-full flex flex-col mx-auto mb-8 lg:mb-0 ">
            <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
              <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                <p className="italic text-[#414141] mt-[21px]">All TVs</p>
                <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                  <BiSearch size={20} />
                  <input
                    defaultValue=""
                    placeholder="Search"
                    className="w-full rounded outline-none font-nunito"
                  />
                </div>
                <hr className="mt-2" />
              </div>
              <div className="pt-2 mx-[20px] overflow-y-auto">
                <div className="  text-base ">
                {tvs.map((tv, i) => {
                return (
                  <div
                    className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                    onClick={() => {
                      setData(tv.id);
                    }}
                  >
                    <h1>{tv.name}</h1>
                  </div>
                );
              })}
                </div>
              </div>
            </div>

            <Button
              content="+ Add TV"
              loading={false}
              onClick={() => setAddTv(true)}
              btnColor="primary"
              Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
            />
          </div>
        }
      />

      <div className="hidden lg:flex w-full lg:min-w-[239px] lg:max-w-[239px] flex-col mx-auto mb-8 lg:mb-0 ">
        <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
          <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
            <p className="italic text-[#414141] mt-[21px]">All TVs</p>
            <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
              <BiSearch size={20} />
              <input
                defaultValue=""
                placeholder="Search"
                className="w-full rounded outline-none font-nunito"
              />
            </div>
            <hr className="mt-2" />
          </div>
          <div className="pt-2 mx-[20px] overflow-y-auto">
            <div className="  text-base ">
              {tvs.map((tv, i) => {
                return (
                  <div
                    className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                    onClick={() => {
                      setData(tv.id);
                    }}
                  >
                    <h1>{tv.name}</h1>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Button
          content="+ Add Tv"
          loading={false}
          onClick={() => setAddTv(true)}
          btnColor="primary"
          Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
        />
      </div>

      {/* end ofleft panel */}

      {/* start of right panel */}
      <div className="bg-white rounded-lg shadow-lg lg:ml-[9px] w-full h-max p-[28px] ">
        <div className="flex justify-between">
          <div className="flex flex-row">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE42KdvyR9LuDFMGDOh-ph-1qovd_VN-6BPA1bNzBN8g&s"
              alt="logo"
            />
            <div className="w-[79px] h-[75px] flex flex-col items-center py-auto ml-[15px]">
              <div className="mx-auto my-auto text-[20px] w-max font-semibold">
                <p>Flash TV</p>
              </div>
            </div>
          </div>
          <div>
            <Popover className="relative">
              <Popover.Button>
                <FiMoreHorizontal size={20} />
              </Popover.Button>

              <Popover.Panel className="absolute z-10">
                <div className="flex flex-col bg-white w-max h-[67px] mr-[27px] justify-around">
                  <a href="#" className="hover:bg-[#D9D9D9]">
                    Edit
                  </a>
                  <a href="#" className="hover:bg-[#D9D9D9]">
                    Delete
                  </a>
                </div>

                <img src="/solutions.jpg" alt="" />
              </Popover.Panel>
            </Popover>
          </div>
        </div>
        <div className="mt-[71px]">
          <Tab.Group manual>
            <Tab.List className="flex flex-row justify-between w-[360px] items-start font-bold">
              <Tab>Shows(3)</Tab>
              <Tab>Pricing</Tab>
              <Tab>About Television</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="mt-[28px] flex flex-col">
                  <div className="w-full flex items-end justify-end">
                    <Button
                      content="+ Add show"
                      loading={false}
                      onClick={onClickHandle}
                      btnColor="primary"
                      Style="text-white w-fit h-[40px] mr-4 ml-0"
                    />
                  </div>
                  <div>
                    <Table columns={columns} data={myData} className="w-full" />
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>Pricing</Tab.Panel>
              <Tab.Panel>About radio</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      {/* end of right panel */}
    </div>
  );
};

export default TV;
