import React, { useState } from "react";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import Drag from "../../../components/Drag";
import TextArea from "../../../components/TextArea";

const CompanyStep4 = ({ companyProfile, setCompanyProfile }) => {
  const [files, setFiles] = useState([]);

  // handle description

  const handleDescription = (e) => {
    const { value } = e.target;
    setCompanyProfile((prevData) => {
      return {
        ...prevData,
        description: value,
      };
    });
  };

  const {
    register,
    // handleSubmit,
    formState: { errors },
    //reset,
  } = useForm();
  return (
    <div className={`flex-row bg-secondary m-0 w-[600px] h-fit font-nunito`}>
      <div className="flex flex-col w-full px-8 py-8">
        <div className="flex flex-col w-full mx-auto my-8 md:my-auto lg:my-4 xlg:my-4 xl:my-4">
          <h1 className="text-[18px] md:text-[20px] lg:text-[20px] xlg:text-[20px] xl:text-[20px]">
            Describe your company
          </h1>
          <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
            what makes you special ? Don’t think too hard, just fun with it.
          </h1>

          <div className="flex flex-col space-y-8">
            <div className="flex flex-row space-x-2 items-end ">
              <div className=" rounded-lg w-full drop-shadow-lg">
                <div className="flex flex-row items-center space-x-2">
                  <TextArea
                    labelFor={"description"}
                    name={"description"}
                    type={"text"}
                    placeholder={"add description"}
                    customClass="w-full my-2 py-0"
                    inputStyle="h-[90px]"
                    register={register}
                    errors={errors}
                    onChange={(e) => handleDescription(e)}
                    value={companyProfile.description}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <h2>Upload logo</h2>
            <Drag
              style="w-full "
              flexStyle="flex flex-col space-y-8"
              uploadLimit={5}
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyStep4;
