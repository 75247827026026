import React from "react";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import MultiSelect from "../../../components/MultiSelect";

const options = [
  { value: "option1", label: "Konvey Direct" },
  { value: "option2", label: "MTN-Rwanda" },
  { value: "option3", label: "Airtel-Rwanda" },
  { value: "option4", label: "RBA" },
  { value: "option5", label: "TV1 & R1" },
  { value: "option6", label: "Aflink" },
  { value: "option7", label: "BTN" },
  { value: "option8", label: "Rwanda Foam" },
  { value: "option9", label: "Prime TV" },
  { value: "option10", label: "TV10 & R10" },
];

const CompanyStep2 = ({companyProfile, setCompanyProfile}) => {
   const handleClients = (clients) => {
    setCompanyProfile((prevData) => {
          return {
            ...prevData,
            clients,
          };
        });
      };

  const {
    register,
    // handleSubmit,
    // formState: { errors },
    //reset,
  } = useForm();
  return (
    <div
      className={`flex flex-row bg-secondary w-fit h-fit font-nunito pt-16 `}
    >
      <div className="flex flex-col ">
        <h1 className="text-[20px]  lg:text-[24px] xlg:text-[24px] xl:text-[24px] mr-[50px]">
          Enter company information
        </h1>
        <Input
          labelText={"Company name"}
          labelFor={"companyName"}
          name={"companyName"}
          type={"text"}
          isRequired={false}
          placeholder={"enter company name"}
          customClass="w-full mb-2 mt-4"
          register={register}
          value={companyProfile.companyName || ''}
          onChange={(e) => {
            setCompanyProfile((prevData) => {
              return {
                ...prevData,
                companyName: e.target.value,
              };
            });
          }}
        />{" "}
        <Input
          labelText={"Telephone"}
          labelFor={"companyPhone"}
          name={"companyPhone"}
          type={"text"}
          isRequired={false}
          placeholder={"+0788888888"}
          customClass="w-full mb-2"
          register={register}
          value={companyProfile.telephone || ''}
          onChange={(e) => {
            setCompanyProfile((prevData) => {
              return {
                ...prevData,
                telephone: e.target.value,
              };
            });
          }}
        />{" "}
        <MultiSelect
          className="w-full mt-4"
          isLoading={false}
          placeholder={"industry type"}
          defaultValue={options}
          options={options}
          onChange={(e) => handleClients(e)}
          value={companyProfile.clients}
        />
      </div>
    </div>
  );
};

export default CompanyStep2;
