import React, { useState, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import PublisherRoutes from './Routes/PublisherRoutes';
import { Test } from './Test';
import Test2 from './Test2';
import Registration from './pages/registration/Registration';
import store from './redux/store';
import { Provider } from "react-redux";
import Layout from './components/Layout';

function App() {
  return (
    <Provider store={store}>
      <Router>
      <Routes>
        <Route path="/*" element={<PublisherRoutes />} />
        <Route path="/test" element={<Test />} />
        <Route path="/test2" element={<Test2 />} />
        <Route path="/profile" element={<Registration />} />
        <Route path="/layout" element={<Layout />} />
      </Routes>
    </Router>
    </Provider>
  );
}

export default App;
