import React, { useEffect, useState, Fragment } from 'react';
import { filterData } from './Radio';
import { Popover, Transition } from '@headlessui/react';
import Collapsible from '../../../components/Collapsible';
import { FiMoreHorizontal } from 'react-icons/fi';
import { BiSearch } from 'react-icons/bi';
import Button from '../../../components/Button';
import AddEvent from './Event_modals/AddEvent';

export const events = [
  {
    title: 'Kwita Izina',
    date: '12/12/2021',
    time: '12:00pm ',
    from: '12:00 pm',
    to: '17:00 pm',
    venue: 'KinigiKong',
    venueImg: 'https://pbs.twimg.com/media/EDxTlVEVAAABstJ.jpg',
    location: 'Kinigi, Musanze.',
    price: '300,000 Rwf',
    description:
      ' The event will feature a naming ceremony of 20 baby mountain gorillas born in the park over the last 12 months. 354 baby gorillas have been named since the start of the naming ceremony in  2005.  This year’s namers will include notable partners, conservationists, international celebrities, dignitaries and friends of Rwanda.',

    id: 1,
  },
  {
    title: 'event two',
    date: '12/12/2021',
    time: '12:00pm',
    venue: 'KCV',
    location: 'Lagos',
    price: '300,000 Rwf',
    description: 'lorem ipsum ipsum',
    id: 2,
  },
  {
    title: 'event three',
    date: '12/12/2021',
    time: '12:00pm',
    venue: 'KCC',
    location: 'Lagos',
    price: '300k',
    description: 'lorem ipsum ipsum',
    id: 3,
  },
  {
    title: 'event four',
    date: '12/12/2021',
    time: '12:00pm',
    venue: 'Main Hall',
    location: 'Lagos',
    price: '300k',
    description:
      'Publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.',
    id: 4,
  },
];

const Events = [
  'Kwita Izina',
  'DJ Battle',
  'Summer Awards',
  'Arnold Classic',
  'Friday Night',
  'Kwita Izina',
  'DJ Battle',
  'Summer Awards',
  'Arnold Classic',
  'Friday Night',
];

const Event = () => {
  const [event, setEvent] = useState(1);
  const myData = filterData(events, event);
  const [eventModal, setEventModal] = useState(false);

  return (
    <>
      <AddEvent show={eventModal} setShow={() => setEventModal(!eventModal)} />
      <div className="mt-[43px] flex flex-col lg:flex-row font-nunito">
        {/* left panel */}
        <Collapsible
          key={1}
          Style={'lg:hidden w-full  h-fit mb-4'}
          heading={
            <h1 className="font-bold mr-2 text-lg text-fontColor">
              Kwita Izina
            </h1>
          }
          body={
            <div className="w-full flex flex-col mx-auto mb-8 lg:mb-0 ">
              <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
                <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                  <p className="italic text-[#414141] mt-[21px]">All Events</p>
                  <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                    <BiSearch size={20} />
                    <input
                      defaultValue=""
                      placeholder="Search"
                      className="w-full rounded outline-none font-nunito"
                    />
                  </div>
                  <hr className="mt-2" />
                </div>
                <div className="pt-2 mx-[20px] overflow-y-auto">
                  <div className="  text-base ">
                    {Events.map((event, i) => {
                      return (
                        <div
                          className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                          onClick={() => {}}
                        >
                          <h1>{event}</h1>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <Button
                content="+ Add Event"
                loading={false}
                onClick={() => {
                  setEventModal(!eventModal);
                }}
                btnColor="primary"
                Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
              />
            </div>
          }
        />

        <div className="hidden lg:flex w-full lg:min-w-[239px] lg:max-w-[239px] flex-col mx-auto mb-8 lg:mb-0 ">
          <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
            <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
              <p className="italic text-[#414141] mt-[21px]">All Events</p>
              <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                <BiSearch size={20} />
                <input
                  defaultValue=""
                  placeholder="Search"
                  className="w-full rounded outline-none font-nunito"
                />
              </div>
              <hr className="mt-2" />
            </div>
            <div className="pt-2 mx-[20px] overflow-y-auto">
              <div className="  text-base ">
                {Events.map((event, i) => {
                  return (
                    <div
                      className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                      onClick={() => {}}
                    >
                      <h1>{event}</h1>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <Button
            content="+ Add Event"
            loading={false}
            onClick={() => {
              setEventModal(!eventModal);
            }}
            btnColor="primary"
            Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
          />
        </div>

        {/* start of right panel */}
        <div>
          <div className="bg-white rounded-lg shadow-lg lg:ml-[9px] w-full h-max p-7">
            <div className=" flex pb-7">
              <div className="flex flex-col lg:flex-row gap-2 w-full">
                <div className="w-[300px] [120px]">
                  <img
                    src="https://i.ytimg.com/vi/FXhv6FsS_og/maxresdefault.jpg"
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className=" w-full lg:w-1/2 flex flex-col ">
                  <p className="text-[36px] w-full h-[49px] font-[500]">
                    {myData[0].title}
                  </p>
                  <p className="text-base font-[500]">{myData[0].date}</p>

                  <p className="text-base font-[500]">{myData[0].time}</p>
                  <div className="flex flex-row gap-3 w-full">
                    <div className="flex flex-row  gap-1 items-center">
                      <h1 className="text-light  font-bold text-base w-fit my-2">
                        From:
                      </h1>
                      <h2 className="text-black text-base ">
                        {myData[0].from}
                      </h2>
                    </div>

                    <div className="flex flex-row  gap-1 items-center">
                      <h1 className="text-light  font-bold text-base w-fit my-2">
                        To:
                      </h1>
                      <h2 className="text-black text-base ">{myData[0].to}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end pt-[17px]">
                <Popover className="relative">
                  <Popover.Button>
                    <FiMoreHorizontal size={20} />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className="absolute z-10  "
                      // style={styles.popper}
                      // {...attributes.popper}
                    >
                      <div className="flex flex-col bg-white shadow-md border border-black/10 w-[80px] rounded-sm text-xs">
                        <h1
                          className="p-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                          onClick={() => {}}
                        >
                          Edit
                        </h1>

                        <h1 className="p-2 hover:bg-[#D9D9D9] text-center cursor-pointer">
                          Delete
                        </h1>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
            </div>
            <hr className="w-full  bg-[#BEBEBE]  mb-[13px]" />
            <div className="flex flex-col gap-4">
              <div className=" flex flex-col lg:flex-row justify-between">
                <div className="flex flex-row gap-2 items-center">
                  <p className="text-lg font-bold text-light">Venue</p>
                  <p className="text-base font-[500]">{myData[0].venue}</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <p className="text-lg font-bold text-light">Location</p>
                  <p className="text-base font-[500]">{myData[0].location}</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <p className="text-lg font-bold text-light">Price</p>
                  <p className="text-base font-[500]">{myData[0].price}</p>
                </div>
              </div>
              <div className="flex flex-col pb-[13px]">
                <p className="text-lg font-bold text-light">Description</p>
                <p className="text-base font-[500]">{myData[0].description}</p>
              </div>
            </div>
            <hr className="w-full  bg-[#BEBEBE]  mb-[13px]" />
            <div className="">
              <video controls>
                <source
                  src="https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video element.
              </video>
            </div>
          </div>
        </div>

        {/* end of right panel */}

        {/* start of modal */}

        {/* end of modal*/}
      </div>
    </>
  );
};

export default Event;
