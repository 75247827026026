import React, { useState } from 'react';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import { useForm } from 'react-hook-form';
import { AiFillMinusCircle } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';

const SameLocation = ({ event, setEvent, sameLocationValue, setSameLocationValue }) => {
  // {
  //   title: '',
  //   description: '',
  //   scheduleType: 'single',
  //   sameLocation: true,
  //   differentLocation: null,
  //   singleDaySchedule: [
  //     {
  //       location: '',
  //       venue: '',
  //       date: '',
  //       from: '',
  //       to: '',
  //       pricing: [
  //         {
  //           name: '',
  //           unit: '',
  //           ppu: '',
  //         },
  //       ],
  //     },
  //   ],
  //   sameLocationSchedule: [
  //     {
  //       location: '',
  //       venue: '',
  //       date: '',
  //       from: '',
  //       to: '',
  //       pricing: [
  //         {
  //           name: '',
  //           unit: '',
  //           ppu: '',
  //         },
  //       ],
  //     },
  //   ],
  //   diffLocationSchedule: [
  //     {
  //       location: '',
  //       venue: '',
  //       date: '',
  //       from: '',
  //       to: '',
  //       pricing: [
  //         {
  //           name: '',
  //           unit: '',
  //           ppu: '',
  //         },
  //       ],
  //     },
  //   ],
  // }

  const handleEventVenue = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.venue = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, sameLocationSchedule: updatedSameLocationSchedule };
    });
  };
  const handleEventDate = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.date = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, sameLocationSchedule: updatedSameLocationSchedule };
    });
  };
  const handleEventFrom = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.from = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, sameLocationSchedule: updatedSameLocationSchedule };
    });
  };
  const handleEventTo = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.to = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, sameLocationSchedule: updatedSameLocationSchedule };
    });
  };

  const handleSlotName = (e, eventIndex, pricingIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.pricing[pricingIndex].name = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return {
        ...prevData,
        sameLocationSchedule: updatedSameLocationSchedule,
      };
    });
  };

  const handleSlotUnit = (e, eventIndex, pricingIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.pricing[pricingIndex].unit = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return {
        ...prevData,
        sameLocationSchedule: updatedSameLocationSchedule,
      };
    });
  };


  const handleSlotPpu = (e, eventIndex, pricingIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedSameLocationSchedule = [...prevData.sameLocationSchedule];
      const eventToUpdate = updatedSameLocationSchedule[eventIndex];
      eventToUpdate.pricing[pricingIndex].ppu = value;
      updatedSameLocationSchedule[eventIndex] = eventToUpdate;
      return {
        ...prevData,
        sameLocationSchedule: updatedSameLocationSchedule,
      };
    });
  };


  const addEvent = () => {
    setEvent((prevData) => {
      return {
        ...prevData,
        sameLocationSchedule: [
          ...prevData.sameLocationSchedule,
          {
            location: sameLocationValue,
            venue: '',
            date: '',
            from: '',
            to: '',
            pricing: [
              {
                name: '',
                unit: '',
                ppu: '',
              },
            ],
          },
        ],
      };
    });
  };

  const removeEvent = (eventIndex) => {
    setEvent((prevData) => {
      const updatedSchedule = [...prevData.sameLocationSchedule];
      updatedSchedule.splice(eventIndex, 1);
      return {
        ...prevData,
        sameLocationSchedule: updatedSchedule,
      };
    });
  };

  const addEventPricing = (eventIndex) => {
    setEvent((prevData) => {
      const newSameLocationSchedule = prevData.sameLocationSchedule.map(
        (event, i) => {
          if (i !== eventIndex) {
            return event;
          }
          const newPricing = [
            ...event.pricing,
            {
              name: '',
              unit: '',
              ppu: '',
            },
          ];
          return { ...event, pricing: newPricing };
        }
      );
      return { ...prevData, sameLocationSchedule: newSameLocationSchedule };
    });
  };

  const handleRemoveEventPricing = (eventIndex, pricingIndex) => {
    setEvent((prevData) => {
      const eventsArray = prevData.sameLocationSchedule;
      const pricingArray = eventsArray[eventIndex].pricing;
      pricingArray.splice(pricingIndex, 1); // Remove the pricing at the specified pricingIndex
      eventsArray[eventIndex].pricing = pricingArray;
      return {
        ...prevData,
        sameLocationSchedule: eventsArray,
      };
    });
  };

  const {
    register,
    // handleSubmit,
    formState: { errors },
    // reset,
  } = useForm();
  return (
    <div className="flex flex-col p-4 w-full">
      <Input
        labelText={'Location'}
        labelFor={'eventLocation'}
        name={'eventLocation'}
        type={'text'}
        isRequired={true}
        placeholder={'location'}
        customClass="w-full lg:w-1/2 mb-8"
        onChange={(e) => {
          setSameLocationValue(e.target.value);
        }}
        register={register}
        errors={errors}
        value={sameLocationValue}
      />
      {event.sameLocationSchedule.map((item, eventIndex) => {
        return (
          <div className="flex flex-row relative mb-6 ">
            <div className="flex flex-col p-4 border border-black/20 rounded-lg">
              <div className="flex flex-col lg:flex-row lg:gap-12">
                <Input
                  labelText={'Date'}
                  labelFor={`date${eventIndex + 1}`}
                  name={`date${eventIndex + 1}`}
                  type={'date'}
                  isRequired={true}
                  placeholder={'date'}
                  customClass="w-full lg:w-1/2 mb-2"
                  register={register}
                  errors={errors}
                  onChange= {(e) => {
                    handleEventDate(e, eventIndex)
                  }}
                  value={item.date}
                />
                <Input
                  labelText={'Venue'}
                  labelFor={`venue${eventIndex + 1}`}
                  name={`venue${eventIndex + 1}`}
                  type={'text'}
                  isRequired={true}
                  placeholder={'venue'}
                  customClass="w-full lg:w-1/2 mb-2"
                  register={register}
                  errors={errors}
                  onChange= {(e) => {
                    handleEventVenue(e, eventIndex)
                  }}
                  value={item.venue}
                />
              </div>

              <div className="flex flex-row justify-between lg:justify-start lg:gap-12">
                <Input
                  labelText={'From'}
                  labelFor={`from${eventIndex + 1}`}
                  name={`from${eventIndex + 1}`}
                  type={'time'}
                  isRequired={true}
                  placeholder={'start'}
                  customClass="w-[45%] lg:w-full mb-2"
                  register={register}
                  errors={errors}
                  onChange= {(e) => {
                    handleEventFrom(e, eventIndex)
                  }}
                  value={item.from}
                />
                <Input
                  labelText={'To'}
                  labelFor={`to${eventIndex + 1}`}
                  name={`to${eventIndex + 1}`}
                  type={'time'}
                  isRequired={true}
                  placeholder={'end'}
                  customClass="w-[45%] lg:w-full mb-2"
                  register={register}
                  errors={errors}
                  onChange= {(e) => {
                    handleEventTo(e, eventIndex)
                  }}
                  value={item.to}
                />
              </div>

              <div className="flex flex-row relative ">
                <div className="pt-2 pb-4 px-4 border border-black/20 rounded-md bg-secondary w-full">
                  {item.pricing.map((eventValue, pricingIndex) => {
                    return (
                      <div
                        key={pricingIndex}
                        className="flex flex-row bg-white p-2  rounded-md mb-2"
                      >
                        <div className="flex flex-col lg:flex-row lg:gap-4 w-full">
                          <Input
                            labelText="Slot name"
                            labelFor={`slotName[${eventIndex}${pricingIndex}]`}
                            name={`slotName[${eventIndex}${pricingIndex}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'e.g: dj mention'}
                            customClass="text-sm w-full mb-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                              handleSlotName(e, eventIndex, pricingIndex);
                            }}
                            value={item.name}
                          />{' '}
                          <Input
                            labelText={'Unit'}
                            labelFor={`unit[${eventIndex}${pricingIndex}]`}
                            name={`unit[${eventIndex}${pricingIndex}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'eg: seconds'}
                            customClass="text-sm w-full mb-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                              handleSlotUnit(e, eventIndex, pricingIndex);
                            }}
                            value={item.unit}
                          />{' '}
                          <Input
                            labelText={'Price/unit'}
                            labelFor={`ppu[${eventIndex}${pricingIndex}]`}
                            name={`ppu[${eventIndex}${pricingIndex}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'eg: 5000 Rwf'}
                            customClass="text-sm w-full mb-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                              handleSlotPpu(e, eventIndex, pricingIndex);
                            }}
                            value={item.ppu}
                          />{' '}
                        </div>
                        <AiFillMinusCircle
                          color="#BDBDBD"
                          size={25}
                          className={`cursor-pointer ${
                            pricingIndex === 0 ? 'hidden' : 'visible'
                          }`}
                          onClick={() =>
                            handleRemoveEventPricing(eventIndex, pricingIndex)
                          }
                        />
                      </div>
                    );
                  })}
                </div>

                <Button
                  type="button"
                  content={<HiPlus color="#FBFBFB" size={20} />}
                  loading={false}
                  onClick={() => {
                    addEventPricing(eventIndex);
                  }}
                  btnColor="primary"
                  Style={
                    'absolute right-[0px] py-[3px] bottom-[0px] text-white w-fit px-[4px] m-2'
                  }
                />
              </div>
            </div>
            <AiFillMinusCircle
              color="#BDBDBD"
              size={25}
              className={` absolute right-[-45px] cursor-pointer mx-2 dynMinus ${
                eventIndex === 0 ? 'hidden' : 'visible'
              }`}
              onClick={() => {
                removeEvent(eventIndex);
              }}
            />

            <Button
              type="button"
              content={<HiPlus color="#FBFBFB" size={20} />}
              loading={false}
              onClick={() => addEvent()}
              btnColor="primary"
              Style={`absolute right-[-10px] lg:right-[-45px] py-[3px] bottom-[-30px]  lg:bottom-[0px] text-white w-fit px-[4px] mx-2 ${
                eventIndex === event.sameLocationSchedule.length - 1
                  ? 'visible'
                  : 'hidden'
              }`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SameLocation;
