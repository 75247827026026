import React, { useEffect, useState } from 'react';
import { Tab } from "@headlessui/react";
import Radio from './tabs/Radio';
import TV from './tabs/TV';
import Billboard from './tabs/Billboard';
import DigitalBillboard from './tabs/DigitalBillboard';
import Event from './tabs/Event';
import Creators from './tabs/Creators';
import Website from './tabs/Website';
import AddBillboard from './tabs/billboard_modals/AddBillboard';
import Modal from '../../components/modal/Modal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Spaces = ({ setActivePage }) => {

  useEffect(() => {
    setActivePage('Spaces');
  }, []);

  useEffect(() => {
    setActivePage('Spaces');
  }, []);

  const spaces = [
    { name: 'Radio', },
    { name: 'TV', },
    { name: 'Billboards',},
    { name: 'Digital billboards', },
    { name: 'Events', },
    { name: 'Creators',  },
    { name: 'Websites', },
  ];

  return (
    <div className="pl-[30px] content bg-secondary pr-[20px] pt-[40px] overflow-x-hidden font-nunito">
      <div className="flex flex-col justify-evenly h-max">
        <div className="flex flex-row justify-between">
          <div>
            <p className="text-[32px]">Spaces</p>
            <p className="text-[14px]">All spaces (34)</p>
          </div>
        </div>
        <div className="mt-[43px] py-[13px] px-auto ">
          {/* test tabs */}
          <Tab.Group
            manual
          >
            <Tab.List className="flex flex-row  pb-4 lg:pb-0 lg:gap-4 w-full xlg:w-[80%] items-start overflow-x-auto">
              {spaces.map((space) => {
                return (
                  <Tab
                    className={({ selected }) =>
                      (classNames = `flex flex-row justify-between items-start  border-[#F6F6F6] font-bold whitespace-nowrap ${
                        selected
                          ? 'bg-primary text-white shadow px-4 rounded-sm'
                          : 'text-primary bg-secondary px-4'
                      }`)
                    }
                  >
                    {space.name}
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              
              {spaces.map((space) => {
               
                if (space.name === 'Radio') {
                  return (
                    <Tab.Panel>
                      <Radio />
                    </Tab.Panel>
                  );
                }else if (space.name === 'TV') {
                  return (
                    <Tab.Panel>
                      <TV />
                    </Tab.Panel>
                  );
                }else if (space.name === 'Billboards') {
                  return (
                    <Tab.Panel>
                      <Billboard />
                    </Tab.Panel>
                  );
                }else if (space.name === 'Digital billboards') {
                  return (
                    <Tab.Panel>
                      <DigitalBillboard />
                    </Tab.Panel>
                  );
                }else if (space.name === 'Events') {
                  return (
                    <Tab.Panel>
                      <Event />
                    </Tab.Panel>
                  );
                }else if (space.name === 'Creators') {
                  return (
                    <Tab.Panel>
                      <Creators />
                    </Tab.Panel>
                  );
                }else if (space.name === 'Websites') {
                  return (
                    <Tab.Panel>
                      <Website />
                    </Tab.Panel>
                  );
                }
                return null;
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default Spaces;
