import React, { useState } from 'react';
import Modal from '../components/modal/Modal';
import Select from '../components/Select';
import Button from '../components/Button';
import AddBillboard from './space/tabs/billboard_modals/AddBillboard';
import AddRadioModal from './space/tabs/Radio_modals/AddRadioModal';
import AddTvModal from './space/tabs/Tv_modals/AddTvModal';
import AddDigitalBillboard from './space/tabs/digital_billboard_modals/AddDigitalBillboard';
import AddEvent from './space/tabs/Event_modals/AddEvent';
import AddAccount from './space/tabs/Creator_modals/AddAccount';
import AddWebsite from './space/tabs/Website_modals/AddWebsite';

const tabs = [
  { value: 1, label: 'Radio' },
  { value: 2, label: 'Tv' },
  { value: 3, label: 'Billboard' },
  { value: 4, label: 'Digital billboard' },
  { value: 5, label: 'event' },
  { value: 6, label: 'creators' },
  { value: 7, label: 'websites' },
];

const CreateSpace = ({ toggleCreateSpace, setToggleCreateSpace }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState('');
  console.log(toggleCreateSpace);

  return (
    <div className="w-full">
      <Modal
        isOpen={toggleCreateSpace}
        onClose={() => setToggleCreateSpace()}
        Style={
          'flex m-auto w-full md:w-[30%] max-w-[90%] overflow-y-auto rounded-lg font-nunito'
        }
      >
        <Modal.Header>Create new space</Modal.Header>
        <Modal.Body>
          <div className="h-full my-2 relative">
            <Select
              className="w-full mb-16"
              label={'Select space type'}
              isLoading={false}
              placeholder={'select space type'}
              defaultValue={''}
              noRegister={{
                onChange: (e) => {
                  setSelected(e.value);
                },
              }}
              options={tabs}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>Close</Modal.DismissButton>
          <Button
            content="Confirm"
            loading={false}
            btnColor="primary"
            Style="text-white w-max h-[40px]  mx-4"
            onClick={() => {
              setOpenModal(!openModal);
            }}
          />
        </Modal.Footer>
      </Modal>
      {selected === 1 && (
        <AddRadioModal
          addRadio={openModal}
          setAddRadio={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
      {selected === 2 && (
        <AddTvModal
          addTv={openModal}
          setAddTv={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
      {selected === 3 && (
        <AddBillboard
          openModal={openModal}
          setOpenModal={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
      {selected === 4 && (
        <AddDigitalBillboard
          openModal={openModal}
          setOpenModal={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
      {selected === 5 && (
        <AddEvent
          show={openModal}
          setShow={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
      {selected === 6 && (
        <AddAccount
          show={openModal}
          setShow={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
      {selected === 7 && (
        <AddWebsite
          show={openModal}
          setShow={setOpenModal}
          setToggleCreateSpace={() => setToggleCreateSpace()}
        />
      )}
    </div>
  );
};

export default CreateSpace;
