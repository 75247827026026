import React from 'react'
import Table from './components/tableComponent/Table'
import data from './seeds/test.json'
import Modal from './components/modal/Modal'

export const Test = () => {
  const [openModal, setOpenModal] = React.useState(false);
    const columns = [
        {
          Header: 'Compaign name',
          accessor: 'Compaign name',
          canFilter: true,
        },
        {
          Header: 'Space type',
          accessor: 'Space type',
        },
        {
          Header: 'Space name',
          accessor: 'Space name',
        },
        {
          Header: 'Status',
          accessor: 'Status',
        },
        {
          Header: 'Amount',
          accessor: 'Amount',
        },
        {
          Header: 'Action',
        },
      ];

  return (
    <div>
        <Table columns={columns} data={data} title="Title of the table"/>

        {/*testing the modal*/}
      <button onClick={() => setOpenModal(!openModal)}>show modal</button>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        style={"max-w-xl mt-8 flex mx-auto"}
      >
        <Modal.Header>Modal Header</Modal.Header>
        <Modal.Body>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            reprehenderit adipisci et voluptates eius facilis fugiat itaque
            blanditiis magnam, nemo minus dolor incidunt quos quam, eveniet vel
            ex velit explicabo!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            reprehenderit adipisci et voluptates eius facilis fugiat itaque
            blanditiis magnam, nemo minus dolor incidunt quos quam, eveniet vel
            ex velit explicabo!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>Close</Modal.DismissButton>
          <button className="ml-2">Save</button>
        </Modal.Footer>
      </Modal>

      {/* end of testing the modal*/}
    </div>
  )
}
