import React, { useState, useEffect } from "react";
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import { useForm } from "react-hook-form";
import Drag from "../../../../components/Drag";
import axios from "axios";
import { ToastComponent, ToasterComponent } from "../../../../components/Toast";

const dimensions = [
  { value: 1, label: "2.6x3xm" },
  { value: 2, label: "2.7x3xm" },
  { value: 3, label: "2.8x3xm" },
  { value: 4, label: "2.9x3xm" },
  { value: 5, label: "Other" },
];

const size = [
  { value: 1, label: "Small" },
  { value: 2, label: "Medium" },
  { value: 3, label: "Large" },
  { value: 4, label: "X-large" },
];

const AddBillboard = ({ openModal, setOpenModal, setToggleCreateSpace }) => {
  const [billboardImage, setBillboardImage] = useState([]);
  const [data, setData] = useState({
    name: "",
    location: "",
    size: 0,
    dimension: 0,
    pricing:{slot:'', unit:'', pricePerUnit:''},
    images: [],
  });

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const onSubmit = () => {
    axios
      .post("https://jsonplaceholder.typicode.com/posts", data)
      .then((res) => {
        console.log(res);
        notify("success", "Billboard added successfully");
        reset();
      })
      .catch((err) => {
        console.log(err);
        notify("error", "Failed to add billboard");
      });
  };

  useEffect(() => {
    setData({ ...data, images: billboardImage });
  }, [billboardImage]);

  return (
    <Modal
      isOpen={openModal}
      onClose={() => setOpenModal(false)}
      Style={
        "scroll-modal  mt-8 flex mx-auto h-[90%] md:max-h-[95%] w-[95%] lg:w-[30%] md:max-w-[60%] overflow-y-scroll rounded-lg font-nunito"
      }
    >
      <form
        action=""
        method="post"
        className="w-full px-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Header>Add billboard</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col items-center space-y-4 w-full">
            <Input
              key="01"
              labelText={"Name"}
              labelFor={"name"}
              id="01"
              name={"name"}
              type={"text"}
              isRequired={true}
              placeholder={"enter billboard name"}
              customClass="w-full"
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              register={register}
              errors={errors}
            />{" "}
            <Input
              key="02"
              labelText={"Location"}
              labelFor={"location"}
              id="02"
              name={"location"}
              type={"text"}
              isRequired={true}
              placeholder={"enter location"}
              customClass="w-full"
              onChange={(e) => {
                setData({ ...data, location: e.target.value });
              }}
              register={register}
              errors={errors}
            />{" "}
            <div className="w-full">
              <Select
                key="03"
                className="w-full"
                label={"Size type"}
                isLoading={false}
                placeholder={"select size"}
                defaultValue={""}
                onChange={(e) => {
                  setData({ ...data, size: e.value });
                }}
                options={size}
                error={errors["size"]?.message}
              />
            </div>
            <div className="w-full flex flex-col space-y-4">
              <Select
                key="04"
                className="w-full"
                label={"Dimension"}
                isLoading={false}
                placeholder={"select dimension"}
                defaultValue={""}
                onChange={(e) => {
                  setData({ ...data, dimension: e.value });
                }}
                options={dimensions}
                error={errors["dimension"]?.message}
              />
              {data.dimension === 5 && (
                <Input
                  key="05"
                  labelText={"Dimension"}
                  labelFor={"dimension"}
                  id="05"
                  name={"dimension"}
                  type={"text"}
                  isRequired={true}
                  placeholder={"ex: height x width"}
                  customClass="w-full"
                  onChange={(e) => {
                    setData({ ...data, dimension: e.target.value });
                  }}
                  register={register}
                  errors={errors}
                />
              )}
            </div>
            <div className="flex flex-col">
              <div>Pricing</div>
              <div className="flex flex-row space-x-4">
                <Input
                  labelText={"slot name"}
                  labelFor={`slot name`}
                  name={`slot name`}
                  type={"text"}
                  isRequired={true}
                  placeholder={"display"}
                  customClass="w-full"
                  register={register}
                  errors={errors}
                  onChange={(e) => {setData({ ...data, pricing:{...data.pricing, slot:e.target.value} });}}
                />{" "}
                <Input
                  labelText={"unit"}
                  labelFor={`unit `}
                  name={`unit`}
                  type={"text"}
                  isRequired={true}
                  placeholder={"days"}
                  customClass="w-full"
                  register={register}
                  errors={errors}
                  onChange={(e) => {setData({ ...data, pricing:{...data.pricing, unit:e.target.value} });}}
                />{" "}
                <Input
                  labelText={"price/ unit"}
                  labelFor={`price per unit`}
                  name={`price per unit`}
                  type={"text"}
                  isRequired={true}
                  placeholder={"1000"}
                  customClass="w-full"
                  register={register}
                  errors={errors}
                  onChange={(e) => {setData({ ...data, pricing:{...data.pricing, pricePerUnit:e.target.value} });}}
                />{" "}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <h2 className="font-bold">Upload image:</h2>
                <Drag
                  label={"Upload one or more images of a billboard"}
                  files={billboardImage}
                  setFiles={setBillboardImage}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <div onClick={() => setToggleCreateSpace()}>Close</div>
          </Modal.DismissButton>
          <Button
            content="Save"
            loading={false}
            btnColor="primary"
            Style="text-white w-max h-[40px]  mx-4"
            type="submit"
          />
        </Modal.Footer>
        <ToasterComponent />
      </form>
    </Modal>
  );
};

export default AddBillboard;
