import React from 'react';
import { BsFillFilePersonFill } from 'react-icons/bs';
import { GrFormNext } from 'react-icons/gr';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { IoBusinessOutline } from 'react-icons/io5';


const Step1 = ({incrementStepper, setCompany}) => {
  const NextStep = () =>{
    incrementStepper()
  }

  const individualRegistration = () =>{
    NextStep()
    setCompany('individual')
  }
  const SMERegistration = () =>{
    NextStep()
    setCompany('sme')
  }
  const LERegistration = () =>{
    NextStep()
    setCompany('le')
  }
  return (
    <div className="flex flex-col h-fit w-fit font-nunito bg-secondary pt-8">
      <div className="flex flex-col">
        <h1 className="font-semibold text-xl mb-8 ">Select your company type</h1>
        <div className="w-fit flex flex-row items-center py-4 px-2 shadow-md rounded-md bg-white gap-4 mb-4 cursor-pointer hover:scale-110 transition ease-in" onClick={individualRegistration}>
          <BsFillFilePersonFill className="h-[50px] w-[50px]" />
          <div className="flex flex-col">
            <h1 className="font-bold text-lg">Individual</h1>
            <h2 className="text-base">Start your registration</h2>
          </div>
          <GrFormNext className="h-[50px] w-[50px]" />
        </div>
        <div className="w-fit flex flex-row items-center py-4 px-2 shadow-md rounded-md bg-white gap-4 mb-4 cursor-pointer hover:scale-110 transition ease-in"
        onClick={SMERegistration}>
          <HiOutlineOfficeBuilding className="h-[50px] w-[50px]" />
          <div className="flex flex-col">
            <h1 className="font-bold text-lg">S & M Enterprise</h1>
            <h2 className="text-base">Start your registration</h2>
          </div>
          <GrFormNext className="h-[50px] w-[50px]" />
        </div>
        <div className="w-fit flex flex-row items-center py-4 px-2 shadow-md rounded-md bg-white gap-4 mb-4 cursor-pointer hover:scale-110 transition ease-in"
        onClick={LERegistration}>
          <IoBusinessOutline className="h-[50px] w-[50px]" />
          <div className="flex flex-col">
            <h1 className="font-bold text-lg">Large Enterprise</h1>
            <h2 className="text-base">Start your registration</h2>
          </div>
          <GrFormNext className="h-[50px] w-[50px]" />
        </div>
      </div>
    </div>
  );
};

export default Step1;
