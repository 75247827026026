import React, { useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import Input from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import Drag from '../../../../components/Drag';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import axios from 'axios'; 

const AddTvModal = ({ addTv, setAddTv, setToggleCreateSpace }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [files, setFiles] = useState([]);

  const onSubmit = (data) => {
    axios
      .post('https://jsonplaceholder.typicode.com/posts', {
          name: 'RTV',
          description: 'RTV description',
          channels: [
            {
              name:"Canal +",
              channel:'340'
            },
            {
              name:"StarTimes",
              channel:'120'
            }
          ],
          logo: files,
      
      })
      .then((res) => {
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* modal of adding tv */}
      <Modal
        isOpen={addTv}
        onClose={() => setAddTv(!addTv)}
        Style={
          'scroll-modal  mt-8 mx-auto h-[90%] md:h-max md:max-h-[80%] w-[90%] md:w-[60%] md:max-w-[60%] overflow-y-scroll rounded-lg'
        }
      >
        <Modal.Header>Add Television</Modal.Header>
        <Modal.Body>
          <div
            className={`p-auto flex  items-center top-0 justify-center left-0 w-full h-full px-4 `}
          >
            {/* form */}
            <form
              action=""
              method=""
              className="w-full"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="flex flex-col md:flex-row md:space-x-16 w-full">
                <div className="flex flex-col w-full md:w-1/2 space-y-4">
                  <Input
                    key="01"
                    labelText={'Television name'}
                    labelFor={'name'}
                    id="01"
                    name={'name'}
                    type={'text'}
                    isRequired={true}
                    placeholder={'enter radio name'}
                    customClass="w-full"
                    handleChange={(e) => {
                      console.log(e.target.value);
                    }}
                    register={register}
                    errors={errors}
                  />{' '}
                  <TextArea
                    labelText={'Description'}
                    labelFor={'description'}
                    name={'reason'}
                    isRequired={true}
                    placeholder={'Specify other reason ...'}
                    customClass={`w-full mt-4 visible `}
                    handleChange={(e) => {
                      console.log(e.target.value);
                    }}
                    register={register}
                    errors={errors}
                  />
                  <Input
                    key="01"
                    labelText={'Tv channel'}
                    labelFor={'channel'}
                    id="01"
                    name={'channel'}
                    type={'text'}
                    isRequired={true}
                    placeholder={'enter tv channel name'}
                    customClass="w-full"
                    handleChange={(e) => {
                      console.log(e.target.value);
                    }}
                    register={register}
                    errors={errors}
                  />{' '}
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <div>
                    <h2 className="font-bold">Upload logo:</h2>
                    <Drag
                      uploadLimit={0}
                      label={'This is the logo of the radio'}
                      files={files}
                      setFiles={setFiles}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <div onClick={() => setToggleCreateSpace()}>Close</div>
          </Modal.DismissButton>
          <Button
            content="Save"
            loading={false}
            btnColor="primary"
            onClick={() => {
              setToggleCreateSpace();
              setAddTv(!addTv);
            }}
            Style="text-white w-max h-[40px]  mx-4"
          />
        </Modal.Footer>
      </Modal>
      {/* end of add radio the modal*/}
    </div>
  );
};

export default AddTvModal;
