import React from 'react'
import Table from '../../../components/tableComponent/Table'
import compaignsDashboard from '../../../seeds/compaignsDashboard.json'
import Button from '../../../components/Button';
import Modal from '../../../components/modal/Modal';
import { CgMoreVerticalAlt } from "react-icons/cg";

const Tabular = () => {
    const [show, setShow] = React.useState(false);
    const columns = [
        {
          Header: "Clients",
          accessor: "Logo",
          Cell: ({row}) => {
            return (
              <div className="flex flex-row items-center space-x-4 w-full ">
                <div className="flex flex-col">
                  <img src={row.original.Logo} alt="logo"  className='w-30 h-8'/>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm font-semibold">{row.original.Client}</p>
                </div>
              </div>
            );
          }
        },
        {
          Header: "Total Campaigns",
          accessor: "Total Campaigns",
        },
        {
          Header: "Total Spaces",
          accessor: "Total Spaces",
        },
        {
            Header: "Total bills",
            accessor: "Total bills",
          },
        {
          Header: "Action",
          Cell: () => {
         return   (
            <div className="flex flex-row  w-max">
                <div className="flex flex-col space-y-8">
                {/* <Button
                content="view"
                loading={false}
                onClick={() => {
                  setShow(true);
                }}
                btnColor="primary"
                Style={"text-white w-[70px] h-[40px] mr-4 ml-0 cursor-pointer"}
              /> */}
              <CgMoreVerticalAlt className="cursor-pointer" size={20} />
              </div>
            </div>
          )}
        },
      ];
    
  return (
    <div>
      {/*campaign details modal*/}
<Modal
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
        Style={"scroll-modal w-3/4 mt-8 mx-auto h-full max-h-[90%] bg-secondary"}
      >
        <Modal.Header>Supporting Document Details</Modal.Header>
        <Modal.Body>
          <div>
            <p>modal</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <Button
              content="Close"
              loading={false}
              onClick={() => setShow(false)}
              btnColor="primary"
              Style={"text-white w-[100px] h-[40px] mr-4 ml-0"}
            />
          </Modal.DismissButton>
        </Modal.Footer>
      </Modal>

      {/* end of campaign details modal*/}
        <Table columns={columns} data={compaignsDashboard} title="compaigns table"/>
    </div>
  )
}

export default Tabular