import React from "react";
import { IoHandLeft } from "react-icons/io5";
import { BiPhone } from "react-icons/bi";

const Footer = ({ telephone, address }) => {
  return (
    <div className="bg-primary text-white lg:px-[194px] flex flex-col h-max space-y-8 font-nunito py-4">
      <div className="flex flex-col m-auto lg:m-0 gap-4 lg:gap-0 lg:flex-row justify-between h-max">
        <div className="">
          <ul className="flex flex-col" >
            <li className="font-bold text-xl text-center">Contact us on</li>
            <li className="self-center">
              <div className="flex flex-row text-[14px]">
                <BiPhone size={20} color="white" />
                <h1>{telephone}</h1>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <ul className="flex flex-col space-">
            <li className="font-bold text-xl text-center">Address</li>
            {address.map((item, index) => {
              return (
                <li key={index} className="text-[14px] text-center">
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center w-full">
        <IoHandLeft size={60} color="white" />
      </div>
      <div className="flex flex-row items-center justify-center w-full">
        <div className="flex flex-col items-center justify-center">
          <h1>Say “Hi” to me @</h1>
          <h2>mytestemail@gmail.com</h2>
        </div>
      </div>
    </div>
  );
};

export default Footer;