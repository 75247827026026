import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { Tab } from '@headlessui/react';
import Table from '../../../components/tableComponent/Table';
import radio from '../../../seeds/radio.json';
import { BiSearch } from 'react-icons/bi';
import Button from '../../../components/Button';
import Collapsible from '../../../components/Collapsible';
import ShowDetailsModal from './Radio_modals/ShowDetailsModal';
import AddShowModal from './Radio_modals/AddShowModal';
import AddRadioModal from './Radio_modals/AddRadioModal';

// filter array items
export const filterData = (arr, data_id) => {
  return arr.filter((item) => item.id === data_id);
};

// list of radios

const radios = [
  { id: 1, name: 'Flash radio' },
  { id: 2, name: 'Radio rwanda' },
  { id: 3, name: 'Salus fm ' },
];

const Radio = () => {
  const columns = [
    {
      Header: <div className="flex items-start ">{'Show Title'}</div>,
      accessor: 'Show Title',
    },
    {
      Header: <div className="flex items-start ">{'Show Type'}</div>,
      accessor: 'Show Type',
    },
    {
      Header: <div className="flex items-start ">{'Time'}</div>,
      accessor: 'Time',
    },
    {
      Header: <div className="flex items-start ">{'Airtime'}</div>,
      accessor: 'Airtime',
    },
    {
      Header: (
        <div className="flex items-start ">{'Price per second'}</div>
      ),
      accessor: 'Price per second',
    },
    {
      Header: <div className="flex items-start ">{'Actions'}</div>,
      accessor: 'Actions',
      Cell: () => {
        return (
          <div className="flex flex-row  w-max font-bold font-nunito">
            <Popover className="relative flex flex-row">
              <div>
                <Popover.Button>
                  <FiMoreVertical size={30} className="cursor-pointer" />
                </Popover.Button>
              </div>
              <div>
                <Popover.Panel className="absolute z-10">
                  <div className="flex flex-col h-max w-max bg-[#DAD7CD] bg-opacity-50 border border-lg border-white ">
                    <ul className="flex flex-col space-y-2  ">
                      <li
                        className="hover:bg-[#D9D9D9] bg-cover px-4"
                        onClick={() => setOpenDetails(!openDetails)}
                      >
                        <div>view details</div>
                      </li>
                    </ul>
                  </div>
                </Popover.Panel>
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  //useState definition

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(1);
  const [openDetails, setOpenDetails] = useState(false);
  const [addRadio, setAddRadio] = useState(false);

  // onClickHandle

  const onClickHandle = () => {
    console.log('clicked');
    setIsOpen(!isOpen);
  };

  const myData = filterData(radio, data);

  return (
    <div className="mt-[43px] flex flex-col lg:flex-row font-nunito overflow-x-auto">
      <ShowDetailsModal
        openDetails={openDetails}
        setOpenDetails={() => setOpenDetails(false)}
      />
      <AddShowModal isOpen={isOpen} onClickHandle={onClickHandle} />
      <AddRadioModal addRadio={addRadio} setAddRadio={setAddRadio} setToggleCreateSpace={setAddRadio}/>

      {/* left panel */}
      <Collapsible
        key={1}
        Style={'lg:hidden w-full  h-fit mb-4'}
        heading={
          <h1 className="font-bold mr-2 text-lg text-fontColor">Flash radio</h1>
        }
        body={
          <div className="w-full flex flex-col mx-auto mb-8 lg:mb-0 ">
            <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
              <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                <p className="italic text-[#414141] mt-[21px]">All Radios</p>
                <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                  <BiSearch size={20} />
                  <input
                    defaultValue=""
                    placeholder="Search"
                    className="w-full rounded outline-none font-nunito"
                  />
                </div>
                <hr className="mt-2" />
              </div>
              <div className="pt-2 mx-[20px] overflow-y-auto">
                <div className="  text-base ">
                  {radios.map((radio, i) => {
                    return (
                      <div
                        className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                        onClick={() => {
                          setData(radio.id);
                        }}
                      >
                        <h1>{radio.name}</h1>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <Button
              content="+ Add Radio"
              loading={false}
              onClick={() => setAddRadio(true)}
              btnColor="primary"
              Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
            />
          </div>
        }
      />

      <div className="hidden lg:flex  w-full flex-col mx-auto mb-8 lg:mb-0 lg:min-w-[239px] lg:max-w-[239px] ">
        <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
          <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
            <p className="italic text-[#414141] mt-[21px]">All Radios</p>
            <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
              <BiSearch size={20} />
              <input
                defaultValue=""
                placeholder="Search"
                className="w-full rounded outline-none font-nunito"
              />
            </div>
            <hr className="mt-2" />
          </div>
          <div className="pt-2 mx-[20px] overflow-y-auto">
            <div className="  text-base ">
              {radios.map((radio, i) => {
                return (
                  <div
                    className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                    onClick={() => {
                      setData(radio.id);
                    }}
                  >
                    <h1>{radio.name}</h1>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Button
          content="+ Add Radio"
          loading={false}
          onClick={() => setAddRadio(true)}
          btnColor="primary"
          Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
        />
      </div>

      {/* end of left panel */}

      {/* start of right panel */}
      <div className="bg-white rounded-lg shadow-lg lg:ml-[9px] w-full p-[28px] ">
        <div className="flex justify-between">
          <div className="flex flex-row">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE42KdvyR9LuDFMGDOh-ph-1qovd_VN-6BPA1bNzBN8g&s"
              alt="logo"
            />
            <div className="flex flex-col  py-auto ml-[15px]">
              <div className="mx-auto  text-[20px] font-semibold w-max">
                <p>Flash FM</p>
                <p>89.2 FM</p>
              </div>
            </div>
          </div>
          <div>
            <Popover className="relative">
              <Popover.Button>
                <FiMoreHorizontal size={20} />
              </Popover.Button>

              <Popover.Panel className="absolute z-10">
                <div className="flex flex-col bg-white w-max h-[67px] mr-[27px] justify-around">
                  <a href="#" className="hover:bg-[#D9D9D9]">
                    Edit
                  </a>
                  <a href="#" className="hover:bg-[#D9D9D9]">
                    Delete
                  </a>
                </div>

                <img src="/solutions.jpg" alt="" />
              </Popover.Panel>
            </Popover>
          </div>
        </div>
        <div className="mt-[71px]">
          <Tab.Group manual>
            <Tab.List className="flex flex-row justify-between w-[360px] items-start font-bold">
              <Tab>Shows(3)</Tab>
              <Tab>Pricing</Tab>
              <Tab>About Radio</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="mt-[28px] flex flex-col">
                  <div className="w-full flex items-end justify-end">
                    <Button
                      content="+ Add show"
                      loading={false}
                      onClick={onClickHandle}
                      btnColor="primary"
                      Style="text-white w-fit h-[40px] mr-4 ml-0"
                    />
                  </div>
                  <div>
                    <Table columns={columns} data={myData} className="w-full" />
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>Pricing</Tab.Panel>
              <Tab.Panel>About radio</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      {/* end of right panel */}
    </div>
  );
};

export default Radio;
