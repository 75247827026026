import React, { useState } from 'react';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useForm } from 'react-hook-form';
import { AiFillMinusCircle } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';

const SingleDay = ({ event, setEvent }) => {
  const handleLocation = (e) => {
    const { value } = e.target;
    setEvent((prevData) => {
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
           location:value,
          }
        ]
      };
    });
  };

  const handleVenue = (e) => {
    const { value } = e.target;
    setEvent((prevData) => {
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
           venue:value,
          }
        ]
      };
    });
  };

  const handleDate = (e) => {
    const { value } = e.target;
    setEvent((prevData) => {
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
           date:value,
          }
        ]
      };
    });
  };

  const handleFrom = (e) => {
    const { value } = e.target;
    setEvent((prevData) => {
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            from:value,
          }
        ]
      };
    });
  };

  const handleTo = (e) => {
    const { value } = e.target;
    setEvent((prevData) => {
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            to:value,
          }
        ]
      };
    });
  };

  const addEventValues = () => {
    setEvent((prevData) => {
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            pricing: [
              ...prevData.singleDaySchedule[0].pricing,
              { name: '', unit: '', ppu: '' },
            ],
          },
        ],
      };
    });
  };

  const handleNameEventValues = (e, index) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const editSlotName = [...prevData.singleDaySchedule[0].pricing];
      editSlotName[index].name = value;
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            pricing: editSlotName,
          },
        ],
      };
    });
  };

  const handleUnitEventValues = (e, index) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const editSlotUnit = [...prevData.singleDaySchedule[0].pricing];
      editSlotUnit[index].unit = value;
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            pricing: editSlotUnit,
          },
        ],
      };
    });
  };

  const handlePpuEventValues = (e, index) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const editSlotPpu = [...prevData.singleDaySchedule[0].pricing];
      editSlotPpu[index].ppu = value;
      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            pricing: editSlotPpu,
          },
        ],
      };
    });
  };

  const removeEventValues = (index) => {
    setEvent((prevData) => {
      const newPricing = [...prevData.singleDaySchedule[0].pricing];
      newPricing.splice(index, 1);

      return {
        ...prevData,
        singleDaySchedule: [
          {
            ...prevData.singleDaySchedule[0],
            pricing: newPricing,
          },
        ],
      };
    });
  };

  const {
    register,
    // handleSubmit,
    formState: { errors },
    // reset,
  } = useForm();
  return (
    <div className="flex flex-col p-4 w-full">
      <div className="flex flex-col lg:flex-row lg:gap-12">

      {/* <Input
                  labelText={'Show Title'}
                  labelFor={'title'}
                  name={'title'}
                  type={'text'}
                  isRequired={true}
                  placeholder={'enter show title'}
                  customClass="w-full mb-2"
                  onChange={(e) => {
                    setCreateShow((prevData) => {
                      return {
                        ...prevData,
                        title: e.target.value,
                      };
                    });
                  }}
                  register={register}
                  value={createShow.title || ''}
                  errors={errors}
                /> */}

        <Input
          labelText={'Location'}
          labelFor={'eventLocation1'}
          name={'eventLocation1'}
          type={'text'}
          isRequired={true}
          placeholder={'location'}
          customClass="w-full lg:w-1/2 mb-2"
          onChange={(e) => {
            handleLocation(e);
          }}
          register={register}
          errors={errors}
          value={event.singleDaySchedule[0].location }
         
        />

        <Input
          labelText={'Venue'}
          labelFor={'venue'}
          name={'venue'}
          type={'text'}
          isRequired={true}
          placeholder={'venue'}
          customClass="w-full lg:w-1/2 mb-2"
          onChange={(e) => {
            handleVenue(e);
          }}
          register={register}
          errors={errors}
          value={event.singleDaySchedule[0].venue }
        />
      </div>

      <div className="flex flex-col lg:flex-row lg:gap-12">
        <Input
          labelText={'Date'}
          labelFor={'date'}
          name={'date'}
          type={'date'}
          isRequired={true}
          placeholder={'date'}
          customClass="w-full lg:w-1/2 mb-2"
          onChange={(e) => {
            handleDate(e);
          }}
          register={register}
          errors={errors}
          value={event.singleDaySchedule[0].date }
        />
        <div className="flex flex-row lg:gap-4 justify-between lg:justify-start w-full lg:w-1/2">
          <Input
            labelText={'From'}
            labelFor={'from'}
            name={'from'}
            type={'time'}
            isRequired={true}
            placeholder={'start'}
            customClass="w-[45%] lg:w-full mb-2"
            onChange={(e) => {
              handleFrom(e);
            }}
            register={register}
            errors={errors}
            value={event.singleDaySchedule[0].from }
          />
          <Input
            labelText={'To'}
            labelFor={'to'}
            name={'to'}
            type={'time'}
            isRequired={true}
            placeholder={'end'}
            customClass="w-[45%] lg:w-full mb-2"
            onChange={(e) => {
              handleTo(e);
            }}
            register={register}
            errors={errors}
            value={event.singleDaySchedule[0].to}
          />
        </div>
      </div>



      <div className="flex flex-row relative ">
        <div className="pt-2 pb-4 px-4 border border-black/20 rounded-md bg-secondary w-full">
          {event.singleDaySchedule[0].pricing.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex flex-row bg-white p-2  rounded-md ${
                  index === 0 ? 'mb-2' : ''
                }`}
              >
                <div className="flex flex-col lg:flex-row lg:gap-4 w-full">
                  <Input
                    labelText="Slot name"
                    labelFor={`slotName[${index}]`}
                    name={`slotName[${index}]`}
                    type={'text'}
                    isRequired={false}
                    placeholder={'e.g: dj mention'}
                    customClass="text-sm w-full mb-2"
                    onChange={(e) => {
                      handleNameEventValues(e, index);
                    }}
                    register={register}
                    value={item.name || ''}
                  />{' '}
                  <Input
                    labelText={'Unit'}
                    labelFor={`unit[${index}]`}
                    name={`unit[${index}]`}
                    type={'text'}
                    isRequired={false}
                    placeholder={'eg: seconds'}
                    customClass="text-sm w-full mb-2"
                    onChange={(e) => {
                      handleUnitEventValues(e, index);
                    }}
                    register={register}
                    value={item.unit || ''}
                  />{' '}
                  <Input
                    labelText={'Price/unit'}
                    labelFor={`ppu[${index}]`}
                    name={`ppu[${index}]`}
                    type={'text'}
                    isRequired={false}
                    placeholder={'eg: 5000 Rwf'}
                    customClass="text-sm w-full mb-2"
                    onChange={(e) => {
                      handlePpuEventValues(e, index);
                    }}
                    register={register}
                    value={item.ppu || ''}
                  />{' '}
                </div>
                <AiFillMinusCircle
                  color="#BDBDBD"
                  size={25}
                  className={`cursor-pointer ${
                    index === 0 ? 'hidden' : 'visible'
                  }`}
                  onClick={() => removeEventValues(index)}
                />
              </div>
            );
          })}
        </div>

        <Button
          type="button"
          content={<HiPlus color="#FBFBFB" size={20} />}
          loading={false}
          onClick={addEventValues}
          btnColor="primary"
          Style={
            'absolute right-[0px] py-[3px] bottom-[0px] text-white w-fit px-[4px] m-2'
          }
        />
      </div>

      
    </div>
  );
};

export default SingleDay;
