// import React, { useState } from 'react';
// import { Popover } from '@headlessui/react';
// import { FiMoreHorizontal } from 'react-icons/fi';
// import { filterData } from './Radio';

// const Website = () => {
//   const [data, setData] = useState(1);
//   const myData = filterData(web, data);
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="mt-[43px] flex flex-row mr-[33px] font-nunito">
//       {/* left panel */}
//       <div>
//         <div className="w-[239px] h-[280px] outline outline-1 outline-primary flex flex-col bg-[#F6F6F6]">
//           <div className="flex flex-row justify-between border border-b-primary border-white border-b-[0.5px] w-[197px] pb-[12px] mx-[20px]">
//             <p className="italic text-[#414141] mt-[21px]">All Events</p>
//             <div className="bg-primary text-white w-[48px] flex flex-row items-center h-[40px]  py-[14.67px] px-[18.67] mt-[12px]">
//               <button
//                 className="mx-auto text-white"
//                 onClick={() => setIsOpen(!isOpen)}
//               >
//                 +
//               </button>
//             </div>
//           </div>
//           <div className="pt-[28px] mx-[20px]">
//             {web.map((element) => (
//               <ul className=" space-y-2 text-[14px] ">
//                 <li
//                   className="hover:bg-primary cursor-pointer hover:text-white py-[11px] w-full px-[19px]"
//                   onClick={() => setData(element.id)}
//                 >
//                   {element.title}
//                 </li>
//               </ul>
//             ))}
//           </div>
//         </div>
//         <div>
//           <button
//             className={`bg-primary text-white w-[239px] h-[40px] py-[12px] flex items-center justify-center mt-[32px] `}
//             onClick={() => setIsOpen(!isOpen)}
//           >
//             + Add social media
//           </button>
//         </div>
//       </div>

//       {/* end ofleft panel */}

//       {/* start of right panel */}
//       <div className="bg-[#F6F6F6] outline outline-1 lg:ml-[9px] w-[961px] h-[598px] p-[28px] flex flex-col">
//         <div className=" h-[189px] flex">
//           <div className=" w-1/2 flex flex-col pt-[38px]">
//             <p className="text-[32px] font-[500]">{myData[0].title}</p>
//             <p>{myData[0].web}</p>
//           </div>
//           <div className="w-1/2 flex justify-end pt-[17px]">
//             <Popover className="relative">
//               <Popover.Button>
//                 <FiMoreHorizontal />
//               </Popover.Button>

//               <Popover.Panel className="absolute z-10">
//                 <div className="flex flex-col bg-white w-[178px] h-[67px] mr-[27px]">
//                   <a href="#" className="hover:bg-[#D9D9D9]">
//                     Edit
//                   </a>
//                   <a href="#" className="hover:bg-[#D9D9D9]">
//                     Delete
//                   </a>
//                 </div>

//                 <img src="/solutions.jpg" alt="" />
//               </Popover.Panel>
//             </Popover>
//           </div>
//         </div>
//         <hr className="w-[900px]  bg-[#BEBEBE]  mb-[13px]" />
//         <div className="h-[225px] flex flex-col">
//           <div className="flex flex-col pb-[34px]">
//             <p className="text-[15px] font-[500] opacity-70">Category</p>
//             <p className="text-[20px] font-[500]">{myData[0].category}</p>
//           </div>

//           <div className="flex flex-col pb-[13px]">
//             <p className="text-[15px] font-[500] opacity-70">Price</p>
//             <p className="text-[20px] font-[500]">{myData[0].price}</p>
//           </div>
//         </div>
//         <hr className="w-[900px]  bg-[#BEBEBE]  mb-[13px]" />
//         <div className="h-[182px]">
//           <div className="flex flex-col pb-[13px]">
//             <p className="text-[15px] font-[500] opacity-70">Description</p>
//             <p className="text-[14px] font-[500] w-[535px] h-[60px]">
//               {myData[0].description}
//             </p>
//           </div>
//         </div>
//       </div>
//       {/* end of right panel */}

//       {/* start of modal */}

//       <div
//         class={`modal fade fixed p-auto ${
//           isOpen ? 'flex' : 'hidden'
//         } items-center top-0 justify-center left-0 w-full h-full overflow-x-hidden overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm`}
//         id="exampleModalCenter"
//         aria-labelledby="exampleModalCenterTitle"
//         aria-modal="true"
//         role="dialog"
//       >
//         <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
//           <div class="modal-content shadow-lg relative flex flex-col w-[961px] h-[574px] pointer-events-auto bg-white border  rounded-md">
//             <div class="modal-header flex flex-col px-[48px] py-[37px] w-full h-full border-b border-gray-200 rounded-t-md">
//               <h5
//                 class="text-[24px] font-normal  font-nunito leading-normal text-gray-800"
//                 id="exampleModalScrollableLabel"
//               >
//                 Add Website
//               </h5>
//               {/* form */}
//               <form
//                 action=""
//                 method=""
//                 className="w-full mt-[67px]"
//                 onSubmit={(e) => e.preventDefault()}
//               >
//                 <div className="flex items-center">
//                   <div className="flex flex-col mr-[24px]">
//                     <label htmlFor="title">Space Type</label>
//                     <select
//                       name=""
//                       id=""
//                       className="block w-[423px] h-[38px] px-[16px] bg-white border focus:outline-none"
//                     >
//                       <option value="">Social Media</option>
//                       <option value="">Website</option>
//                       <option value="">Radio</option>
//                       <option value="">TV</option>
//                     </select>
//                   </div>

//                   <div className="flex flex-col">
//                     <label htmlFor="time">Content Category</label>
//                     <input
//                       type="text"
//                       className="w-[384px] h-[38px] px-[16px] border focus:outline-none"
//                       placeholder="enter category"
//                     />
//                   </div>
//                 </div>

//                 <div className="flex mt-[16px] items-center ">
//                   <div className="flex flex-col mr-[24px] ">
//                     <label htmlFor="title">Name</label>
//                     <input
//                       type="text"
//                       className="w-[423px] h-[38px] px-[16px] border focus:outline-none"
//                       placeholder="name"
//                     />
//                   </div>
//                   <div className="flex flex-col">
//                     <label htmlFor="title">link</label>
//                     <input
//                       type="text"
//                       className="w-[384px] h-[38px] px-[16px] border focus:outline-none"
//                       placeholder="link"
//                     />
//                   </div>
//                 </div>

//                 <div className="flex mt-[30px]">
//                   <div className="flex flex-col mr-[24px]">
//                     <label htmlFor="">Description</label>
//                     <textarea
//                       name=""
//                       id=""
//                       className="w-[422px] h-[114px] border focus:outline-none px-2 py-2"
//                     ></textarea>
//                   </div>
//                   <div className="flex flex-col">
//                     <label htmlFor="title">Price</label>
//                     <input
//                       type="text"
//                       className="w-[384px] h-[38px] px-[16px] border focus:outline-none"
//                       placeholder="price"
//                     />
//                   </div>
//                 </div>
//                 <div className="flex mt-[40px] items-center justify-between w-1/6">
//                   <button
//                     className="bg-primary w-[64px] text-white text-[16px] h-[40px]"
//                     onClick={() => setIsOpen(!isOpen)}
//                     type="submit"
//                   >
//                     Save
//                   </button>
//                   <button
//                     className="bg-transparent w-[64px] text-[#414141] h-[40px]"
//                     onClick={() => setIsOpen(!isOpen)}
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/*end of modal*/}
//     </div>
//   );
// };

// export default Website;

import React, { useEffect, useState, Fragment } from 'react';
import { filterData } from './Radio';
import { Popover, Transition } from '@headlessui/react';
import Collapsible from '../../../components/Collapsible';
import { FiMoreHorizontal } from 'react-icons/fi';
import { BiSearch } from 'react-icons/bi';
import Button from '../../../components/Button';
import AddWebsite from './Website_modals/AddWebsite';
import Carausal from '../../../components/Carousal';
import ContentModal from './Website_modals/ContentModal';
const websites = [
  {
    id: 0,
    title: 'IGIHE',
    web: 'www.igihe.com',
    category: 'NEWS',
    logo: 'https://www.igihe.com/local/cache-vignettes/L276xH107/arton151116-5dafe.png?1620896476',
    description:
      'IGIHE is a leading news website that provides readers with reliable and insightful coverage of the latest news stories from Rwanda and around the world. With a team of experienced journalists and editors. IGIHE covers a wide range of topics, from politics and business to sports and entertainment. Advertisers who partner with IGIHE can reach a highly engaged and active audience of readers who value quality journalism and seek out the latest news and information. With its reputation for excellence and professionalism, IGIHE is the perfect platform for businesses looking to grow their brand and connect with a wider audience.',
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
  },
  {
    id: 1,
    title: 'INYARWANDA',
    web: 'www.inyarwanda.com',
    category: 'NEWS',
    logo: 'https://music.inyarwanda.com/assets/img/logo.png',
    description:
      'INYARWANDA is a leading news website that provides readers with reliable and insightful coverage of the latest news stories from Rwanda and around the world. With a team of experienced journalists and editors. INYARWANDA covers a wide range of topics, from politics and business to sports and entertainment. Advertisers who partner with INYARWANDA can reach a highly engaged and active audience of readers who value quality journalism and seek out the latest news and information. With its reputation for excellence and professionalism, INYARWANDA is the perfect platform for businesses looking to grow their brand and connect with a wider audience.',
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
  },
  {
    id: 2,
    title: 'BWIZA',
    web: 'www.bwiza.com',
    category: 'NEWS',
    logo: 'https://bwiza.com/assets/img/cropped-Logo-1-192x192.jpg',
    description:
      'BWIZA is a leading news website that provides readers with reliable and insightful coverage of the latest news stories from Rwanda and around the world. With a team of experienced journalists and editors. BWIZA covers a wide range of topics, from politics and business to sports and entertainment. Advertisers who partner with BWIZA can reach a highly engaged and active audience of readers who value quality journalism and seek out the latest news and information. With its reputation for excellence and professionalism, BWIZA is the perfect platform for businesses looking to grow their brand and connect with a wider audience.',
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
  },
  {
    id: 3,
    title: 'Kigali Today',
    web: 'www.kigalitoday.com',
    category: 'NEWS',
    logo: 'https://pbs.twimg.com/profile_images/463334039617691648/A-hi9wfg_400x400.png',
    description:
      'Kigali Today is a leading news website that provides readers with reliable and insightful coverage of the latest news stories from Rwanda and around the world. With a team of experienced journalists and editors. Kigali Today covers a wide range of topics, from politics and business to sports and entertainment. Advertisers who partner with Kigali Today can reach a highly engaged and active audience of readers who value quality journalism and seek out the latest news and information. With its reputation for excellence and professionalism, Kigali Today is the perfect platform for businesses looking to grow their brand and connect with a wider audience.',
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
  },
];

const Webs = ['IGIHE', 'Inyarwanda', 'BWIZA', 'Kigali Today'];

const Website = () => {
  const [webModal, setWebModal] = useState(false);
  const [contentModal, setContentModal] = useState(false);
  const [webDisplay, setWebDisplay] = useState(0);
  const [content, setContent] = useState(null);

  // const Contents = [1, 2, 3, 4, 5];
  const Contents = [
    {
      link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
      type: 'video',
    },
    {
      link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
      type: 'video',
    },
    {
      link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
      type: 'video',
    },
    {
      link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
      type: 'video',
    },
    {
      link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
      type: 'video',
    },
    {
      link: 'https://images.unsplash.com/photo-1679312995136-4bfc25c1936f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI0fGFldTZyTC1qNmV3fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
      type: 'image',
    },
  ];

  const LinkComponent = (content, index) => {
    if (content[index].type === 'image') {
      return (
        <div className="h-full">
          <img
            src={content[index].link}
            alt="Image"
            className="w-full h-full object-fill"
          />
        </div>
      );
    } else if (content[index].type === 'video') {
      return (
        <video src={content[index].link} controls className="w-full h-full" />
      );
    }

    return null;
  };

  return (
    <>
      <AddWebsite show={webModal} setShow={() => setWebModal(!webModal)} />
      <ContentModal
        show={contentModal}
        setShow={() => setContentModal(!contentModal)}
        content={content}
      />
      <div className="mt-[43px] flex flex-col lg:flex-row font-nunito">
        {/* left panel */}
        <Collapsible
          key={1}
          Style={'lg:hidden w-full  h-fit mb-4'}
          heading={
            <h1 className="font-bold mr-2 text-lg text-fontColor">IGIHE</h1>
          }
          body={
            <div className="w-full flex flex-col mx-auto mb-8 lg:mb-0 ">
              <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
                <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                  <p className="italic text-[#414141] mt-[21px]">
                    All Websites
                  </p>
                  <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                    <BiSearch size={20} />
                    <input
                      defaultValue=""
                      placeholder="Search"
                      className="w-full rounded outline-none font-nunito"
                    />
                  </div>
                  <hr className="mt-2" />
                </div>
                <div className="pt-2 mx-[20px] overflow-y-auto">
                  <div className="  text-base ">
                    {Webs.map((website, i) => {
                      return (
                        <div
                          className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                          onClick={() => {
                            setWebDisplay(website.id);
                          }}
                        >
                          <h1>{website}</h1>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <Button
                content="+ Add Website"
                loading={false}
                onClick={() => {
                  setWebModal(!webModal);
                }}
                btnColor="primary"
                Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
              />
            </div>
          }
        />

        <div className="hidden lg:flex w-full lg:min-w-[239px] lg:max-w-[239px] flex-col mx-auto mb-8 lg:mb-0 ">
          <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
            <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
              <p className="italic text-[#414141] mt-[21px]">All Websites</p>
              <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                <BiSearch size={20} />
                <input
                  defaultValue=""
                  placeholder="Search"
                  className="w-full rounded outline-none font-nunito"
                />
              </div>
              <hr className="mt-2" />
            </div>
            <div className="pt-2 mx-[20px] overflow-y-auto">
              <div className="  text-base ">
                {Webs.map((website, i) => {
                  return (
                    <div
                      className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                      onClick={() => {
                        setWebDisplay(i);
                      }}
                    >
                      <h1>{website}</h1>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <Button
            content="+ Add Website"
            loading={false}
            onClick={() => {
              setWebModal(!webModal);
            }}
            btnColor="primary"
            Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
          />
        </div>

        {/* start of right panel */}
        <div>
          <div className="bg-white rounded-lg shadow-lg lg:ml-[9px] w-full h-max p-7">
            <div className="flex flex-col">
              <div className=" flex pb-7">
                <div className="flex flex-col lg:flex-row gap-2 w-full">
                  <div className="w-[300px] h-[150px]">
                    <img
                      src={websites[`${webDisplay}`].logo}
                      alt=""
                      className="w-[300px] h-[150px]"
                    />
                  </div>
                  <div className=" w-full lg:w-1/2 flex flex-col ">
                    <p className="text-[36px] w-full h-[49px] font-[500]">
                      {websites[`${webDisplay}`].title}
                    </p>
                    <p className="text-base font-bold cursor-pointer text-link">
                      {websites[`${webDisplay}`].web}
                    </p>

                    <div className="flex flex-row gap-3 w-full">
                      <div className="flex flex-row  gap-1 items-center">
                        <h1 className="text-light  font-bold text-base w-fit my-2">
                          Category
                        </h1>
                        <h2 className="text-black text-base ">
                          {websites[`${webDisplay}`].category}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-[17px]">
                  <Popover className="relative">
                    <Popover.Button>
                      <FiMoreHorizontal size={20} />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        className="absolute z-10  "
                        // style={styles.popper}
                        // {...attributes.popper}
                      >
                        <div className="flex flex-col bg-white shadow-md border border-black/10 w-[80px] rounded-sm text-xs">
                          <h1
                            className="p-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                            onClick={() => {}}
                          >
                            Edit
                          </h1>

                          <h1 className="p-2 hover:bg-[#D9D9D9] text-center cursor-pointer">
                            Delete
                          </h1>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
              <div className="py-4">
                <div className="mb-6">
                  <h1 className="text-xl font-bold pt-4 pb-2">Analytics</h1>
                  <div className="flex flex-row flex-wrap gap-6">
                    <h3 className="text-primary/70">
                      Profile visits <b className="text-primary">8,201</b>
                    </h3>

                    <h3 className="text-primary/70">
                      Total impressions <b className="text-primary">150K</b>
                    </h3>
                    <h3 className="text-primary/70">
                      Total engagements <b className="text-primary">20K</b>
                    </h3>
                    <h3 className="text-primary/70">
                      Conversion rate <b className="text-primary">7.14%</b>
                    </h3>
                  </div>
                </div>
                <h1 className="text-lg font-bold text-light">Pricing</h1>
                <div className="flex flex-row flex-wrap gap-6">
                  {websites[`${webDisplay}`].pricing.map((item) => {
                    return (
                      <h3 className="text-light font-bold">
                        {item.slot}:
                        <b className="text-primary ml-1">
                          {item.ppu} <span className="text-light">per</span>{' '}
                          {item.unit}
                        </b>
                      </h3>
                    );
                  })}
                </div>
              </div>
            </div>
            <hr className="w-full  bg-[#BEBEBE]  mb-[13px]" />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col pb-[13px]">
                <p className="text-lg font-bold text-light">Description</p>
                <p className="text-base font-[500]">
                  {websites[`${webDisplay}`].description}
                </p>
              </div>
            </div>
            <hr className="w-full  bg-[#BEBEBE]  mb-[13px]" />
            <div className="">
              <h1 className="text-lg font-bold text-light mb-3">My Content</h1>
              <div className="max-w-[1100px] w-fit mx-auto">
                <Carausal slidesToShow={3} slidesToScroll={3} Style={'mx-auto'}>
                  {Contents.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="w-full h-[200px]  cursor-pointer"
                        onClick={() => {
                          setContent(item);
                          setContentModal(!contentModal);
                        }}
                      >
                        {LinkComponent(Contents, i)}
                      </div>
                    );
                  })}
                </Carausal>
              </div>
            </div>
          </div>
        </div>

        {/* end of right panel */}

        {/* start of modal */}

        {/* end of modal*/}
      </div>
    </>
  );
};

export default Website;
