import React, { useEffect, useState, Fragment } from 'react';
import {
  TikTokEmbed,
  InstagramEmbed,
  TwitterEmbed,
  LinkedInEmbed,
  FacebookEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';

import { filterData } from './Radio';
import { Popover, Transition } from '@headlessui/react';
import SubMenu from '../../../components/SubMenu';
import Collapsible from '../../../components/Collapsible';
import Carausal from '../../../components/Carousal';
import { FiMoreHorizontal } from 'react-icons/fi';
import { BiSearch } from 'react-icons/bi';
import Button from '../../../components/Button';
import Twitter from '../Creators/Twitter';
import LinkedIn from '../Creators/LinkedIn';
import Facebook from '../Creators/Facebook';
import Instagram from '../Creators/Instagram';
import TikTok from '../Creators/TikTok';
import Youtube from '../Creators/Youtube';
import AddAccount from './Creator_modals/AddAccount';
import ContentModal from './Creator_modals/ContentModal';

export const events = [
  {
    description:
      "Meet John, a fashion and fitness influencer with 200k+ followers. He promotes only the brands he believes in and has personally tried. John's authentic approach and engaging content have built him a loyal following of customers eager to engage with the products he endorses. He aims to inspire his followers to live a happy and fulfilling life through fashion, fitness, and healthy living.",
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
    id: 0,
  },
  {
    description:
      "Meet John, a fashion and fitness influencer with 200k+ followers and 500+ connections. He promotes only the brands he believes in and has personally tried. John's authentic approach and engaging content have built him a loyal following of customers eager to engage with the products he endorses. He aims to inspire his followers to live a happy and fulfilling life through fashion, fitness, and healthy living.",
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Shares',
        ppu: '20Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'post',
      },
      {
        slot: 'Clicks',
        ppu: '30 Rwf',
        unit: 'click',
      },
    ],
    id: 1,
  },
  {
    description:
      "Meet John, a fashion and fitness influencer with 200k+ Twitter followers. He promotes only the brands he believes in and has personally tried. John's authentic approach and engaging content have built him a loyal following of customers eager to engage with the products he endorses. He aims to inspire his followers to live a happy and fulfilling life through fashion, fitness, and healthy living.",
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
    id: 2,
  },
  {
    description:
      "Meet John, a fashion and fitness influencer with 200k+ Twitter followers. He promotes only the brands he believes in and has personally tried. John's authentic approach and engaging content have built him a loyal following of customers eager to engage with the products he endorses. He aims to inspire his followers to live a happy and fulfilling life through fashion, fitness, and healthy living.",
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
    id: 3,
  },
  {
    description:
      "Meet John, a fashion and fitness influencer with 200k+ Twitter followers. He promotes only the brands he believes in and has personally tried. John's authentic approach and engaging content have built him a loyal following of customers eager to engage with the products he endorses. He aims to inspire his followers to live a happy and fulfilling life through fashion, fitness, and healthy living.",
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
    id: 4,
  },
  {
    description:
      "Meet John, a fashion and fitness influencer with 200k+ Twitter followers. He promotes only the brands he believes in and has personally tried. John's authentic approach and engaging content have built him a loyal following of customers eager to engage with the products he endorses. He aims to inspire his followers to live a happy and fulfilling life through fashion, fitness, and healthy living.",
    pricing: [
      {
        slot: 'Impressions',
        ppu: '10Rwf',
        unit: 'Impression',
      },
      {
        slot: 'Engagements',
        ppu: '13Rwf',
        unit: 'Engagement',
      },
      {
        slot: 'Brand mentioning',
        ppu: '30,000 Rwf',
        unit: 'tweet',
      },
    ],
    id: 5,
  },
];

const Accounts = [
  {
    name: 'Twitter',
    accounts: [
      { name: 'John Doe' },
      { name: 'Twitter 2' },
    ],
    id: 0,
  },
  {
    name: 'Instagram',
    accounts: [
      { name: 'J_Doe' },
      { name: 'Instagram 2' },
    ],
    id: 3,
  },
  {
    name: 'Facebook',
    accounts: [{ name: 'John Doe' }, { name: 'Facebook 2' }],
    id: 2,
  },
  {
    name: 'linkedIn',
    accounts: [
      { name: 'John Doe' },
      { name: 'LinkedIn 2' },
    ],
    id: 1,
  },
  {
    name: 'TikTok',
    accounts: [{ name: 'Johnny' }, { name: 'TikTok 2' }],
    id: 4,
  },
  {
    name: 'Youtube',
    accounts: [{ name: 'Joe Fashions' }, { name: 'Youtube 2' }],
    id: 5,
  },
];

const Contents = [
  {
    link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
    type: 'video',
  },

  {
    link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
    type: 'video',
  },
  {
    link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
    type: 'video',
  },
  {
    link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
    type: 'video',
  },
  {
    link: 'https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4',
    type: 'video',
  },
  {
    link: 'https://images.unsplash.com/photo-1679312995136-4bfc25c1936f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI0fGFldTZyTC1qNmV3fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    type: 'image',
  },
];

const previews = [
  [
    {
      link: 'https://twitter.com/PixelAndBracket/status/1356633038717923333',
      type: 'preview',
      acc: 'twitter',
    },
    {
      link: 'https://twitter.com/PixelAndBracket/status/1356633038717923333',
      type: 'preview',
      acc: 'twitter',
    },
    {
      link: 'https://twitter.com/PixelAndBracket/status/1356633038717923333',
      type: 'preview',
      acc: 'twitter',
    },
    {
      link: 'https://twitter.com/BillGates/status/1641859479485714433',
      type: 'preview',
      acc: 'twitter',
    },
    {
      link: 'https://twitter.com/elonmusk/status/1647345410162450432',
      type: 'preview',
      acc: 'twitter',
    },
  ],
  [
    {
      link: 'https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384',
      type: 'preview',
      acc: 'linkedIn',
    },
    {
      link: 'https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384',
      type: 'preview',
      acc: 'linkedIn',
    },
    {
      link: 'https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384',
      type: 'preview',
      acc: 'linkedIn',
    },
    {
      link: 'https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384',
      type: 'preview',
      acc: 'linkedIn',
    },
  ],
  [
    {
      link: 'https://www.facebook.com/andrewismusic/posts/451971596293956',
      type: 'preview',
      acc: 'facebook',
    },
    {
      link: 'https://www.facebook.com/andrewismusic/posts/451971596293956',
      type: 'preview',
      acc: 'facebook',
    },
    {
      link: 'https://www.facebook.com/andrewismusic/posts/451971596293956',
      type: 'preview',
      acc: 'facebook',
    },
    {
      link: 'https://www.facebook.com/andrewismusic/posts/451971596293956',
      type: 'preview',
      acc: 'facebook',
    },
  ],
  [
    {
      link: 'https://www.instagram.com/p/CUbHfhpswxt/',
      type: 'preview',
      acc: 'instagram',
    },
    {
      link: 'https://www.instagram.com/p/CUbHfhpswxt/',
      type: 'preview',
      acc: 'instagram',
    },
    {
      link: 'https://www.instagram.com/p/CUbHfhpswxt/',
      type: 'preview',
      acc: 'instagram',
    },
    {
      link: 'https://www.instagram.com/p/CUbHfhpswxt/',
      type: 'preview',
      acc: 'instagram',
    },
  ],
  [
    {
      link: 'https://www.tiktok.com/@epicgardening/video/7055411162212633903',
      type: 'preview',
      acc: 'tiktok',
    },
    {
      link: 'https://www.tiktok.com/@epicgardening/video/7055411162212633903',
      type: 'preview',
      acc: 'tiktok',
    },
    {
      link: 'https://www.tiktok.com/@epicgardening/video/7055411162212633903',
      type: 'preview',
      acc: 'tiktok',
    },
    {
      link: 'https://www.tiktok.com/@epicgardening/video/7055411162212633903',
      type: 'preview',
      acc: 'tiktok',
    },
  ],
  [
    {
      link: 'https://www.youtube.com/watch?v=HpVOs5imUN0',
      type: 'preview',
      acc: 'youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=HpVOs5imUN0',
      type: 'preview',
      acc: 'youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=HpVOs5imUN0',
      type: 'preview',
      acc: 'youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=HpVOs5imUN0',
      type: 'preview',
      acc: 'youtube',
    },
  ],
];

const Creators = () => {
  const [accountModal, setAccountModal] = useState(false);
  const [contentModal, setContentModal] = useState(false);
  const [content, setContent] = useState(null);
  const [accountDisplay, setAccountDisplay] = useState(0);

  const LinkComponent = (content, index) => {
    if (content[index].type === 'image') {
      return (
        <div className="h-full">
          <img
            src={content[index].link}
            alt="Image"
            className="w-full h-full object-fill"
          />
        </div>
      );
    } else if (content[index].type === 'video') {
      return (
        <video src={content[index].link} controls className="w-full h-full" />
      );
    }
    return null;
  };

  const previewComponent = (preview, index) => {
    if (preview.acc === 'twitter') {
      return <TwitterEmbed url={preview.link} width={350} />;
    } else if (preview.acc === 'linkedIn') {
      return <LinkedInEmbed url={preview.link} width={350} />;
    } else if (preview.acc === 'facebook') {
      return <FacebookEmbed url={preview.link} width={350} />;
    } else if (preview.acc === 'instagram') {
      return <InstagramEmbed url={preview.link} width={350} />;
    } else if (preview.acc === 'tiktok') {
      return <TikTokEmbed url={preview.link} width={350} />;
    } else if (preview.acc === 'youtube') {
      return <YouTubeEmbed url={preview.link} width={350} />;
    }
  };

  return (
    <>
      <AddAccount
        show={accountModal}
        setShow={() => setAccountModal(!accountModal)}
      />
      <ContentModal
        show={contentModal}
        setShow={() => setContentModal(!contentModal)}
        content={content}
      />

      <div className="mt-[43px] flex flex-col lg:flex-row font-nunito">
        {/* left panel */}
        <Collapsible
          key={1}
          Style={'lg:hidden w-full  h-fit mb-4'}
          heading={
            <h1 className="font-bold mr-2 text-lg text-fontColor">
              Twitter (John Doe)
            </h1>
          }
          body={
            <div className="w-full flex flex-col mx-auto mb-8 lg:mb-0 ">
              <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
                <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                  <p className="italic text-[#414141] mt-[21px]">
                    All Accounts
                  </p>
                  <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                    <BiSearch size={20} />
                    <input
                      defaultValue=""
                      placeholder="Search"
                      className="w-full rounded outline-none font-nunito"
                    />
                  </div>
                  <hr className="mt-2" />
                </div>
                <div className="pt-2 mx-[20px] overflow-y-auto">
                  <div className="  text-base ">
                    {Accounts.map((platform, i) => {
                      return (
                        <div
                          className="cursor-pointer w-full px-2 font-bold"
                          onClick={() => {}}
                        >
                          <SubMenu
                            key={1}
                            Style={' w-full  h-fit mb-4'}
                            heading={
                              <h1 className="font-bold mr-2 text-lg text-fontColor ">
                                {platform.name}
                              </h1>
                            }
                            body={
                              <div className="">
                                {platform.accounts.map((account) => {
                                  return (
                                    <h1
                                      className="pl-4 hover:bg-primary py-1 hover:text-white "
                                      onClick={() =>
                                        setAccountDisplay(platform.id)
                                      }
                                    >
                                      {account.name}
                                    </h1>
                                  );
                                })}
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <Button
                content="+ Add Account"
                loading={false}
                onClick={() => {
                  setAccountModal(!accountModal);
                }}
                btnColor="primary"
                Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
              />
            </div>
          }
        />

        <div className="hidden lg:flex w-full lg:min-w-[239px] lg:max-w-[239px] flex-col mx-auto mb-8 lg:mb-0 ">
          <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
            <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
              <p className="italic text-[#414141] mt-[21px]">All Accounts</p>
              <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                <BiSearch size={20} />
                <input
                  defaultValue=""
                  placeholder="Search"
                  className="w-full rounded outline-none font-nunito"
                />
              </div>
              <hr className="mt-2" />
            </div>
            <div className="pt-2 mx-[20px] overflow-y-auto">
              <div className="  text-base ">
                {Accounts.map((platform, i) => {
                  return (
                    <div
                      className="cursor-pointer w-full px-2 font-bold"
                      onClick={() => {}}
                    >
                      <SubMenu
                        key={1}
                        Style={' w-full  h-fit mb-4'}
                        heading={
                          <h1 className="font-bold mr-2 text-lg text-fontColor ">
                            {platform.name}
                          </h1>
                        }
                        body={
                          <div className="">
                            {platform.accounts.map((account) => {
                              return (
                                <h1
                                  className="pl-4 hover:bg-primary py-1 hover:text-white "
                                  onClick={() => setAccountDisplay(platform.id)}
                                >
                                  {account.name}
                                </h1>
                              );
                            })}
                          </div>
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <Button
            content="+ Add Account"
            loading={false}
            onClick={() => {
              setAccountModal(!accountModal);
            }}
            btnColor="primary"
            Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
          />
        </div>

        {/* start of right panel */}
        <div>
          <div className="bg-white rounded-lg shadow-lg lg:ml-[9px] w-full h-max p-7">
            <div className=" flex ">
              {accountDisplay === 0 && <Twitter />}
              {accountDisplay === 1 && <LinkedIn />}
              {accountDisplay === 2 && <Facebook />}
              {accountDisplay === 3 && <Instagram />}
              {accountDisplay === 4 && <TikTok />}
              {accountDisplay === 5 && <Youtube />}
            </div>
            <div className="py-4">
              <h1 className="text-lg font-bold text-light">Pricing</h1>
              <div className="flex flex-row flex-wrap gap-6">
                {events[`${accountDisplay}`].pricing.map((item) => {
                  return (
                    <h3 className="text-light font-bold">
                      {item.slot}:
                      <b className="text-primary ml-1">
                        {item.ppu} <span className="text-light">per</span>{' '}
                        {item.unit}
                      </b>
                    </h3>
                  );
                })}
              </div>
            </div>
            <hr className="w-full  bg-[#BEBEBE]  mb-[13px]" />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col pb-[13px]">
                <p className="text-lg font-bold text-light">Description</p>
                <p className="text-base font-[500]">
                  {events[`${accountDisplay}`].description}
                </p>
              </div>
            </div>
            <hr className="w-full  bg-[#BEBEBE]  mb-[13px]" />

            <div className="">
              <h1 className="text-lg font-bold text-light mb-3">My Content</h1>
              <div className="max-w-[1100px] w-fit mx-auto">
                <Carausal
                  slidesToShow={3}
                  slidesToScroll={3}
                  Style={'mx-auto '}
                >
                  {Contents.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="w-full h-[200px]  cursor-pointer"
                        onClick={() => {
                          setContent(item);
                          setContentModal(!contentModal);
                        }}
                      >
                        {LinkComponent(Contents, i)}
                      </div>
                    );
                  })}
                </Carausal>
              </div>
            </div>

            <div className="pt-6">
              <h1 className="text-lg font-bold text-light mb-3">
                Checkout my content
              </h1>
              <div className="max-w-[1100px] w-fit mx-auto">
                <Carausal
                  slidesToShow={3}
                  slidesToScroll={3}
                  Style={'mx-auto '}
                >
                  {previews[accountDisplay].map((preview, index) => {
                    return (
                      <div key={index} className="w-full cursor-pointer">
                        {previewComponent(preview)}
                      </div>
                    );
                  })}
                </Carausal>
              </div>
            </div>
          </div>
        </div>

        {/* end of right panel */}

        {/* start of modal */}

        {/* end of modal*/}
      </div>
    </>
  );
};

export default Creators;
