import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../components/Input";
import { HiPlus } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import Button from "../../../components/Button";

const CompanyStep3 = ({ companyProfile, setCompanyProfile }) => {
  // add location dynamically

  const addAddress = () => {
    setCompanyProfile((prevData) => {
      return {
        ...prevData,
        location: [...prevData.location, { name: "" }],
      };
    });
  };

  const handleAddress = (e, index) => {
    const { value } = e.target;
    setCompanyProfile((prevData) => {
      const newLocation = [...prevData.location];
      newLocation[index] = {
        name: value,
      };
      return {
        ...prevData,
        location: newLocation,
      };
    });
  };

  const handleRemoveAddress = (index) => {
    setCompanyProfile((prevData) => {
      const locationArray = [...prevData.location];
      locationArray.splice(index, 1);
      return {
        ...prevData,
        location: locationArray,
      };
    });
  };

  // end

  // configure the dynamic form for social media

  const addSocialMedia = () => {
    setCompanyProfile((prevData) => {
      return {
        ...prevData,
        socialMedia: [...prevData.socialMedia, { name: "", link: "" }],
      };
    });
  };

  const handleSmName = (e, index) => {
    const { value } = e.target;
    setCompanyProfile((prevData) => {
      const newSocialMedia = [...prevData.socialMedia];
      newSocialMedia[index] = {
        ...newSocialMedia[index],
        name: value,
      };
      return {
        ...prevData,
        socialMedia: newSocialMedia,
      };
    });
  };

  const handleSmLink = (e, index) => {
    const { value } = e.target;
    setCompanyProfile((prevData) => {
      const newSocialMedia = [...prevData.socialMedia];
      newSocialMedia[index] = {
        ...newSocialMedia[index],
        link: value,
      };
      return {
        ...prevData,
        socialMedia: newSocialMedia,
      };
    });
  };

  const handleRemoveSocial = (index) => {
    setCompanyProfile((prevData) => {
      const smArray = [...prevData.socialMedia];
      smArray.splice(index, 1);
      return {
        ...prevData,
        socialMedia: smArray,
      };
    });
  };

  // end

  // handle email function

  const handleEmail = (e) => {
    const { value } = e.target;
    setCompanyProfile((prevData) => {
      return {
        ...prevData,
        email: value,
      };
    });
  };

  const {
    register,
    // handleSubmit,
    formState: { errors },
    //reset,
  } = useForm();
  return (
    <div className={`flex flex-row bg-secondary m-0 w-full h-fit font-nunito`}>
      <div className="flex flex-col w-full  py-8">
        <div className="flex flex-col w-full mx-auto my-8 md:my-auto lg:my-4 xlg:my-4 xl:my-4">
          <h1 className="text-[18px] md:text-[20px] lg:text-[20px] xlg:text-[20px] xl:text-[20px]mb-4">
            Enter company address
          </h1>
          <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
            click add icon to add your address
          </h1>

          <div className="flex flex-col space-y-8">
            <div className="flex flex-row space-x-2 items-end ">
              <div className="relative rounded-lg w-full px-[33px] bg-white drop-shadow-lg ">
                <div className="mb-12">
                  {companyProfile.location.map((item, index) => {
                    return (
                      <div className="flex flex-row items-center space-x-2">
                        <Input
                          labelText={` Location address ${index + 1}`}
                          labelFor={`comAddress ${index + 1}`}
                          name={`comAddress ${index + 1}`}
                          type={"text"}
                          placeholder={"enter the address"}
                          customClass="w-full my-2"
                          register={register}
                          errors={errors}
                          onChange={(e) => handleAddress(e, index)}
                          value={item.name}
                        />{" "}
                        <AiFillMinusCircle
                          color="#BDBDBD"
                          size={25}
                          className={`cursor-pointer ${
                            index === 0 ? "hidden" : "visible"
                          }`}
                          onClick={() => handleRemoveAddress(index)}
                        />
                      </div>
                    );
                  })}
                </div>
                <Button
                  type="button"
                  content={<HiPlus color="#FBFBFB" size={20} />}
                  loading={false}
                  onClick={addAddress}
                  btnColor="primary"
                  Style={
                    "absolute right-[0px] py-[6px] bottom-[0px] text-white w-fit px-[8px] m-2"
                  }
                />
              </div>
            </div>
            {/* social media */}
            <div className="flex flex-col">
              <h1 className="text-[18px] md:text-[20px] lg:text-[20px] xlg:text-[20px] xl:text-[20px]">
                Social media link
              </h1>
              <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
                click add icon to add your social media
              </h1>
              <div className="flex flex-row space-x-2 ">
                <div className="relative rounded-lg w-full px-[33px] bg-white drop-shadow-lg">
                  <div className="mb-12">
                    {companyProfile.socialMedia.map((item, index) => {
                      // Company social media name = comSmName
                      // Company social media link = comSmLink
                      return (
                        <div className="flex flex-row items-center space-x-2">
                          <Input
                            labelText={"Name"}
                            labelFor={`comSmName ${index + 1}`}
                            name={`comSmName ${index + 1}`}
                            type={"text"}
                            placeholder={"add name"}
                            customClass="w-full my-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => handleSmName(e, index)}
                            value={item.name}
                          />{" "}
                          <Input
                            labelText={"Link"}
                            labelFor={`comSmLink  ${index + 1}`}
                            name={`comSmLink  ${index + 1}`}
                            type={"text"}
                            placeholder={"add link"}
                            customClass="w-full my-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => handleSmLink(e, index)}
                            value={item.link}
                          />{" "}
                          <AiFillMinusCircle
                            color="#BDBDBD"
                            size={50}
                            className={`cursor-pointer ${
                              index === 0 ? "hidden" : "visible"
                            }`}
                            onClick={() => handleRemoveSocial(index)}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <Button
                    type="button"
                    content={<HiPlus color="#FBFBFB" size={20} />}
                    loading={false}
                    onClick={addSocialMedia}
                    btnColor="primary"
                    Style={
                      "absolute right-[0px] py-[6px] bottom-[0px] text-white w-fit px-[8px] m-2"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* company email = comEmail */}
        <Input
          labelText={"Email"}
          labelFor={"comEmail"}
          name={"comEmail"}
          type={"email"}
          isRequired={false}
          placeholder={"example@gmail.com"}
          customClass="w-full mb-2"
          register={register}
          onChange={(e) => handleEmail(e)}
          value={companyProfile.email || ""}
        />{" "}
      </div>
    </div>
  );
};

export default CompanyStep3;
