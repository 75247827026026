import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";

const Drag = ({ style, flexStyle, uploadLimit,label,files, setFiles }) => {
  
  const [disabled, setDisabled] = useState(false);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles((files) => [...files, ...acceptedFiles]);
      if(files.length === uploadLimit){
        setDisabled(true);
      }
    },
    maxFiles: uploadLimit,
    disabled: disabled,
  });

  const handleDelete = (file) => {
    setFiles((files) => files.filter((f) => f !== file));
  };

 

  return (
    <div className={`${flexStyle} font-nunito`}>
      <div className="w-fit -h-fit flex flex-col">
        <label className="mb-1">{label}</label>
      <div
        className={`bg-white ${style} container border border-dotted flex flex-col p-[16px] items-center justify-center rounded-lg`}
      >
        <div
          {...getRootProps({
            className:
              "dropzone relative mb-[25px] flex flex-col space-y-2 items-center justify-center",
          })}
        >
          <input {...getInputProps()} />
          <button
          type="button"
            onClick={open }
            className="flex items-center justify-center top-0 left-0  w-full z-1 h-full  border-none outline-none rounded-[4px]"
          >
            <i className="">
              <FiUpload size={20} />
            </i>
          </button>
          <div>
            <h1>
              Drag and drop or
              <button type="button" onClick={open} className="font-bold mx-2">
                Choose file
              </button>
              to upload.{" "}
            </h1>
          </div>
        </div>
      </div>
      </div>
      <aside>
        <h4>{files.length !== 0 ? "Files" : "No files uploaded"}</h4>
        <ul className="w-full flex flex-col space-y-2">
          {files.map((file) => (
            <li key={file.name} className="flex items-center justify-between bg-secondary  shadow-lg px-2 py-2 w-full flex-row space-between rounded-sm">
              <div className="flex items-center space-x-2">
                <span>{file.name}</span>
              </div>
              <button type='button' onClick={() => handleDelete(file)}>
                <AiFillDelete className="h-6 w-6" />
              </button>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
};

export default Drag;
