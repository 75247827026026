import React from 'react';
import Background from '../../../Assets/BackG.png';
import Profile from '../../../Assets/Avatar.png';

const LinkedIn = () => {
  return (
    <div className="flex-flex-col w-full h-full">
      <div className="h-[40px] w-[40px] mx-auto">
        <img
          src="https://img.icons8.com/color/512/linkedin.png"
          alt=""
          className='w-fit h-fit object-contain'
        />
      </div>
      <div className="relative flex flex-col items-center w-full h-[210px]">
        <img src={Background} alt="" className="object-fill w-full h-[210px]" />
        <div className="absolute  bottom-[-50px] h-[130px] w-[130px] border-[5px] border-white rounded-full left-[100px] transform -translate-x-1/2">
          <img
            src={Profile}
            alt=""
            className="h-full w-full rounded-full object-cover"
          />
        </div>
      </div>
      <div className="pt-16">
        <div className='flex flex-col'>
          <h1 className='text-2xl font-bold'>John Doe</h1>
        </div>
        <div className="flex flex-row gap-4">
          <h1 className="text-primary/70">
            <b className="text-primary">200.9K</b> Followers
          </h1>
          <h1 className="text-primary/70">
            <b className="text-primary">500+</b> Connections
          </h1>
        </div>
        <div>
          <h1 className="text-xl font-bold pt-4 pb-2">Metrics</h1>
          <div className="flex flex-row flex-wrap gap-6">
            <h3 className="text-primary/70">
              Profile visits <b className="text-primary">8,201</b>
            </h3>

            <h3 className="text-primary/70">
              Total impressions <b className="text-primary">150K</b>
            </h3>
            <h3 className="text-primary/70">
              Total engagements <b className="text-primary">20K</b>
            </h3>
            <h3 className="text-primary/70">
              Conversion rate <b className="text-primary">7.14%</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedIn;
