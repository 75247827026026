import React, { useState } from 'react';
import Modal from '../../components/modal/Modal';
import Drag from '../../components/Drag';
import Button from '../../components/Button';
import Select from '../../components/Select';


const RequestPayment = ({ show, setShow }) => {
  const [payment, setPayment] = useState('');
  const [files, setFiles] = useState([]);
  const paymentOptions = [
    { value: 1, label: 'Momo Pay' },
    { value: 2, label: 'Airtel Money' },
    { value: 3, label: 'Bank' },
  ];
  return (
    <div>
      <Modal
        isOpen={show}
        onClose={() => setShow()}
        Style={'w-1/3 min-h-[60%] p-4 flex m-auto'}
      >
        <Modal.Body>
          <div className="flex flex-col p-4">
            <h1 className="font-nunito text-2xl text-center text-black font-bold mb-8">
              Supporting documents
            </h1>
            <div>
              <Drag style="w- h-max" flexStyle="flex flex-col space-y-3" files={files} setFiles={setFiles}/>

              <Select
                label="Select payment method"
                customClass="mt-8"
                className="w-3/4"
                isLoading={false}
                placeholder={'Select reason'}
                defaultValue={paymentOptions}
                onChange={(e) => {
                  setPayment(e.label);
                }}
                options={paymentOptions}
              />
            </div>
            <div className="flex justify-between mt-8">
              <Button
                type="submit"
                content="Cancel"
                loading={false}
                btnColor="secondary"
                onClick={() => {
                  setShow();
                }}
                Style={'text-fontcolor h-[40px] mt-2 ml-0 border'}
              />

              <Button
                type="submit"
                content="Submit"
                loading={false}
                btnColor="primary"
                onClick={() => {
                  setShow();
                }}
                Style={'text-white h-[40px] mt-2 ml-0'}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestPayment;
