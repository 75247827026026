import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/modal/Modal';
import Button from '../../../../components/Button';
import { useForm } from 'react-hook-form';

const ContentModal = ({ show, setShow, content }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const LinkComponent = (content) => {
    console.log(content,'content')
    if (content.type === 'image') {
      return (
        <div className="h-full">
          <img
            src={content.link}
            alt="Image"
            className="w-full h-full object-fill"
          />
        </div>
      );
    } else if (content.type === 'video') {
      return (
        <video src={content.link} controls  />
      );
    }

    return null;
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow()}
      Style={
        'm-auto max-h-[90%] w-[95%] lg:w-[60%]  overflow-y-auto rounded-lg font-nunito z-20'
      }
    >
      <Modal.Header>
        <h1 className="font-bold text-xl">Content</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col items-center w-full">
          {show && LinkComponent(content)}
          <div className="flex flex-row w-full justify-end mt-8">
            <div className="flex flex-row gap-4">
              <Button
                content="Close"
                loading={false}
                btnColor="white"
                Style="text-black w-max h-[40px]"
                type="button"
                onClick={() => {
                  setShow();
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContentModal;
