import React, {useState} from 'react'
import Sidebar from './Sidebar'
import { AiOutlineHome, AiOutlineTablet } from 'react-icons/ai';
import { BiCube } from 'react-icons/bi';
import { FiDollarSign } from 'react-icons/fi';
import { RxDashboard } from 'react-icons/rx';
import TopBar from './TopBar';

const Layout = ({ children }) => {

  const [activePage, setActivePage] = useState('Home');
  const [toggleCreateSpace, setToggleCreateSpace] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const Menus = [
    { title: 'Home', path: '/', src: <AiOutlineHome /> },
    { title: 'Spaces', path: '/space', src: <BiCube /> },
    { title: 'Campaigns', path: '/campaign', src: <AiOutlineTablet /> },
    { title: 'Billing', path: '/billing', src: <FiDollarSign />},
    { title: 'Dashboard', path: '/dashboard', src: <RxDashboard /> },
  ]
  
    return (
        <>
            <div className='flex flex-auto h-screen'>
                <Sidebar Menus={Menus} setLogoutModal={() => setLogoutModal(!logoutModal)}/>
                <div className='grow'>
                    <TopBar 
                    activePage={activePage}
                    setActivePage={setActivePage}
                    toggleCreateSpace={toggleCreateSpace}
                    setToggleCreateSpace={() => {setToggleCreateSpace(!toggleCreateSpace)}}
                    showNotification={showNotification}
                    toggleNotification={toggleNotification}

                    />
                    <div className='m-0'>{children}</div>
                </div>
            </div>
        </>
    )
}

export default Layout
