import React, { useState } from 'react';
import StepCombiner from './StepCombiner';
import HandShake from '../../Assets/handShake.jpeg';
import registerBackground from '../../Assets/registerBackground.jpg';
import Konvey from '../../Assets/Konvey.jpeg';
const Registration = () => {
  const [stepCount, setStepCount] = useState(0);
  console.log(stepCount);
  const incrementStepper = () => {
    if (stepCount === 4) {
      return
    } else {
      setStepCount((prevCount) => {
        return prevCount + 1;
      });
    }
  };
  const decrementStepper = () => {
    setStepCount((prevCount) => {
      return prevCount - 1;
    });
  };
  return (
    <div className="flex flex-col w-full h-full font-nunito bg-secondary mb-0">
      <div className="flex flex-row w-full h-full">
        <div className="w-full md:w-1/2 ">
          <div className="fixed top-[0px] h-[40px] z-[10] pl-4 pt-1 w-full md:w-1/2 bg-secondary">
            {' '}
            <img src={Konvey} alt="" />
          </div>
          <div className="relative top-[40px] overflow-y-auto fixedHeight md:px-8 xlg:px-16">
            <StepCombiner
              stepCount={stepCount}
              incrementStepper={incrementStepper}
              decrementStepper={decrementStepper}
            />
          </div>
        </div>
        <div className="w-1/2 h-full hidden md:block pointer-events-none ">
          <img
            src={stepCount === 0 ? HandShake : registerBackground}
            alt=""
            className="w-full object-fill h-full"
          />
          <h1 className="absolute bottom-8 text-4xl font-bold text-white mx-8">
            “Konvey is simple, explore konvey , bring your campaigns and join
            the Konvey Community.”
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Registration;
