import React from "react";
import Table from "../../../components/tableComponent/Table";
import spacesDashboard from "../../../seeds/spacesDashboard.json";
import Button from "../../../components/Button";
import Modal from "../../../components/modal/Modal";
import { CgMoreVerticalAlt } from "react-icons/cg";

const Tabular = () => {
  const [show, setShow] = React.useState(false);
  const columns = [
    {
      Header: "Space name",
      accessor: "Space name",
    },
    {
      Header: "Space category",
      accessor: "Space category",
    },
    {
      Header: "Total clients",
      accessor: "Total clients",
    },
    {
      Header: "Total campaigns",
      accessor: "Total campaigns",
    },
    {
      Header: "Total revenues",
      accessor: "Total revenues",
    },
    {
      Header: "Action",
      Cell: () => {
        return (
          <div className="flex flex-row  w-max">
            <div className="flex flex-col space-y-8">
              {/* <Button
                content="view"
                loading={false}
                onClick={() => {
                  setShow(true);
                }}
                btnColor="primary"
                Style={"text-white w-[70px] h-[40px] mr-4 ml-0 cursor-pointer"}
              /> */}
              <CgMoreVerticalAlt className="cursor-pointer" size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      {/*campaign details modal*/}
      <Modal
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
        Style={
          "scroll-modal w-3/4 mt-8 mx-auto h-full max-h-[90%] bg-secondary"
        }
      >
        <Modal.Header>Supporting Document Details</Modal.Header>
        <Modal.Body>
          <div>
            <p>modal</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <Button
              content="Close"
              loading={false}
              onClick={() => setShow(false)}
              btnColor="primary"
              Style={"text-white w-[100px] h-[40px] mr-4 ml-0"}
            />
          </Modal.DismissButton>
        </Modal.Footer>
      </Modal>

      {/* end of campaign details modal*/}
      <Table columns={columns} data={spacesDashboard} title="compaigns table" />
    </div>
  );
};

export default Tabular;
