import React, { useState } from 'react';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import { useForm } from 'react-hook-form';
import { AiFillMinusCircle } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';

const DifLocation = ({ event, setEvent }) => {
  // const [eventValues, setEventValues] = useState([
  //   { name: '', unit: '', ppu: '' },
  // ]);
  // const [locationValues, setLocationValues] = useState([
  //   {
  //     date: '',
  //     location: '',
  //     venue: '',
  //     from: '',
  //     to: '',
  //     eventValues: [{ name: '', unit: '', ppu: '' }],
  //   },
  // ]);

  // const addLocationValues = () => {
  //   setLocationValues((prevLocationValues) => [
  //     ...prevLocationValues,
  //     {
  //       date: '',
  //       venue: '',
  //       from: '',
  //       to: '',
  //       eventValues: [{ name: '', unit: '', ppu: '' }],
  //     },
  //   ]);
  // };

  // const removeLocationValues = (index) => {
  //   setLocationValues((prevLocationValues) => {
  //     const newArray = [...prevLocationValues];
  //     newArray.splice(index, 1);
  //     return newArray;
  //   });
  // };

  // const addEventValues = (index) => {
  //   setLocationValues((prevLocationValues) => {
  //     const newArray = [...prevLocationValues];
  //     newArray[index] = {
  //       ...newArray[index],
  //       eventValues: [
  //         ...newArray[index].eventValues,
  //         { name: '', unit: '', ppu: '' },
  //       ],
  //     };
  //     console.log(newArray, 'newArray');
  //     return newArray;
  //   });
  // };
  // const removeEventValues = (locationIndex, eventIndex) => {
  //   setLocationValues((prevLocationValues) => {
  //     const newArray = [...prevLocationValues];
  //     const location = newArray[locationIndex];
  //     const eventValues = [...location.eventValues];
  //     eventValues.splice(eventIndex, 1);
  //     newArray[locationIndex] = { ...location, eventValues };
  //     return newArray;
  //   });
  // };

  const addEvent = () => {
    setEvent((prevData) => {
      return {
        ...prevData,
        diffLocationSchedule: [
          ...prevData.diffLocationSchedule,
          {
            location: '',
            venue: '',
            date: '',
            from: '',
            to: '',
            pricing: [
              {
                name: '',
                unit: '',
                ppu: '',
              },
            ],
          },
        ],
      };
    });
  };

  const handleEventLocation = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.location = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, diffLocationSchedule: updatedDiffLocationSchedule };
    });
  };

  const handleEventVenue = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.venue = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, diffLocationSchedule: updatedDiffLocationSchedule };
    });
  };
  const handleEventDate = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.date = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, diffLocationSchedule: updatedDiffLocationSchedule };
    });
  };
  const handleEventFrom = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.from = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, diffLocationSchedule: updatedDiffLocationSchedule };
    });
  };
  const handleEventTo = (e, eventIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.to = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return { ...prevData, diffLocationSchedule: updatedDiffLocationSchedule };
    });
  };

  const handleSlotName = (e, eventIndex, pricingIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.pricing[pricingIndex].name = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return {
        ...prevData,
        diffLocationSchedule: updatedDiffLocationSchedule,
      };
    });
  };

  const handleSlotUnit = (e, eventIndex, pricingIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.pricing[pricingIndex].unit = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return {
        ...prevData,
        diffLocationSchedule: updatedDiffLocationSchedule,
      };
    });
  };


  const handleSlotPpu = (e, eventIndex, pricingIndex) => {
    const { value } = e.target;
    setEvent((prevData) => {
      const updatedDiffLocationSchedule = [...prevData.diffLocationSchedule];
      const eventToUpdate = updatedDiffLocationSchedule[eventIndex];
      eventToUpdate.pricing[pricingIndex].ppu = value;
      updatedDiffLocationSchedule[eventIndex] = eventToUpdate;
      return {
        ...prevData,
        diffLocationSchedule: updatedDiffLocationSchedule,
      };
    });
  };

  const removeEvent = (eventIndex) => {
    setEvent((prevData) => {
      const updatedSchedule = [...prevData.diffLocationSchedule];
      updatedSchedule.splice(eventIndex, 1);
      return {
        ...prevData,
        diffLocationSchedule: updatedSchedule,
      };
    });
  };

  const addEventPricing = (eventIndex) => {
    setEvent((prevData) => {
      const newdiffLocationSchedule = prevData.diffLocationSchedule.map(
        (event, i) => {
          if (i !== eventIndex) {
            return event;
          }
          const newPricing = [
            ...event.pricing,
            {
              name: '',
              unit: '',
              ppu: '',
            },
          ];
          return { ...event, pricing: newPricing };
        }
      );
      return { ...prevData, diffLocationSchedule: newdiffLocationSchedule };
    });
  };

  const handleRemoveEventPricing = (eventIndex, pricingIndex) => {
    setEvent((prevData) => {
      const eventsArray = prevData.diffLocationSchedule;
      const pricingArray = eventsArray[eventIndex].pricing;
      pricingArray.splice(pricingIndex, 1); // Remove the pricing at the specified pricingIndex
      eventsArray[eventIndex].pricing = pricingArray;
      return {
        ...prevData,
        diffLocationSchedule: eventsArray,
      };
    });
  };

  const {
    register,
    // handleSubmit,
    formState: { errors },
    // reset,
  } = useForm();
  return (
    <div className="flex flex-col p-4 w-full">
      {event.diffLocationSchedule.map((item, eventIndex) => {
        return (
          <div className="flex flex-row relative mb-6 " key={eventIndex}>
            <div className="flex flex-col p-4 border border-black/20 rounded-lg">
              <div className="flex flex-col lg:flex-row lg:gap-12">
                <Input
                  labelText={'Location'}
                  labelFor={`eventLocation${eventIndex + 1}`}
                  name={`eventLocation${eventIndex + 1}`}
                  type={'text'}
                  isRequired={true}
                  placeholder={'location'}
                  customClass="w-full lg:w-1/2 mb-2"
                  onChange={(e) => {
                    handleEventLocation(e, eventIndex);
                  }}
                  register={register}
                  errors={errors}
                  value={item.location}
                />

                <Input
                  labelText={'Venue'}
                  labelFor={`venue${eventIndex + 1}`}
                  name={`venue${eventIndex + 1}`}
                  type={'text'}
                  isRequired={true}
                  placeholder={'venue'}
                  customClass="w-full lg:w-1/2 mb-2"
                  register={register}
                  errors={errors}
                  onChange={(e) => {
                    handleEventVenue(e, eventIndex);
                  }}
                  value={item.venue}
                />
              </div>

              <div className="flex flex-col lg:flex-row lg:gap-12">
                <Input
                  labelText={'Date'}
                  labelFor={`date${eventIndex + 1}`}
                  name={`date${eventIndex + 1}`}
                  type={'date'}
                  isRequired={true}
                  placeholder={'date'}
                  customClass="w-full lg:w-1/2 mb-2"
                  register={register}
                  errors={errors}
                  onChange={(e) => {
                    handleEventDate(e, eventIndex);
                  }}
                  value={item.date}
                />
                <div className="w-full lg:w-1/2 flex justify-between lg:justify-start lg:gap-4">
                  <Input
                    labelText={'From'}
                    labelFor={`from${eventIndex + 1}`}
                    name={`from${eventIndex + 1}`}
                    type={'time'}
                    isRequired={true}
                    placeholder={'start'}
                    customClass="w-[45%] lg:w-1/2 mb-2"
                    register={register}
                    errors={errors}
                    onChange={(e) => {
                      handleEventFrom(e, eventIndex);
                    }}
                    value={item.from}
                  />
                  <Input
                    labelText={'To'}
                    labelFor={`to${eventIndex + 1}`}
                    name={`to${eventIndex + 1}`}
                    type={'time'}
                    isRequired={true}
                    placeholder={'end'}
                    customClass="w-[45%] lg:w-1/2 mb-2"
                    register={register}
                    errors={errors}
                    onChange={(e) => {
                      handleEventTo(e, eventIndex);
                    }}
                    value={item.to}
                  />
                </div>
              </div>

              <div className="flex flex-row relative ">
                <div className="pt-2 pb-4 px-4 border border-black/20 rounded-md bg-secondary w-full">
                  {item.pricing.map((eventValue, pricingIndex) => {
                    return (
                      <div
                        key={pricingIndex}
                        className="flex flex-row bg-white p-2  rounded-md mb-2"
                      >
                        <div className="flex flex-col lg:flex-row lg:gap-4 w-full">
                          <Input
                            labelText="Slot name"
                            labelFor={`slotName[${eventIndex}][${pricingIndex}]`}
                            name={`slotName[${eventIndex}][${pricingIndex}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'e.g: dj mention'}
                            customClass="text-sm w-full mb-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                              handleSlotName(e, eventIndex, pricingIndex);
                            }}
                            value={item.name}
                          />{' '}
                          <Input
                            labelText={'Unit'}
                            labelFor={`unit[${eventIndex}][${pricingIndex}]`}
                            name={`unit[${eventIndex}][${pricingIndex}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'eg: seconds'}
                            customClass="text-sm w-full mb-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                              handleSlotUnit(e, eventIndex, pricingIndex);
                            }}
                            value={item.unit}
                          />{' '}
                          <Input
                            labelText={'Price/unit'}
                            labelFor={`ppu[${eventIndex}][${pricingIndex}]`}
                            name={`ppu[${eventIndex}][${pricingIndex}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'eg: 5000 Rwf'}
                            customClass="text-sm w-full mb-2"
                            register={register}
                            errors={errors}
                            onChange={(e) => {
                              handleSlotPpu(e, eventIndex, pricingIndex);
                            }}
                            value={item.ppu}
                          />{' '}
                        </div>
                        <AiFillMinusCircle
                          color="#BDBDBD"
                          size={25}
                          className={`cursor-pointer ${
                            pricingIndex === 0 ? 'hidden' : 'visible'
                          }`}
                          onClick={() =>
                            handleRemoveEventPricing(eventIndex, pricingIndex)
                          }
                        />
                      </div>
                    );
                  })}
                </div>

                <Button
                  type="button"
                  content={<HiPlus color="#FBFBFB" size={20} />}
                  loading={false}
                  onClick={() => {
                    // console.log(index1, 'index1');
                    addEventPricing(eventIndex);
                  }}
                  btnColor="primary"
                  Style={
                    'absolute right-[0px] py-[3px] bottom-[0px] text-white w-fit px-[4px] m-2'
                  }
                />
              </div>
            </div>
            <AiFillMinusCircle
              color="#BDBDBD"
              size={25}
              className={` absolute right-[-45px] cursor-pointer mx-2 dynMinus ${
                eventIndex === 0 ? 'hidden' : 'visible'
              }`}
              onClick={() => {
                removeEvent(eventIndex);
              }}
            />

            <Button
              type="button"
              content={<HiPlus color="#FBFBFB" size={20} />}
              loading={false}
              onClick={() => addEvent()}
              btnColor="primary"
              Style={`absolute right-[-10px] lg:right-[-45px] py-[3px] bottom-[-30px]  lg:bottom-[0px] text-white w-fit px-[4px] mx-2  ${
                eventIndex === event.diffLocationSchedule.length - 1
                  ? 'visible'
                  : 'hidden'
              }`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DifLocation;
