import React, { useState } from 'react';
import { BiChevronRight } from 'react-icons/bi';

function Collapsible({ heading, body,key, Style }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`shadow-md rounded-lg border border-black/10 ${Style}`} key={key}>
      <div
        className="flex items-center justify-between p-3 cursor-pointer  transition-all duration-300 ease-in-out"
        onClick={handleToggle}
      >
        {heading}
        <BiChevronRight
          className={`transform duration-200 ${
            isExpanded ? 'rotate-90' : 'rotate-0'
          }`}
          size={40}
        />
      </div>
      {isExpanded && <div className="p-3">{body}</div>}
    </div>
  );
}

export default Collapsible;
