// by David
import React from 'react'

export const Card = ({children, Style}) => {
  return (
<div class={`${Style}`}>
  <div class={`shadow-lg rounded p-4 flex flex-col justify-between leading-normal`}>
    {children}
  </div>
</div>
  )
}