import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/modal/Modal';
import Input from '../../../../components/Input';
import TextArea from '../../../../components/TextArea';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import { useForm } from 'react-hook-form';
import Drag from '../../../../components/Drag';
import axios from 'axios';
import { ToastComponent, ToasterComponent } from '../../../../components/Toast';
import { HiPlus } from 'react-icons/hi';
import { AiFillMinusCircle } from 'react-icons/ai';

const dimensions = [
  { value: 1, label: '2.6x3xm' },
  { value: 2, label: '2.7x3xm' },
  { value: 3, label: '2.8x3xm' },
  { value: 4, label: '2.9x3xm' },
];

const size = [
  { value: 1, label: 'Twitter' },
  { value: 2, label: 'Instagram' },
  { value: 3, label: 'Facebook' },
  { value: 4, label: 'linkedIn' },
  { value: 5, label: 'TikTok' },
  { value: 6, label: 'Youtube' },
];

const AccountDetails = ({ show, setShow, closeFirstModal }) => {
  const [billboardImage, setBillboardImage] = useState([]);
  const [details, setDetails] = useState({
    description: '',
    pricing: [
      {
        name: '',
        unit: '',
        ppu: '',
      },
    ],
  });

  const addDetailValues = () => {
    setDetails((prevData) => {
      return {
        ...prevData,
        pricing: [...prevData.pricing, { name: '', unit: '', ppu: '' }],
        // singleDaySchedule: [
        //   {
        //     ...prevData.singleDaySchedule[0],
        //     pricing: [
        //       ...prevData.singleDaySchedule[0].pricing,
        //       { name: '', unit: '', ppu: '' },
        //     ],
        //   },
        // ],
      };
    });
  };

  const handleSlotNameValue = (e, index) => {
    const { value } = e.target;
    setDetails((prevData) => {
      const editSlotName = [...prevData.pricing];
      editSlotName[index].name = value;
      return {
        ...prevData,
        pricing: editSlotName,
      };
    });
  };

  const handleUnitValue = (e, index) => {
    const { value } = e.target;
    setDetails((prevData) => {
      const editSlotUnit = [...prevData.pricing];
      editSlotUnit[index].unit = value;
      return {
        ...prevData,
        pricing: editSlotUnit,
      };
    });
  };

  const handlePpuValue = (e, index) => {
    const { value } = e.target;
    setDetails((prevData) => {
      const editSlotPpu = [...prevData.pricing];
      editSlotPpu[index].ppu = value;
      return {
        ...prevData,
        pricing: editSlotPpu,
      };
    });
  };

  const removeDetailValues = (index) => {
    setDetails((prevData) => {
      const newPricing = [...prevData.pricing];
      newPricing.splice(index, 1);

      return {
        ...prevData,
        pricing: newPricing,
        // singleDaySchedule: [
        //   {
        //     ...prevData.singleDaySchedule[0],
        //     pricing: newPricing,
        //   },
        // ],
      };
    });
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const onSubmit = () => {
    axios
      .post('https://jsonplaceholder.typicode.com/posts', details)
      .then((res) => {
        console.log(res);
        notify('success', 'Billboard added successfully');
        reset();
      })
      .catch((err) => {
        console.log(err);
        notify('error', 'Failed to add billboard');
      });
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow()}
      Style={
        'm-auto max-h-[90%] w-[95%] lg:w-[45%] max-w-[50%] overflow-y-auto rounded-lg font-nunito z-20'
      }
    >
      <form
        action=""
        method="post"
        className="w-full px-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Header>
          <h1 className="font-bold text-xl">Account details</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col items-center w-full">
            <div className="w-full">
              <TextArea
                labelText="Describe your account"
                labelFor={'description'}
                name={'description'}
                type={'text'}
                isRequired={false}
                placeholder={'Description'}
                customClass="w-full mb-4"
                inputStyle="h-[90px]"
                register={register}
                // onChange={(e) => handleBio(e)}
                // value={profile.bio}
              />{' '}
              <h1 className="">Pricing</h1>
              <div className="flex flex-row relative mb-4">
                <div className="pt-2 pb-4 px-4 border border-black/20 rounded-md bg-secondary w-full">
                  {details.pricing.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex flex-row bg-white p-2  rounded-md ${
                          index === 0 ? 'mb-2' : ''
                        }`}
                      >
                        <div className="flex flex-col lg:flex-row lg:gap-4 w-full">
                          <Input
                            labelText="Slot name"
                            labelFor={`slotName[${index}]`}
                            name={`slotName[${index}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'e.g: Brand mention'}
                            customClass="text-sm w-full mb-2"
                            onChange={(e) => {
                              handleSlotNameValue(e, index);
                            }}
                            register={register}
                            value={item.name || ''}
                          />{' '}
                          <Input
                            labelText={'Unit'}
                            labelFor={`unit[${index}]`}
                            name={`unit[${index}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'eg: post'}
                            customClass="text-sm w-full mb-2"
                            onChange={(e) => {
                              handleUnitValue(e, index);
                            }}
                            register={register}
                            value={item.unit || ''}
                          />{' '}
                          <Input
                            labelText={'Price/unit'}
                            labelFor={`ppu[${index}]`}
                            name={`ppu[${index}]`}
                            type={'text'}
                            isRequired={false}
                            placeholder={'eg: 10,000 Rwf'}
                            customClass="text-sm w-full mb-2"
                            onChange={(e) => {
                              handlePpuValue(e, index);
                            }}
                            register={register}
                            value={item.ppu || ''}
                          />{' '}
                        </div>
                        <AiFillMinusCircle
                          color="#BDBDBD"
                          size={25}
                          className={`cursor-pointer ${
                            index === 0 ? 'hidden' : 'visible'
                          }`}
                          onClick={() => removeDetailValues(index)}
                        />
                      </div>
                    );
                  })}
                </div>

                <Button
                  type="button"
                  content={<HiPlus color="#FBFBFB" size={20} />}
                  loading={false}
                  onClick={addDetailValues}
                  btnColor="primary"
                  Style={
                    'absolute right-[0px] py-[3px] bottom-[0px] text-white w-fit px-[4px] m-2'
                  }
                />
              </div>
              <Drag
                label={'Upload one or more sample content :'}
                files={billboardImage}
                setFiles={setBillboardImage}
              />
            </div>

            {/* <div className="flex flex-col w-full">
              <div></div>
            </div> */}

            <div className="flex flex-row w-full justify-end mt-8">
              <div className="flex flex-row gap-4">
                <Button
                  content="Close"
                  loading={false}
                  btnColor="white"
                  Style="text-black w-max h-[40px]"
                  type="button"
                  onClick={() => {
                    closeFirstModal();
                    setShow();
                  }}
                />
                <Button
                  content="Save"
                  loading={false}
                  btnColor="primary"
                  Style="text-white w-max h-[40px]"
                  type="submit"
                  onClick={() => {
                    closeFirstModal();
                    setShow();
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <ToasterComponent />
      </form>
    </Modal>
  );
};

export default AccountDetails;
