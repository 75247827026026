import React from "react";
import Select from "../../../components/Select";
import { CCard, CCardBody, CCol, CCardHeader } from "@coreui/react";
import {
  CChartBar,
  CChartDoughnut,
  CChartLine,
} from "@coreui/react-chartjs";

const Graphical = () => {
  const options = [
    { value: "option1", label: "Konvey Direct" },
    { value: "option2", label: "MTN-Rwanda" },
    { value: "option3", label: "Airtel-Rwanda" },
  ];

  return (
    <div className="flex flex-col pt-8 w-full h-full space-y-8 font-nunito">
      <div className="w-full flex flex-row justify-center">
        <div className="w-full md:1/2 flex flex-row items-center justify-center md:justify-end space-x-2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-[187px] md:w-[387px] h-[40px] pl-10 text-sm text-gray-900 border border-[#888888] rounded-lg bg-gray-50 focus:outline-none  "
              placeholder="Search......"
              required
            />
          </div>
          <div className="mt-0">
            <Select
              className="w-full mb-1"
              isLoading={false}
              placeholder={"Filter"}
              defaultValue={options}
              noRegister={{
                onChange: (e) => {
                  console.log("just changed");
                },
              }}
              options={options}
            />
          </div>
        </div>
      </div>

      {/* cards */}

      <div className="flex flex-col md:flex-row  md:space-x-4 space-y-4 w-full mt-8 text-[18px] font-bold justify-center md:justify-end">
        <div className=" md:w-[362px] w-full flex flex-row bg-white shadow-lg rounded-lg mt-4">
          <div className="w-1/3 text-black items-center justify-center flex flex-col py-8 px-4">
            <p className="text-20px font-bold">134</p>
          </div>
          <div className="bg-primary text-white flex flex-row items-center justify-center w-3/4 rounded-r-lg">
            <p>Total compaigns</p>
          </div>
        </div>
        <div className="md:w-[362px] w-full flex flex-row bg-white shadow-lg rounded-lg">
          <div className="w-1/3 text-black items-center justify-center flex flex-col py-8 px-4 ">
            <p className="text-20px font-bold">6000$</p>
          </div>
          <div className="bg-primary text-white flex flex-row items-center justify-center w-3/4 py-8 px-4 rounded-r-lg">
            <p>Total Revenues</p>
          </div>
        </div>
        <div className="md:w-[362px] w-full flex flex-row bg-white shadow-lg rounded-lg">
          <div className="w-1/3 text-black items-center justify-center flex flex-col py-8 px-4 ">
            <p className="text-20px font-bold">500</p>
          </div>
          <div className="bg-primary text-white flex flex-row items-center justify-center w-3/4 py-8 px-4 rounded-r-lg">
            <p>Total Clients</p>
          </div>
        </div>
      </div>
      {/* end  cards */}

      {/* charts */}
      <div className="flex flex-col md:space-y-16 space-y-8 h-full">
        <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4  w-full mt-8 text-[18px] font-bold  h-fit ">
          <div className="w-full md:w-1/2 h-max">
            <CCol className="bg-white px-4 rounded-lg shadow-lg w-full">
              <CCard>
                <CCardHeader>Customers, Campaigns and revenues </CCardHeader>
                <CCardBody>
                  <CChartLine
                    data={{
                      labels: [
                        "Mon",
                        "Tue",
                        "Wed",
                        "Thus",
                        "Fri",
                        "Sat",
                        "Sun",
                      ],                  
                      datasets: [
                        {
                          label: "Compaigns",
                          backgroundColor: "#1C1C1C",
                          data: [40, 20, 35, 70, 75, 100, 90],
                          tension: 0.5,
                          fill: false,
                        },
                        {
                          label: "Spaces",
                          backgroundColor: "#C5CDD2",
                          borderColor: "rgb(0,0,0)",
                          data: [34, 24, 60, 74, 14, 104, 84],
                          tension: 0.5,
                        },
                        {
                          label: "Money spent",
                          backgroundColor: "#C5CDD2",
                          borderColor: "#BAEDBD",
                          data: [154, 240, 600, 740, 140, 104, 840],
                          tension: 0.5,
                        },
                      ],
                    }}
                    options={{
                      tooltips: {
                        enabled: true
                      },
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </div>

          <div className="w-full md:w-1/2 h-max">
            <CCol className="px-4 rounded-lg shadow-lg w-full ">
              <CCard>
                <CCardHeader>Campaigns per  Campaign type</CCardHeader>
                <CCardBody>
                  <CChartBar
                    data={{
                      labels: [
                        "awareness",
                        "conversion",
                        "SMS broadcast",
                        "app installation",
                      ],
                      datasets: [
                        {
                          label: "number of compaigns",
                          backgroundColor: "#777777",
                          data: [40, 20, 35, 70],
                        },
                      ],
                    }}
                    options={{
                      tooltips: {
                        enabled: true
                      },
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </div>

        <div>
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 w-full mt-8 text-[18px] font-bold">
            <div className="md:w-full w-full h-max flex flex-col md:flex-row md:space-x-4 sm:space-y-4">
              <div className="md:w-1/2">
                <CCol className="bg-white px-4 rounded-lg shadow-lg w-full">
                  <CCard>
                    <CCardHeader>Campaigns Status</CCardHeader>
                    <CCardBody>
                      <CChartDoughnut
                        data={{
                          labels: ["approved", "completed", "ongoing"],
                          datasets: [
                            {
                              label: "Campaigns Status",
                              data: [300, 50, 100],
                              backgroundColor: [
                                "#BAEDBD",
                                "#C6C7F8",
                                "#1C1C1C",
                              ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                        options={{
                          tooltips: {
                            enabled: true
                          },
                          plugins: {
                            legend: {
                              position: 'bottom',
                            },
                          },
                        }}
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
              </div>

              <div className="md:w-1/2">
                <CCol className="bg-white px-4 rounded-lg shadow-lg w-full">
                  <CCard>
                    <CCardHeader>Campaigns per Space type</CCardHeader>
                    <CCardBody>
                      <CChartDoughnut
                        data={{
                          labels: ["approved", "completed", "ongoing"],
                          datasets: [
                            {
                              label: "Campaigns Status",
                              data: [300, 50, 100],
                              backgroundColor: [
                                "#BAEDBD",
                                "#C6C7F8",
                                "#1C1C1C",
                              ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                        options={{
                          tooltips: {
                            enabled: true
                          },
                          plugins: {
                            legend: {
                              position: 'bottom',
                            },
                          },
                        }}
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
              </div>
            </div>

            <div className="lg:w-full w-full h-max">
              <CCol className="bg-white px-4 rounded-lg shadow-lg w-full">
                <CCard>
                  <CCardHeader>Revenue per campaign type</CCardHeader>
                  <CCardBody>
                    <CChartBar
                      data={{
                        labels: [
                          "awareness",
                          "conversion",
                          "SMS broadcast",
                          "app installation",
                        ],
                        datasets: [
                          {
                            label: "spent per campaign type",
                            backgroundColor: "#777777",
                            data: [400000, 200000, 350000, 700000],
                          },
                        ],
                      }}
                      options={{
                        tooltips: {
                          enabled: true
                        },
                        plugins: {
                          legend: {
                            position: 'bottom',
                          },
                        },
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </div>
          </div>

          
        </div>

          <div className="flex md:flex-row md:space-x-4 flex-col space-y-4 w-full mt-8 text-[18px] font-bold ">
          <div className="md:w-1/2 w-full h-max">
              <CCol className="bg-white px-4 rounded-lg shadow-lg w-full ">
                <CCard>
                  <CCardHeader>Campaigns per industry type</CCardHeader>
                  <CCardBody>
                    <CChartBar
                      data={{
                        labels: [
                          "Mon",
                          "Tue",
                          "Wed",
                          "Thus",
                          "Fri",
                          "Sat",
                          "Sun",
                        ],
                        datasets: [
                          {
                            label: "spent per campaign type",
                            backgroundColor: "#777777",
                            data: [400000, 200000, 350000, 700000,800000, 30000, 70000],
                          },
                        ],
                      }}
                      options={{
                        tooltips: {
                          enabled: true
                        },
                        plugins: {
                          legend: {
                            position: 'bottom',
                          },
                        },
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </div>

            <div className="md:w-1/2 w-full h-max ">
              <CCol className="bg-white px-4 rounded-lg shadow-lg w-full ">
                <CCard>
                  <CCardHeader>Revenue per industry type</CCardHeader>
                  <CCardBody>
                    <CChartBar
                      data={{
                        labels: [
                          "Mon",
                          "Tue",
                          "Wed",
                          "Thus",
                          "Fri",
                          "Sat",
                          "Sun",
                        ],
                        datasets: [
                          {
                            label: "spent per campaign type",
                            backgroundColor: "#777777",
                            data: [4, 20, 3, 7,5,7,10],
                          },
                        ],
                      }}
                      options={{
                        tooltips: {
                          enabled: true
                        },
                        plugins: {
                          legend: {
                            position: 'bottom',
                          },
                        },
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </div>       
        </div>    

        <div className="flex md:flex-row flex-col space-x-4 w-full  text-[18px] font-bold ">
          <div className="md:w-1/2 w-full h-max mb-8">
              <CCol className="bg-white px-4 rounded-lg shadow-lg w-full ">
                <CCard>
                  <CCardHeader>Campaigns per timeframe</CCardHeader>
                  <CCardBody>
                    <CChartBar
                      data={{
                        labels: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
                        datasets: [
                          {
                            label: "spent per campaign type",
                            backgroundColor: "#777777",
                            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                          },
                        ],
                      }}
                      options={{
                        tooltips: {
                          enabled: true
                        },
                        plugins: {
                          legend: {
                            position: 'bottom',
                          },
                        },
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </div>      
        </div>        
      </div>
    </div>
  );
};

export default Graphical;
