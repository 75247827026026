import React from 'react'
import { Tab } from '@headlessui/react'
import Billing from './billings/Billing';
import Campaigns from './campaigns/Campaigns';
import Space from './spaces/Space';


const Dashboard = ({ setActivePage }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  React.useEffect(() => {
    setActivePage('Dashboard');
  }, []);
  return (
    <div className=" bg-secondary p-8 font-nunito h-screen overflow-y-scroll font-bold">
      <Tab.Group>
      <Tab.List className="flex space-x-4 rounded-xl bg-black text white w-ful lg:w-1/2 ">
        <Tab className={({ selected }) =>
                classNames(
                  'w-full lg:w-1/2 rounded-lg py-2.5 px-2 text-xl font-bold leading-5 text-primary',
                  'ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white'
                )
              }>Compaigns</Tab>
        <Tab className={({ selected }) =>
                classNames(
                  'w-full lg:w-1/2 rounded-lg py-2.5 text-[20px] font-bold leading-5 text-primary',
                  'ring-white ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white'
                )
              }>Billings</Tab>
              <Tab className={({ selected }) =>
                classNames(
                  'w-full lg:w-1/2 rounded-lg py-2.5 px-2 text-[20px] font-bold leading-5 text-primary',
                  'ring-white ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white'
                )
              }>Spaces</Tab>
      </Tab.List>
      <Tab.Panels className="mt-4 h-full">
        <Tab.Panel className="h-full">
          <Campaigns />
        </Tab.Panel>
        <Tab.Panel>
          <Billing />
        </Tab.Panel>
        <Tab.Panel>
          <Space />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
    </div>
  );

}

export default Dashboard
