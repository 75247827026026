import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/modal/Modal';
import Drag from '../../../../components/Drag';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import { AiFillMinusCircle } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';
import Input from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import EveryDay from './schedule/EveryDay';
import DifDays from './schedule/DifDays';


// days of the week



const options = [
  { value: 'news', label: 'News' },
  { value: 'sports', label: 'Sports' },
  { value: 'other', label: 'Others' },
];

const AddShowModal = ({ isOpen, onClickHandle }) => {
  {
    /*useState hook*/
  }
  const [showOthersInput, setShowOthersInput] = useState(false);
  const [radioValue, setRadioValue] = useState('everyday');
  const [logoFile, setLogoFile] = useState([]);
  const [sampleFile, setSampleFile] = useState([]);

  const handleRadioChange = (e) => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        scheduleType: e.target.value,
      };
    });
  };

  const [createShow, setCreateShow] = useState({
    title: '',
    type: '',
    presenters: [{ name: '' }],
    scheduleType: 'everyday',
    everyDaySchedule: {
      day:'everyDay',
      from: '',
      to: '',
    },
    difDaySchedule: [
      {
        day: '',
        start: '',
        end: '',
      },
    ],
    pricing: [
      {
        slot: '',
        unit: '',
        ppu: '',
      },
    ],
    banner: logoFile,
    sample: sampleFile,
  });

  const handleShowType = (type) => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        type,
      };
    });
  };

  const addShowPresenter = () => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        presenters: [...prevData.presenters, { name: '' }],
      };
    });
  };

  const handleShowPresenter = (e, index) => {
    const { value } = e.target;
    console.log(value,'value')
    setCreateShow((prevData) => {
      const editPresenter = [...prevData.presenters];
      editPresenter[index].name = value;
      return {
        ...prevData,
        presenters: editPresenter,
      };
    });
  };

  const handleRemovePresenter = (index) => {
    setCreateShow((prevData) => {
      const updatedPresenters = [...prevData.presenters];
      updatedPresenters.splice(index, 1);
      return {
        ...prevData,
        presenters: updatedPresenters,
      };
    });
  };

  const handleDailyFromSchedule = (from) => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        everyDaySchedule: {
          ...prevData.everyDaySchedule,
          from,
        },
      };
    });
  };

  const handleDailyToSchedule = (to) => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        everyDaySchedule: {
          ...prevData.everyDaySchedule,
          to,
        },
      };
    });
  };
    
  const addPricing = () => {
    setCreateShow((prevData) => {
      return {
       ...prevData,
        pricing: [...prevData.pricing, { slot: '', unit: '', ppu: '' }],
      };
    });
  }



  const handleSlotName = (e, index) => {
    const { value } = e.target;
    setCreateShow((prevData) => {
      const editSlotName = [...prevData.pricing];
      editSlotName[index].slot = value;
      return {
        ...prevData,
        pricing: editSlotName,
      };
    });
  };

  const handleSlotUnit = (e, index) => {
    const { value } = e.target;
    setCreateShow((prevData) => {
      const editSlotUnit = [...prevData.pricing];
      editSlotUnit[index].unit = value;
      return {
        ...prevData,
        pricing: editSlotUnit,
      };
    });
  };

  const handleSlotPpu = (e, index) => {
    const { value } = e.target;
    setCreateShow((prevData) => {
      const editSlotPpu = [...prevData.pricing];
      editSlotPpu[index].ppu = value;
      return {
        ...prevData,
        pricing: editSlotPpu,
      };
    });
  };

  const handleRemovePricing = (index) => {
    setCreateShow((prevData) => {
      const updatedPricing = [...prevData.pricing];
      updatedPricing.splice(index, 1);
      return {
        ...prevData,
        pricing: updatedPricing,
      };
    });
  };

 

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post('https://jsonplaceholder.typicode.com/posts', {
        title: '',
        type: '',
        presenters: [{name:''}],
        scheduleType: '',
        schedule: [
          {
            everyDay: {
              from: '',
              to: '',
            },
          },
        ],
        pricing: [
          {
            slot: 'DJ Mention',
            unit: 'seconds',
            ppu: 100,
          },
        ],
        banner: null,
        sample: null,
      })
      .then((res) => {
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setCreateShow(prevState => ({
      ...prevState,
      sample: sampleFile,
      banner: logoFile,
    }));
  }, [sampleFile, logoFile]);
  return (
    <div>
      {/* start of show modal */}
      <Modal
        isOpen={isOpen}
        onClose={onClickHandle}
        Style={
          'scroll-modal  mt-8 flex mx-auto h-[90%] md:h-full md:max-h-[80%] w-[90%] md:w-max md:max-w-[80%] overflow-y-scroll rounded-lg'
        }
      >
        <Modal.Header>Create show</Modal.Header>
        <form
          action=""
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="mx-4"
        >
          <Modal.Body>
            <div className="flex flex-col space-y-4">
              <div>
                <Input
                  labelText={'Show Title'}
                  labelFor={'title'}
                  name={'title'}
                  type={'text'}
                  isRequired={true}
                  placeholder={'enter show title'}
                  customClass="w-full mb-2"
                  onChange={(e) => {
                    setCreateShow((prevData) => {
                      return {
                        ...prevData,
                        title: e.target.value,
                      };
                    });
                  }}
                  register={register}
                  value={createShow.title || ''}
                  errors={errors}
                />
                <Select
                  className="w-full "
                  label={'Show type'}
                  isLoading={false}
                  placeholder={'select show type'}
                  options={options}
                  register={{
                    control,
                    name: 'showType',
                    rules: { required: 'Show type is required' },
                  }}
                  error={errors['showType']?.message}
                  onChange={(e) => handleShowType(e)}
                  value={createShow.type}
                />
                <Input
                  labelText={'Add show type'}
                  labelFor={'type'}
                  name={'type'}
                  type={'text'}
                  isRequired={true}
                  placeholder={'enter show type'}
                  customClass={`w-full ${
                    showOthersInput ? 'visible' : 'hidden'
                  }`}
                  onChange={(e) => {
                    setCreateShow((prevData) => {
                      return {
                        ...prevData,
                        type: e.target.value,
                      };
                    });
                  }}
                  register={register}
                  errors={errors}
                />{' '}
                {createShow.presenters.map((item, index) => {
                  return (
                    <div className="flex flex-row items-center space-x-2 ">
                      <Input
                        labelText={`Presenter ${index + 1}`}
                        labelFor={`presenter ${index + 1}`}
                        name={`presenter ${index + 1}`}
                        type={'text'}
                        isRequired={true}
                        placeholder={'enter show presenter'}
                        customClass="w-full my-2"
                        register={register}
                        errors={errors}
                        onChange={(e) => {
                          console.log(e.target.value,'e.target.value')
                          handleShowPresenter(e, index)
                        }}
                        value={item.name || ''}
                      />{' '}
                      <AiFillMinusCircle
                        color="#BDBDBD"
                        size={25}
                        className={`cursor-pointer ${
                          index === 0 ? 'hidden' : 'visible'
                        }`}
                        onClick={() => handleRemovePresenter(index)}
                      />
                      <Button
                        type="button"
                        content={<HiPlus color="#FBFBFB" size={20} />}
                        loading={false}
                        onClick={addShowPresenter}
                        btnColor="primary"
                        Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2 ${
                          index !== createShow.presenters.length - 1
                            ? 'hidden'
                            : 'visible'
                        }`}
                      />
                    </div>
                  );
                })}
                <div className="flex flex-col space-y-4 w-full">
                  <h2>Schedule</h2>
                  <div className="flex flex-row justify-between">
                    <div class="flex items-center ">
                      <input
                        id="default-radio-1"
                        type="radio"
                        value="everyday"
                        name="default-radio"
                        className="w-4 h-4"
                        onClick={handleRadioChange}
                      />
                      <label for="default-radio-1" class="ml-2">
                        every day
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="default-radio-2"
                        type="radio"
                        value="selectdays"
                        name="default-radio"
                        className="w-4 h-4"
                        onClick={handleRadioChange}
                      />
                      <label for="default-radio-2" class="ml-2">
                        select days
                      </label>
                    </div>
                  </div>
                  {createShow.scheduleType === 'everyday' ? (
                   <EveryDay createShow={createShow} setCreateShow={setCreateShow}/>
                  ) : (
                   <DifDays createShow={createShow} setCreateShow={setCreateShow}/>
                  )}
                </div>
                <div className="border rounded-sm mt-4 border-primary border-opacity-50">
                  {createShow.pricing.map((item, index) => {
                    return (
                      <div className="flex flex-col md:flex-row items-center space-x-2 p-4">
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          <Input
                            labelText={'slot name'}
                            labelFor={`slot name ${index + 1}`}
                            name={`slot name ${index + 1}`}
                            type={'text'}
                            isRequired={true}
                            placeholder={'slot name'}
                            customClass="w-full"
                            register={register}
                            errors={errors}
                            onChange={(e) => handleSlotName(e, index)}
                            value={item.slot }
                          />{' '}
                          <Input
                            labelText={'unit'}
                            labelFor={`unit ${index + 1}`}
                            name={`unit ${index + 1}`}
                            type={'text'}
                            isRequired={true}
                            placeholder={'seconds'}
                            customClass="w-full"
                            register={register}
                            errors={errors}
                            onChange={(e) => handleSlotUnit(e, index)}
                            value={item.unit}
                          />{' '}
                          <Input
                            labelText={'price/ unit'}
                            labelFor={`price per unit ${index + 1}`}
                            name={`price per unit ${index + 1}`}
                            type={'text'}
                            isRequired={true}
                            placeholder={'100'}
                            customClass="w-full"
                            register={register}
                            errors={errors}
                            onChange={(e) => handleSlotPpu(e, index)}
                            value={item.ppu}
                          />{' '}
                        </div>
                        <div className='w-[90%] flex items-end justify-end'>
                        <AiFillMinusCircle
                          color="#BDBDBD"
                          size={25}
                          className={`cursor-pointer ${
                            index === 0 ? 'hidden' : 'visible'
                          }`}
                          onClick={() => handleRemovePricing(index)}
                        />
                        <Button
                          type="button"
                          content={<HiPlus color="#FBFBFB" size={20} />}
                          loading={false}
                          onClick={addPricing}
                          btnColor="primary"
                          Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2 ${
                            index !== createShow.pricing.length - 1 ? 'hidden' : 'visible'
                          }`}
                        />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col space-y-12">
                <div>
                  <h2 className="font-bold">Upload show banner:</h2>
                  <Drag
                    uploadLimit={0}
                    label={'This is the logo of the show'}
                    files={logoFile}
                    setFiles={setLogoFile}
                  />
                </div>
                <div>
                  <h2 className="font-bold">Upload show sample:</h2>
                  <Drag
                    uploadLimit={0}
                    label={'This is the sample of the show'}
                    files={sampleFile}
                    setFiles={setSampleFile}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.DismissButton>
              <Button
                type="button"
                content="Close"
                loading={false}
                onClick={onClickHandle}
                btnColor="white"
                Style={
                  'right-[0px] py-[1px] bottom-[0px] text-primary w-fit px-8 m-2'
                }
              />
            </Modal.DismissButton>
            <Button
              type="submit"
              content="Save"
              loading={false}
              btnColor="primary"
              Style={
                'right-[0px] py-[1px] bottom-[0px] text-white w-fit px-8 m-2'
              }
            />
          </Modal.Footer>
        </form>
      </Modal>
      {/*end of show modal */}
    </div>
  );
};

export default AddShowModal;
