import React, { useState, useEffect } from 'react';
import Input from '../../components/Input';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import { HiPlus } from 'react-icons/hi';
import { AiFillMinusCircle } from 'react-icons/ai';

export const Step3 = ({ profile, setProfile }) => {
  // configure the dynamic form for address
  const addAddress = () => {
    setProfile((prevData) => {
      return {
        ...prevData,
        address: [...prevData.address, { name: '' }],
      };
    });
  };

  const handleAddress = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newAddress = [...prevData.address];
      newAddress[index] = {
        name: value,
      };
      return {
        ...prevData,
        address: newAddress,
      };
    });
  };

  const handleRemoveAddress = (index) => {
    setProfile((prevData) => {
      const addressArray = [...prevData.address];
      addressArray.splice(index, 1);
      return {
        ...prevData,
        address: addressArray,
      };
    });
  };

  const addSocialMedia = () => {
    setProfile((prevData) => {
      return {
        ...prevData,
        socialMedia: [...prevData.socialMedia, { name: '', link: '' }],
      };
    });
  };

  const handleSmName = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newSocialMedia = [...prevData.socialMedia];
      newSocialMedia[index] = {
        ...newSocialMedia[index],
        name: value,
      };
      return {
        ...prevData,
        socialMedia: newSocialMedia,
      };
    });
  };

  const handleSmLink = (e, index) => {
    const { value } = e.target;
    setProfile((prevData) => {
      const newSocialMedia = [...prevData.socialMedia];
      newSocialMedia[index] = {
        ...newSocialMedia[index],
        link: value,
      };
      return {
        ...prevData,
        socialMedia: newSocialMedia,
      };
    });
  };

  const handleRemoveSocial = (index) => {
    setProfile((prevData) => {
      const smArray = [...prevData.socialMedia];
      smArray.splice(index, 1);
      return {
        ...prevData,
        socialMedia: smArray,
      };
    });
  };

  //end of configuration

  const {
    register,
    //handleSubmit,
    formState: { errors },
    //reset,
  } = useForm();

  return (
    <div className="bg-secondary">
      <div
        className={`flex-row bg-secondary m-0 w-full h-fit font-nunito`}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full mx-auto my-8 md:my-auto lg:my-4 xlg:my-4 xl:my-4">
            <h1 className="text-[18px] md:text-[20px] lg:text-[20px] xlg:text-[20px] xl:text-[20px] mb-4">
              Enter your address
            </h1>
            <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
              click add icon to add your address
            </h1>

            <div className="flex flex-col space-y-8">
              <div className="flex flex-row space-x-2 items-end ">
                <div className="relative rounded-lg w-full px-[33px] bg-white drop-shadow-lg ">
                  <div className="mb-12">
                    {profile.address.map((item, index) => {
                      return (
                        <div className="flex flex-row items-center space-x-2 ">
                          <Input
                            labelText={`Address line ${index + 1}`}
                            labelFor={`address${index}`}
                            name={`address${index}`}
                            type="text"
                            placeholder="Enter your address"
                            customClass="w-full my-2"
                            onChange={(e) => handleAddress(e, index)}
                            register={register}
                            value={item.name}
                          />
                          <AiFillMinusCircle
                            color="#BDBDBD"
                            size={25}
                            className={`cursor-pointer ${
                              index === 0 ? 'hidden' : 'visible'
                            }`}
                            onClick={() => handleRemoveAddress(index)}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <Button
                    type="button"
                    content={<HiPlus color="#FBFBFB" size={20} />}
                    loading={false}
                    onClick={addAddress}
                    btnColor="primary"
                    Style={
                      'absolute right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2'
                    }
                  />
                </div>
              </div>
              {/* social media */}
              <div className="flex flex-col">
                <h1 className="text-[18px] md:text-[20px] lg:text-[20px] xlg:text-[20px] xl:text-[20px]">
                  Social media link
                </h1>
                <h1 className="text-[12px] md:text-[12px] lg:text-[12px] xlg:text-[12px] xl:text-[12px]">
                  click add icon to add your social media
                </h1>
                <div className="flex flex-row space-x-2 items-end ">
                  <div className="relative rounded-lg w-full px-[33px] bg-white drop-shadow-lg">
                    <div className="mb-12">
                      {profile.socialMedia.map((item, index) => {
                        // Indidual social media name = IndSmName
                        // Indidual social media link = IndSmLink
                        return (
                          <div className="flex flex-row items-center space-x-2">
                            <Input
                              labelText={'Name'}
                              labelFor={'indSmName'}
                              name={`indSmName${index}`}
                              type={'text'}
                              placeholder={'add name'}
                              customClass="w-full my-2"
                              register={register}
                              onChange={(e) => handleSmName(e, index)}
                              value={item.name}
                            />{' '}
                            <Input
                              labelText={'Link'}
                              labelFor={'indSmLink'}
                              name={`indSmLink${index}`}
                              type={'text'}
                              placeholder={'add link'}
                              customClass="w-full my-2"
                              register={register}
                              errors={errors}
                              onChange={(e) => handleSmLink(e, index)}
                              value={item.link}
                            />{' '}
                            <AiFillMinusCircle
                              color="#BDBDBD"
                              size={50}
                              className={`cursor-pointer ${
                                index === 0 ? 'hidden' : 'visible'
                              }`}
                              onClick={() => handleRemoveSocial(index)}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <Button
                      type="button"
                      content={<HiPlus color="#FBFBFB" size={20} />}
                      loading={false}
                      onClick={addSocialMedia}
                      btnColor="primary"
                      Style={
                        'absolute right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
