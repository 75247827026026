import React, { useState } from "react";
import { filterData } from "./Radio";
import Modal from "../../../components/modal/Modal";
import { BiSearch } from "react-icons/bi";
import Button from "../../../components/Button";
import AddBillboard from "./billboard_modals/AddBillboard";

const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + '.....' : str;
};


export const billboardData = [
  {
    image:
      "https://www.shutterstock.com/image-photo/outdoor-billboard-on-blue-sky-260nw-1921386038.jpg",
    "size type": "Big",
    Dimensions: "20m x 30m ",
    price: "300k per Week",
    available: true,
    description: "lorem ipsum ipsum",
    id: 1,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUPCtKR4CjFysoH-Q3UHA3Ve07BDZ0ByzBDL4mfe_H&s",
    "size type": "Small",
    Dimensions: "20m x 30m ",
    price: "300k per Week",
    available: false,
    description: "lorem ipsum ipsum",
    id: 2,
  },
  {
    image:
      "https://cms-assets.tutsplus.com/cdn-cgi/image/width=850/uploads/users/2659/posts/41209/final_image/creative_billboard_design_main.jpeg",
    "size type": "Big",
    Dimensions: "40m x 50m ",
    price: "300k per Week",
    available: false,
    description: "lorem ipsum ipsum",
    id: 3,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB1uKKe2OqZzBVeQgdg5MQ75b5aA-Mzfqm_D1BYBxzohGAr1Ddx2oIDuzgbQ9BrA7F-5w&usqp=CAU",
    "size type": "Small",
    Dimensions: "20m x 30m ",
    price: "600k per Week",
    available: false,
    description: "lorem ipsum ipsum",
    id: 4,
  },

  {
    image:
      "https://img.freepik.com/free-vector/billboard-against-sky-background-day-image_1284-8018.jpg?w=2000",
    "size type": "Big",
    Dimensions: "20m x 30m ",
    price: "700k per Week",
    available: false,
    description: "lorem ipsum ipsum",
    id: 1,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf7wyl-DWr2FPDYlJ1pAunJCKJGOF9L8FHzK3RZLoGOA&s",
    "size type": "Small",
    Dimensions: "20m x 30m ",
    price: "700k per Week",
    available: false,
    description: "lorem ipsum ipsum",
    id: 2,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqL-e7fPH3uaXguOjgICAHczJgfBi7LTkBZBpmy37OqH9WIR2bXj0hKE6q9OPKGWkpM4E&usqp=CAU",
    "size type": "Big",
    Dimensions: "40m x 50m ",
    price: "600k per Week",
    available: false,
    description: "lorem ipsum ipsum",
    id: 3,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT9KkRHWUP_3lCTPSBu7E_g-4ZN_BKf-5fb9BK8pJe&s",
    "size type": "Small",
    Dimensions: "20m x 30m ",
    price: "300k per Week",
    available: true,
    description: "lorem ipsum ipsum",
    id: 1,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT9KkRHWUP_3lCTPSBu7E_g-4ZN_BKf-5fb9BK8pJe&s",
    "size type": "Small",
    Dimensions: "20m x 30m ",
    price: "300k per Week",
    available: true,
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi earum temporibus magnam nihil dignissimos atque eveniet pariatur consequuntur, itaque quae quas libero debitis ipsam dolores aliquam quibusdam cupiditate qui facere",
    id: 1,
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT9KkRHWUP_3lCTPSBu7E_g-4ZN_BKf-5fb9BK8pJe&s",
    "size type": "Small",
    Dimensions: "20m x 30m ",
    price: "300k per Week",
    available: true,
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi earum temporibus magnam nihil dignissimos atque eveniet pariatur consequuntur, itaque quae quas libero debitis ipsam dolores aliquam quibusdam cupiditate qui facere",
    id: 1,
  },
];

// list of billboards location
const billboardLocations = [
  { id: 1, name: "Kicukiro" },
  { id: 2, name: "Kimihurura" },
  { id: 3, name: "Kanombe" },
  { id: 4, name: "Kimisagara" },
];

const Billboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({
    image: "",
    "size type": "",
    Dimensions: "",
    price: "",
    available: "",
    description: "",
  });

  const [tableData, setTableData] = useState(1);
  const myData = filterData(billboardData, tableData);

  return (
    <div className="mt-[43px] flex flex-col lg:flex-row font-nunito">
      {/* left panel */}

      <div className="w-full lg:min-w-[239px] lg:max-w-[239px] flex flex-col mx-auto mb-8 lg:mb-0 ">
        <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
          <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
            <p className="italic text-[#414141] mt-[21px]">All Billboards</p>
            <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
              <BiSearch size={20} />
              <input
                defaultValue=""
                placeholder="Search"
                className="w-full rounded outline-none font-nunito"
              />
            </div>
            <hr className="mt-2" />
          </div>
          <div className="pt-2 mx-[20px] overflow-y-auto">
            <div className="  text-base ">
            {billboardLocations.map((element, i) => {
                return (
                  <div
                    className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                    onClick={() => setTableData(element.id)}
                  >
                    <h1>{element.name}</h1>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Button
          content="+ Add billboard"
          loading={false}
          onClick={() => setOpenModal(!openModal)}
          btnColor="primary"
          Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
        />
      </div>

      {/* end ofleft panel */}

      {/* start of right panel */}
      <div className="bg-white rounded-lg lg:ml-[9px] w-full justify-center h-max  md:pt-[12px] flex flex-row flex-wrap shadow-lg">
        {myData.map((element) => (
          <div className="w-full md:max-w-sm rounded overflow-hidden shadow-lg md:w-[229px] min-h-[100px] mr-2 mb-2"
          onClick={() => {
            setIsOpen(!isOpen);
            setData(element);
          }}
          >
            <img
              className="w-full h-[229px]"
              src={element.image}
            />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Billbord location</div>
              <p className="text-gray-700 text-base">{truncate(element.description, 30)}</p>
            </div>
            <div className="px-6 pb-2 mb-2">
              <Button
                content={element.available ? "Available" : "Not Available"}
                loading={false}
                btnColor={
                  element.available ? "primary" : "primary bg-opacity-30"
                }
                Style="text-white w-full h-[40px] mr-4 ml-0"
              />
            </div>
          </div>
        ))}
      </div>
      {/* end of right panel */}

      {/*modal of displaying details of the billboard*/}

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        Style={
          "scroll-modal mt-8 flex mx-auto w-[90%] h-max max-h-[80%] md:w-max md:max-w-[60%] overflow-y-scroll rounded-lg"
        }
      >
        <Modal.Header>Billboard details</Modal.Header>
        <Modal.Body>
        <div className="relative w-full h-full max-w-2xl">
            <div className="relative bg-white  shadow">
              <div className="p-6 space-y-4 flex flex-row justify-between">
                <div className="w-1/2 flex flex-col justify-start">
                  <img
                    src={data.image}
                    alt="billboard image"
                    className="w-[229px] h-[229px]"
                  />
                </div>
                <div className="w-1/2 mx-6">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    <p className="font-bold">Size Type</p>
                    <p className="italic">{data?.["size type"]}</p>
                    <p className="font-bold">Dimensions</p>
                    <p className="italic mb-2">{data?.Dimensions}</p>
                  </p>
                  <p className="font-bold">Price</p>
                  <p className="italic mb-2">{data?.price}</p>

                  <p className="text-base leading-relaxed w-inherit">
                    {data?.description}
                  </p>
                </div>
              </div>
              <div className="flex items-center p-6 space-x-2  rounded-b dark:border-gray-600">
                <p className="font-bold text-[18px]">status:</p>
                <p className="text-lime-900 font-bold text-[18px]">{data.available? "available" : "Not available"} </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>Close</Modal.DismissButton>
        </Modal.Footer>
      </Modal>

      {/*end of the modal*/}  

      {/*modal of adding a billboard*/}
      <AddBillboard openModal={openModal} setOpenModal={setOpenModal} setToggleCreateSpace={setOpenModal}/>
      {/*end of modal*/}
    </div>
  );
};

export default Billboard;
