import React from 'react';
import Input from '../../../../../components/Input';
import { useForm } from 'react-hook-form';

const EveryDay = ({ createShow, setCreateShow }) => {
  const handleDailyFromSchedule = (from) => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        everyDaySchedule: {
          ...prevData.everyDaySchedule,
          from,
        },
      };
    });
  };

  const handleDailyToSchedule = (to) => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        everyDaySchedule: {
          ...prevData.everyDaySchedule,
          to,
        },
      };
    });
  };
  const {
    register,
    formState: { errors },
    reset,
  } = useForm();
  return (
    <div className="flex flex-row justify-between">
      <div class="flex items-center ">
        <Input
          labelText={'From'}
          labelFor={'from'}
          name={'from'}
          type={'time'}
          isRequired={true}
          placeholder={'start time'}
          customClass="w-max"
          onChange={(e) => {
            handleDailyFromSchedule(e.target.value);
          }}
          register={register}
          errors={errors}
          value={createShow.everyDaySchedule.from}
        />{' '}
      </div>
      <div class="flex items-center">
        <Input
          key="01"
          labelText={'end time'}
          labelFor={'to'}
          name={'to'}
          type={'time'}
          isRequired={true}
          placeholder={'end time'}
          customClass="w-max"
          onChange={(e) => {
            handleDailyToSchedule(e.target.value);
          }}
          register={register}
          errors={errors}
          value={createShow.everyDaySchedule.to}
        />{' '}
      </div>
    </div>
  );
};

export default EveryDay;
