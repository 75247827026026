import React, { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import Footer from '../components/homepage/Footer';
import Header from '../components/homepage/Header';
import Background from '../Assets/Background.png';
import Person from '../Assets/person.jpg';
import { BsTwitter } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import Carausal from '../components/Carousal';
import { Services, Links, Clients } from '../seeds/Data';
import { Card } from '../components/Card';

import { MdOutlineMiscellaneousServices } from 'react-icons/md';
import { RiLinksLine } from 'react-icons/ri';
import Modal from '../components/modal/Modal';

const Home = ({ setActivePage }) => {
  const addresses = ['kayonza', 'kirehe', 'ruhanga'];
  useEffect(() => {
    setActivePage('Home');
  }, []);

  // opening and closing modal
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState([]);
  const [openServiceModal, setOpenServiceModal] = React.useState(false);
  const [serviceContent, setServiceContent] = React.useState([]);
  const handleServiceModal = (item) => {
    setServiceContent({
      ...serviceContent,
      service: item.service,
      description: item.description,
    });
    setOpenServiceModal(!openServiceModal);
  };
  const handleOpenModal = (item) => {
    setModalContent({
      ...modalContent,
      type: item.type,
      sample: item.sample,
      title: item.title,
      description: item.description,
    });
    setOpenModal(!openModal);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  return (
    <div className="flex flex-col bg-secondary h-screen pt-8 font-nunito content">
      {/* start of service modal */}
      <Modal
        isOpen={openServiceModal}
        onClose={() => setOpenServiceModal(false)}
        Style={'w-[80%] lg:w-1/2 m-2 flex m-auto max-h-[80%]'}
      >
        <Modal.Header>{serviceContent.service}</Modal.Header>
        <Modal.Body>
          <div className="w-full flex">
            <p>{serviceContent.description}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>Close</Modal.DismissButton>
        </Modal.Footer>
      </Modal>
      {/* end of service modal*/}

      <section className="flex flex-col space-y-8 w-full lg:w-3/4 h-fit mx-auto bg-white p-4 rounded-md">
        <section className="w-full h-1/8 mx-auto">
          <Header accountProfile="Konvey publisher profile" />
        </section>

        <div className="relative flex flex-col items-center w-full h-[180px]">
          <img
            src={Background}
            alt=""
            className="object-cover w-full h-[180px]"
          />
          <div className="absolute  bottom-[-50px] h-[130px] w-[130px] border-[5px] border-white rounded-full left-[100px] transform -translate-x-1/2">
            <img
              src={Person}
              alt=""
              className="h-full w-full rounded-full object-cover"
            />
          </div>
        </div>

        <div className="flex flex-col h-full items-start justify-start space-y-4 px-[5%] pt-8 ">
          <div>
            <h2 className="font-[700]">Publisher type: Individual </h2>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              pulvinar felis ac viverra ullamcorper. Quisque id hendrerit elit.
              Maecenas non est diam.
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <button
              type="button"
              className="cursor-pointer bg-black text-white p-2 rounded-[4px]"
            >
              Book a call
            </button>
            <button
              type="button"
              className="cursor-pointer bg-white text-primary underline font-[700]"
            >
              My works
            </button>
          </div>
          <div className="flex flex-row space-x-2">
            <p>Follow me on: </p>
            <div className="flex flex-row space-x-2">
              <BsTwitter size={20} className="cursor-pointer" />
              <BsFacebook size={20} className="cursor-pointer" />
              <AiFillInstagram size={20} className="cursor-pointer" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white flex flex-col lg:flex-row p-4 rounded-md w-full lg:w-3/4 h-fit mx-auto my-4 ">
        <div className="flex flex-row px-[5%] w-full lg:w-1/3">
          <hr className="min-w-[50px] max-w-[106px] bg-primary opacity-50 font-[700]" />
          <div className="font-bold">
            <h2 className="leading-0 font-[800]">
              Meet my <br />
              recent clients
            </h2>
          </div>
        </div>
        <div className="w-1/2 mx-auto">
          <Carausal
            slidesToShow={3}
            slidesToScroll={3}
            Style={'w-full mx-auto'}
          >
            {Clients.map((client, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col flex-wrap rounded-md w-100  "
                >
                  <div className="w-[90px] h-[45px]">
                    <img
                      src={client.logo}
                      alt=""
                      className="object-contain w-full h-full"
                    />
                  </div>
                  <Marquee pauseOnHover speed={10} gradientWidth={20}>
                    <div className="w-full">
                      <h1 className="font-bold text-sm">{client.name}</h1>
                    </div>
                  </Marquee>
                </div>
              );
            })}
          </Carausal>
        </div>
      </section>

      <section className="flex flex-col w-full h-max mx-auto py-2 space-y-4 ">
        <div className="flex flex-row items-center justify-center">
          <MdOutlineMiscellaneousServices size={30} />
          <h2 className="font-bold text-3xl">Services</h2>
        </div>
        <div className="w-3/4 h-full mx-auto">
          <Carausal
            slidesToShow={3}
            slidesToScroll={2}
            Style={'w-full mx-auto'}
          >
            {Services.map((item, i) => (
              <div
                key={i}
                className="item cursor-pointer"
                onClick={() => handleServiceModal(item)}
              >
                <Card
                  Style={
                    'bg-primary text-white px-2 py-2 flex flex-col w-full rounded-[5px] shadow-xl min-h-[200px]'
                  }
                >
                  <div className="font-bold text-2xl">
                    <h2>{item.service}</h2>
                  </div>
                  <div>
                    <p>{truncate(item.description, 100)}</p>
                  </div>
                </Card>
              </div>
            ))}
          </Carausal>
        </div>
      </section>

      {/* my useful links */}

      <section className="flex flex-col  w-full lg:w-3/4 h-max mx-auto py-6 items-center justify-center px-6">
        <div className="flex flex-row font-bold text-2xl justify-center w-full items-center space-x-2">
          <RiLinksLine />
          <h2>My links</h2>
        </div>
        {Links.map((link, i) => (
          <div
            key={i}
            className="w-full flex flex-row items-start justify-center my-2 bg-[#D9D9D9] rounded-2xl text-black font-[800] mx-auto hover:scale-[1.01] transition ease-in cursor-pointer h-[50px] pt-4"
          >
            <a href={link.link}>
              <p>{link.title}</p>
            </a>
          </div>
        ))}
      </section>
      <Footer telephone="0788888888" address={addresses} />
    </div>
  );
};

export default Home;
