import React from 'react';
import Profile from '../../../Assets/Avatar.png';

const Youtube = () => {
  return (
    <div className="flex-flex-col w-full h-full">
      <div className="h-[40px] w-[40px] mx-auto">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png"
          alt=""
          className="w-fit h-fit object-contain"
        />
      </div>
      <div className="h-fit w-fit mx-auto flex gap-12 pt-6">
        <div
          className="h-[150px] w-[150px]
          rounded-full "
        >
          <img
            src={Profile}
            alt=""
            className="h-full w-full rounded-full object-cover"
          />
        </div>
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">John Doe</h1>
          <h3 className="text-primary/70">
            @ <span>john_doe</span>
          </h3>
          <div className="flex gap-4">
            <h1 className="text-primary/70">
              <b className="text-primary">12K</b> Subscribers
            </h1>

            <h1 className="text-primary/70">
              <b className="text-primary">78</b> Videos
            </h1>
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex flex-row gap-4"></div>
        <div>
          <h1 className="text-xl font-bold pt-4 pb-2">Metrics</h1>
          <div className="flex flex-row flex-wrap gap-6">
            <h3 className="text-primary/70">
              Views <b className="text-primary">3.1M</b>
            </h3>

            <h3 className="text-primary/70">
              Total impressions <b className="text-primary">15.05K</b>
            </h3>
            <h3 className="text-primary/70">
              Watch time <b className="text-primary">16k hrs</b>
            </h3>
            <h3 className="text-primary/70">
              Conversion rate <b className="text-primary">7.14%</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youtube;
