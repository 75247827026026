import React, {useState, useRef, useEffect} from 'react'
import { filterData } from './Radio';
import { BiSearch } from "react-icons/bi";
import Button from '../../../components/Button';
import AddBillboard from './digital_billboard_modals/AddDigitalBillboard';
import Modal from '../../../components/modal/Modal';
import axios from 'axios'

const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + '.....' : str;
};

export const billboardData = [
    {
      video: "https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4",
      "size type": "Big",
      Dimensions: "20m x 30m ",
      price: "300k per Week",
      available: true,
      description: "lorem ipsum ipsum",
      id: 1,
    },
    {
      video:
        "https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4",
      "size type": "Big",
      Dimensions: "20m x 30m ",
      price: "300k per Week",
      available: true,
      description: "lorem ipsum ipsum",
      id: 1,
    },
    {
      video:
        "https://joy1.videvo.net/videvo_files/video/free/2019-03/large_watermarked/181004_10_LABORATORIES-SCIENCE_08_preview.mp4",
      "size type": "Big",
      Dimensions: "20m x 30m ",
      price: "300k per Week",
      available: true,
      description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi earum temporibus magnam nihil dignissimos atque eveniet pariatur consequuntur, itaque quae quas libero debitis ipsam dolores aliquam quibusdam cupiditate qui facere",
      id: 2,
    },
    {
      video: "https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4",
      "size type": "Big",
      Dimensions: "20m x 30m ",
      price: "300k per Week",
      available: true,
      description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi earum temporibus magnam nihil dignissimos atque eveniet pariatur consequuntur, itaque quae quas libero debitis ipsam dolores aliquam quibusdam cupiditate qui facere",
      id: 2,
    },
    {
      video:
        "https://joy1.videvo.net/videvo_files/video/free/video0462/large_watermarked/_import_60f11cf8e33129.96454172_preview.mp4",
      "size type": "Big",
      Dimensions: "20m x 30m ",
      price: "300k per Week",
      available: true,
      description: "lorem ipsum ipsum",
      id: 1,
    },
    {
      video:
        "https://joy1.videvo.net/videvo_files/video/free/video0454/large_watermarked/_import_6064a30001a6f6.90353328_preview.mp4",
      "size type": "Big",
      Dimensions: "20m x 30m ",
      price: "300k per Week",
      available: true,
      description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi earum temporibus magnam nihil dignissimos atque eveniet pariatur consequuntur, itaque quae quas libero debitis ipsam dolores aliquam quibusdam cupiditate qui facere",
      id: 1,
    },
  ];

  // list of billboards location
const billboardLocations = [
  { id: 1, name: "Kicukiro" },
  { id: 2, name: "Kimihurura" },
  { id: 3, name: "Kanombe" },
  { id: 4, name: "Kimisagara" },
];

  

  
const DigitalBillboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
      image: "",
      "size type": "",
      Dimensions: "",
      price: "",
      available: "",
      description: "",
    });
  
    const [tableData, setTableData] = useState(1);
    const myData = filterData(billboardData, tableData);
  
    const videoEl = useRef(null);
  
    const attemptPlay = () => {
      videoEl &&
        videoEl.current &&
        videoEl.current.play().catch((error) => {
          console.error("Error attempting to play", error);
        });
    };
  
    useEffect(() => {
      attemptPlay();
    }, []);

   
  
    return (
      <div className="mt-[43px] flex flex-col lg:flex-row font-nunito">
        {/* left panel */}
      <div className="w-full lg:min-w-[239px] lg:max-w-[239px] flex flex-col mx-auto mb-8 lg:mb-0 ">
        <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
          <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
            <p className="italic text-[#414141] mt-[21px]">All Billboards</p>
            <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
              <BiSearch size={20} />
              <input
                defaultValue=""
                placeholder="Search"
                className="w-full rounded outline-none font-nunito"
              />
            </div>
            <hr className="mt-2" />
          </div>
          <div className="pt-2 mx-[20px] overflow-y-auto">
            <div className="  text-base ">
            {billboardLocations.map((element, i) => {
                return (
                  <div
                    className="hover:bg-primary cursor-pointer hover:text-white py-3 w-full px-5 font-bold"
                    onClick={() => setTableData(element.id)}
                  >
                    <h1>{element.name}</h1>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Button
          content="+ Add billboard"
          loading={false}
          onClick={() => setOpenModal(!openModal)}
          btnColor="primary"
          Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
        />
      </div>
        {/* end ofleft panel */}
  
        {/* start of right panel */}
        <div className="bg-white rounded-lg lg:ml-[9px] w-full justify-center h-max md:pt-[12px] flex flex-row flex-wrap  space-x-2 shadow-lg">
          {myData.map((element) => (
            <div
              className="w-full md:max-w-sm md:w-1/2 rounded overflow-hidden shadow-lg h-[400px] max-h-[900px] mr-2 mt-4 "
              onClick={() => {
                setIsOpen(!isOpen);
                setData(element);
              }}
            >
              <div className="relative">
                <video
                  className="h-64 w-full object-cover"
                  src={element.video}
                  loop
                  ref={videoEl}
                  playsInline
                  muted
                  controls
                  autoPlay
                ></video>
              </div>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Billbord location</div>
                <p className="text-gray-700 text-base">{truncate(element.description, 40)}</p>
              </div>
              <div className="px-6">
              <Button
                content={element.available ? "Available" : "Not Available"}
                loading={false}
                btnColor={
                  element.available ? "primary" : "primary bg-opacity-30"
                }
                Style="text-white w-full h-[40px] mr-4 ml-0"
              />
              </div>
            </div>
          ))}
  
          {/* Dialogue*/}
  
          
        </div>
  
        <div
            // id="staticModal"
            data-modal-backdrop="static"
            tabindex="-1"
            aria-hidden="true"
            className={`fixed  ${
              isOpen ? "flex" : "hidden"
            }  p-4 overflow-x-hidden overflow-y-hidden w-[90%] h-screen top-16 items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm`}
          >
            <div className="relative w-full h-full max-w-2xl">
              <div className="relative bg-white rounded-lg shadow">
                <div className="flex items-start justify-between p-4 border-b rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    BillBoard details
                  </h3>
                  <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                    data-modal-hide="staticModal"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
  
                <div className="p-6 space-y-4 flex flex-col justify-between">
                  <div className="w-full flex flex-col justify-start">
                    <video
                      className="h-64 w-full object-cover"
                      src={data.video}
                      loop
                      ref={videoEl}
                      playsInline
                      muted
                      controls
                      autoPlay
                    ></video>
                  </div>
                  <div className="w-full mx-6">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      <p className="font-bold">Size Type</p>
                      <p className="italic">{data?.["size type"]}</p>
                      <p className="font-bold">Dimensions</p>
                      <p className="italic mb-2">{data?.Dimensions}</p>
                    </p>
                    <p className="font-bold">Price</p>
                    <p className="italic mb-2">{data?.price}</p>
  
                    <p className="text-base leading-relaxed w-full">
                      {data.description}
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-6 space-x-2  rounded-b dark:border-gray-600">
                  <p className="font-bold text-[18px]">status:</p>
                  <p className="text-lime-900 font-bold text-[18px]">available</p>
                </div>
              </div>
            </div>
          </div>
        {/* end of right panel */}

         {/*modal of displaying details of the billboard*/}

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        Style={
          "scroll-modal mt-8 flex mx-auto h-max max-h-[80%] w-max max-w-[60%] overflow-y-scroll rounded-lg"
        }
      >
        <Modal.Header>Billboard details</Modal.Header>
        <Modal.Body>
        <div className="relative bg-white rounded-lg shadow">
                <div className="flex items-start justify-between p-4 border-b rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    BillBoard details
                  </h3>
                </div>
  
                <div className="p-6 space-y-4 flex flex-col justify-between">
                  <div className="w-full flex flex-col justify-start">
                    <video
                      className="h-64 w-full object-cover"
                      src={data.video}
                      loop
                      ref={videoEl}
                      playsInline
                      muted
                      controls
                      autoPlay
                    ></video>
                  </div>
                  <div className="w-full mx-6">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      <p className="font-bold">Size Type</p>
                      <p className="italic">{data?.["size type"]}</p>
                      <p className="font-bold">Dimensions</p>
                      <p className="italic mb-2">{data?.Dimensions}</p>
                    </p>
                    <p className="font-bold">Price</p>
                    <p className="italic mb-2">{data?.price}</p>
  
                    <p className="text-base leading-relaxed w-full">
                      {data.description}
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-6 space-x-2  rounded-b dark:border-gray-600">
                  <p className="font-bold text-[18px]">status:</p>
                  <p className="text-lime-900 font-bold text-[18px]">available</p>
                </div>
              </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>Close</Modal.DismissButton>
        </Modal.Footer>
      </Modal>

      {/*end of the modal*/}  
  
         {/*modal of adding a billboard*/}
      <AddBillboard openModal={openModal} setOpenModal={setOpenModal} setToggleCreateSpace={setOpenModal} />
      {/*end of modal*/}
      </div>
    );
  
}

export default DigitalBillboard