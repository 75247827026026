import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import HamburgerButton from "./HamburgerMenuButton/HamburgerButton";
import Avatar from "react-avatar";
import Logout from "../modals/Logout";
import { FiLogOut } from "react-icons/fi";

const Sidebar = ({
  Menus,
  publisher,
  logoutModal,
  setLogoutModal,
}) => {
  const [open, setOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    setLogoutModal();
  };

  return (
    <>
      <Logout logoutModal={logoutModal} setLogoutModal={setLogoutModal} />
      <div
        className={`${
          open ? "w-60" : "w-fit"
        } hidden md:block relative h-screen duration-300 border-r bg-primary text-white  p-5`}
      >
        <BsArrowLeftCircle
          className={`${
            !open && "rotate-180"
          } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4 `}
          onClick={() => setOpen(!open)}
        />
        {/* <Link to='/'>
          <div className={`flex ${open && 'gap-x-4'} items-center`}>
            <img src={Logo} alt='' className='pl-2' />
            {open && (
              <span className='text-xl font-medium whitespace-nowrap '>
                Goal Quest
              </span>
            )}
          </div>
        </Link> */}

        <ul className="pt-6">
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer  hover:bg-gray-200 
                        ${menu.gap ? "mt-9" : "mt-2"} ${
                  location.pathname === menu.path && "bg-gray-200"
                }`}
              >
                <span className="text-2xl">{menu.src}</span>
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>

      {/* profile section*/}

      <div
        className={`flex fixed border-white border-y-[1px] justify-center items-center w-[220px] p-4 h-fit bottom-[50px] mb-4 ${
          open ? "block" : "hidden"
        }`}
      >
        <div className="flex gap-2 w-fit cursor-pointer justify-center items-center">
          <Avatar
            name={"Kayitare Prince"}
            round={true}
            size="50px"
            className=" font-nunito font-bold text-1xl self-center border "
            color="black"
          />
          <div className="flex flex-col ">
            <h1 className="font-nunito font-bold text-white text-base">
              Kayitare Prince
            </h1>
            <h2 className="font-nunito text-white text-xs opacity-70 cursor-pointer">
              View Profile
            </h2>
          </div>
        </div>
      </div>
      <div className=" fixed w-[220px] h-[25px] justify-start items-start bottom-4">
        <div
          className="flex w-fit mx-[10%] justify-start items-start cursor-pointer gap-2 pl-0"
          onClick={handleLogout}
        >
          <FiLogOut size={25} color="white" />
          <div className="">
            <h1
              className={`font-nunito font-bold text-base text-white ${
                open ? "flex" : "hidden"
              }`}
            >
              {" "}
              Logout
            </h1>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="pt-3">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="md:hidden">
        <div
          className={`${
            mobileMenu ? "flex" : "hidden"
          } absolute z-50 h-[50%] flex-col   py-4 mt-16 space-y-6 font-bold w-[90%] left-6 right-6  bg-primary text-white drop-shadow md rounded-xl`}
        >
          <div className="text-white w-fit mx-auto flex flex-col items-start">
          {Menus.map((menu, index) => (
            <Link
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <li
                className={`flex  items-start gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer hover:bg-error
                        ${menu.gap ? "mt-9" : "mt-2"} ${
                  location.pathname === menu.path && "bg-gray-200"
                }`}
              >
                <span className="text-2xl">{menu.src}</span>
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
          </div>

          {/* profile section*/}
          <div
        className={`flex fixed border-white border-y-[1px] justify-center items-center w-full p-4 h-fit bottom-[50px] mb-4 ${
          open ? "block" : "hidden"
        }`}
      >
        <div className="flex gap-2 w-fit cursor-pointer justify-center items-center">
          <Avatar
            name={"Kayitare Prince"}
            round={true}
            size="50px"
            className=" font-nunito font-bold text-1xl self-center border "
            color="black"
          />
          <div className="flex flex-col ">
            <h1 className="font-nunito font-bold text-white text-base">
              Kayitare Prince
            </h1>
            <h2 className="font-nunito text-white text-xs opacity-70 cursor-pointer">
              View Profile
            </h2>
          </div>
        </div>
      </div>
      <div className="flex fixed w-full h-[25px] justify-center items-center bottom-4">
        <div
          className="flex w-[40%] mx-[20%] justify-start items-start cursor-pointer gap-2 pl-0"
          onClick={handleLogout}
        >
          <FiLogOut size={25} color="white" />
          <div className="">
            <h1
              className={`font-nunito font-bold text-base text-white ${
                open ? "flex" : "hidden"
              }`}
            >
              {" "}
              Logout
            </h1>
          </div>
        </div>
      </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
