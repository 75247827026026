import React, { useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import Input from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import Drag from '../../../../components/Drag';
import Select from '../../../../components/Select';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import axios from 'axios';
import { ToastComponent, ToasterComponent } from '../../../../components/Toast';

const district = [
  { value: 1, label: 'Kigali' },
  { value: 2, label: 'Nyanza' },
  { value: 3, label: 'Nyamagabe' },
  { value: 4, label: 'Huye' },
  { value: 5, label: 'Gicumbi' },
  { value: 6, label: 'Rubavu' },
  { value: 7, label: 'Ruhango' },
  { value: 8, label: 'Muhanga' },
  { value: 9, label: 'Ngororero' },
  { value: 10, label: 'Gakenke' },
  { value: 11, label: 'Rusizi' },
  { value: 12, label: 'Ngoma' },
  { value: 13, label: 'Nyabihu' },
  { value: 14, label: 'Nyamasheke' },
  { value: 15, label: 'Gisagara' },
  { value: 16, label: 'Burera' },
  { value: 17, label: 'Rutsiro' },
  { value: 18, label: 'Karongi' },
  { value: 19, label: 'Musanze' },
  { value: 20, label: 'Rulindo' },
  { value: 21, label: 'Kirehe' },
  { value: 22, label: 'Gatsibo' },
  { value: 23, label: 'Ngororero' },
  { value: 24, label: 'Rwamagana' },
  { value: 25, label: 'Kamonyi' },
  { value: 26, label: 'Muhanga' },
  { value: 27, label: 'Nyagatare' },
  { value: 28, label: 'Bugesera' },
  { value: 30, label: 'Kabuye' },
  { value: 31, label: 'Kamonyi' },
  { value: 33, label: 'Kicukiro' },
  { value: 34, label: 'Muhanga' },
  { value: 35, label: 'Nyagatare' },
  { value: 36, label: 'Nyamagabe' },
  { value: 37, label: 'Nyanza' },
  { value: 38, label: 'Nyarugenge' },
  { value: 39, label: 'Ruhango' },
  { value: 40, label: 'Rusizi' },
  { value: 41, label: 'Rutsiro' },
  { value: 42, label: 'Rwamagana' },
];

const province = [
  { value: 1, label: 'Kigali' },
  { value: 2, label: 'Eastern' },
  { value: 3, label: 'Northern' },
  { value: 4, label: 'Southern' },
  { value: 5, label: 'Western' },
];

const AddRadioModal = ({ addRadio, setAddRadio,setToggleCreateSpace }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const notify = (type) => {
    ToastComponent({
      message: 'Success',
      type: type,
      successMessage: 'registered successfully',
      errorMessage: 'registration failed',
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const data = {
    Radio: {
      name: '',
      description: '',
      frequency: '',
      location: { province: '', district: '' },
      logo: '',
      sample: '',
    },
  };

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post('https://jsonplaceholder.typicode.com/posts', {
        space:'Radio',
        name: 'Flash FM',
        description: 'FLASH FM description',
        frequency: '88.8',
        location: { province: 'Kigali', district: 'Nyarugenge' },
        logo: files,
      })
      .then((res) => {
        setLoading(false);
        notify('success');
        console.log(res);
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* modal of adding radio */}
      <Modal
        isOpen={addRadio}
        onClose={() => setAddRadio(false)}
        Style={
          'scroll-modal  mt-8 mx-auto h-[90%] md:h-max md:max-h-[80%] w-[90%] md:w-[60%] md:max-w-[60%] overflow-y-scroll rounded-lg'
        }
      >
        <Modal.Header>Add radio</Modal.Header>
        <form
          action="#none"
          className="w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Body>
            <div
              class={`p-auto flex items-center top-0 justify-center left-0 w-full h-full `}
              
              role="dialog"
            >
              {/* form */}

              <div className="flex flex-col md:flex-row md:space-x-16 w-full">
                <div className="flex flex-col w-full md:w-1/2 space-y-4">
                  <Input
                    key="01"
                    labelText={'Radio name'}
                    labelFor={'Radio name'}
                    name={'Radio name'}
                    type={'text'}
                    isRequired={true}
                    placeholder={'enter radio name'}
                    customClass="w-full"
                    register={register}
                    errors={errors}
                  />{' '}
                  <TextArea
                    labelText={'Description'}
                    labelFor={'description'}
                    name={'description'}
                    isRequired={true}
                    placeholder={'Specify other reason ...'}
                    customClass={`w-full mt-4 visible `}
                    register={register}
                    errors={errors}
                  />
                  <Input
                    labelText={'Frequency'}
                    labelFor={'frequency'}
                    name={'frequency'}
                    type={'text'}
                    isRequired={true}
                    placeholder={'enter radio frequency'}
                    customClass="w-full"
                    register={register}
                    errors={errors}
                  />{' '}
                  <Select
                    className="w-full "
                    label={'Province'}
                    isLoading={false}
                    placeholder={'select province'}
                    options={province}
                    isRequired={true}
                    register={{
                      control,
                      name: 'province',
                      rules: { required: 'Province is required' },
                    }}
                    error={errors['province']?.message}
                  />
                  <Select
                    className="w-full "
                    label={'District'}
                    isLoading={false}
                    placeholder={'select district'}
                    defaultValue={'select show type'}
                    options={district}
                    isRequired={true}
                    register={{
                      control,
                      name: 'district',
                      rules: { required: 'District is required' },
                    }}
                    error={errors['district']?.message}
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <div>
                    <h2 className="font-bold">Upload logo:</h2>
                    <Drag
                      uploadLimit={0}
                      label={'This is the logo of the radio'}
                      onChange={(e) => console.log(e)}
                      files={files}
                      setFiles={setFiles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.DismissButton>
              <div onClick={() => 
                {
                  setToggleCreateSpace();}
                }>Close</div>
            </Modal.DismissButton>
            <Button
              type="submit"
              content="Save"
              loading={false}
              onClick={() => {
                console.log('clicked');
                // // setToggleCreateSpace();
                // setAddRadio(false);
              }}
              btnColor="primary"
              Style={
                'right-[0px] py-[1px] bottom-[0px] text-white w-fit px-8 m-2'
              }
            />
          </Modal.Footer>
        </form>
      </Modal>

      {/* end of add radio the modal*/}
    </div>
  );
};

export default AddRadioModal;
