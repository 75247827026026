import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from '../components/TopBar';
import SideBar from '../components/Sidebar';
import Home from '../pages/Home';
import Spaces from '../pages/space/Spaces';
import Campaigns from '../pages/Campaigns';
import Billing from '../pages/Billing';
import Dashboard from '../pages/dashboard/Dashboard';
import { AiOutlineHome, AiOutlineTablet } from 'react-icons/ai';
import { BiCube } from 'react-icons/bi';
import { FiDollarSign } from 'react-icons/fi';
import { RxDashboard } from 'react-icons/rx';
import Layout from '../components/Layout';


const PublisherRoutes = () => {
  const [activePage, setActivePage] = useState('Home');
  const [toggleCreateSpace, setToggleCreateSpace] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [hideSideBar, setHideSideBar] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };
  // const responsiveSideBar = () => {
  //   setResponsive(!responsive);
  // };

  const publisherSideBar = [
    { icon: AiOutlineHome, title: 'Home', to: '/' },
    { icon: BiCube, title: 'Spaces', to: '/space' },
    { icon: AiOutlineTablet, title: 'Campaigns', to: '/campaign' },
    { icon: FiDollarSign, title: 'Billing', to: '/billing' },
    { icon: RxDashboard, title: 'Dashboard', to: '/dashboard' },
  ];
  return (
    <>
      {/* <TopBar
        activePage={activePage}
        toggleCreateSpace={toggleCreateSpace}
        setToggleCreateSpace={() => {setToggleCreateSpace(!toggleCreateSpace)}}
        responsiveSideBar={responsiveSideBar}
        setShowSidebar={setShowSidebar}
        showNotification={showNotification}
        toggleNotification={toggleNotification}
      /> */}
      {/* <SideBar
        publisher={publisherSideBar}
        logoutModal={logoutModal}
        setLogoutModal={() => setLogoutModal(!logoutModal)}
        responsive={responsive}
        responsiveSideBar={responsiveSideBar}
        hideSideBar={hideSideBar}
        setHideSideBar={setHideSideBar}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      /> */}
      <Layout>
      <Routes>
        <Route path="/" element={<Home setActivePage={setActivePage} />} />
        <Route
          path="/space"
          element={<Spaces setActivePage={setActivePage} />}        
        />
        
        <Route
          path="/campaign"
          element={<Campaigns setActivePage={setActivePage} />}
        />
        <Route
          path="/billing"
          element={<Billing setActivePage={setActivePage} />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard setActivePage={setActivePage} />}
        />
      </Routes>
      </Layout>
    </>
  );
};

export default PublisherRoutes;
