import React from 'react';
import Modal from '../../components/modal/Modal';
import Button from '../../components/Button';

const CampaignDetails = ({ show, setShow }) => {
  return (
    <div className="font-nunito">
      <Modal
        isOpen={show}
        onClose={() => setShow()}
        Style={
          ' w-3/4 mt-8 max-h-[90%] mx-auto overflow:hidden pb-0 font-nunito'
        }
      >
        <Modal.Body >
          <div className=" p-4">
            <div className="w-full flex flex-col space-y-4">
              <div className="flex flex-row justify-between w-full items-center">
                <div className="flex flex-row gap-2 items-center">
                  <div className="max-w-[250px] h-[100px]">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg"
                      className="h-full w-full object-contain"
                      alt="logo"
                    />
                  </div>
                  <h2 className=" font-bold text-[24px]">MTN-Rwanda</h2>
                </div>
                <div>
                  <h2 className="font-bold text-lg px-4">Completed</h2>
                </div>
              </div>
              <h2 className="font-bold text-[24px]">Download the Ayoba App</h2>
              <p>
                We examine the top of funnel to figure out how to make people to
                a subscribe newsletter then turn prospects into leads, we
                examine the top of funnel to figure out how to make people to a
                subscribe newsletter then turn prospects into leads.
              </p>
              <video controls>
                <source src="https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4" type="video/mp4" />
                Your browser does not support the video element.
              </video>
              {/* {modalContent.type === 'image' ? (
              <div className="w-full 1/3">
                <img
                  src={modalContent.sample}
                  alt="sample"
                  className="w-full h-full"
                />
              </div>
            ) : modalContent.type === 'video' ? (
             
            ) : (
              <div className="flex flex-row items-end justify-center h-[300px] w-full bg-center bg-[url('https://img.freepik.com/free-vector/music-background-with-equalizer-speaker_1017-32860.jpg?w=1380&t=st=1678352704~exp=1678353304~hmac=acaff577f8fc12c73bce66d1a409bc739ba6b889e5b8db6e90f658277bbff229')] bg-cover">
                <audio controls>
                  <source src={modalContent.sample} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )} */}
            </div>

            <div className="flex flex-col  mt-2  w-3/4">
              <div className="flex flex-row  gap-1 items-center mt-1 ">
                <h1 className="text-light  font-bold w-fit ">Campaign type:</h1>
                <h2 className="text-black  ">Awareness</h2>
              </div>
              <div className="flex flex-row  gap-1 items-center mt-1 ">
                <h1 className="text-light  font-bold w-fit ">File format:</h1>
                <h2 className="text-black ">mp4</h2>
              </div>

              <div className="flex flex-row  gap-1 items-center mt-1">
                <h1 className="text-light  font-bold w-fit ">From:</h1>
                <h2 className="text-black ">01st/04/2023</h2>
              </div>

              <div className="flex flex-row  gap-1 items-center mt-1 ">
                <h1 className="text-light  font-bold  w-fit ">To:</h1>
                <h2 className="text-black  ">23rd/04/2023</h2>
              </div>

              <div className="flex flex-col gap-2 mt-4">
                <div className="text-light flex flex-row justify-between items-center">
                  <h1 className="font-bold  w-fit ">Time</h1>
                  <h2 className=" font-bold">Amount</h2>
                </div>

                <div className="flex flex-row justify-between items-center">
                  <h1 className="">
                    Monday : <span>06:00 am - 08:00 am</span>
                  </h1>
                  <h1 className="text-black font-bold">133,000 Rwf</h1>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <h1 className="">
                    Tuesday : <span>06:00 am - 08:00 am</span>
                  </h1>
                  <h1 className="text-black font-bold">133,000 Rwf</h1>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <h1 className="">
                    Friday : <span>06:00 am - 08:00 am</span>
                  </h1>
                  <h1 className="text-black font-bold">133,000 Rwf</h1>
                </div>
                {/* <div className="flex flex-col space-y-4">
                    <h2 className="text-[20px] font-[800]">Time</h2>
                    <ul>
                      {modalContent?.duration?.map((duration) => {
                        return (
                          <li>
                            {duration?.day} {duration?.start} - {duration?.end}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <h2 className="text-[20px] font-[800]">Amount</h2>
                    <ul>
                      {modalContent?.duration?.map((duration) => {
                        return <li>{duration?.amount}</li>;
                      })}
                    </ul>
                  </div> */}
              </div>

              <div className="flex flex-row  gap-1 items-center mt-4 ">
                <h1 className="text-light  font-bold w-fit">Payment Status:</h1>
                <h2 className="text-black font-bold">Paid</h2>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>Close</Modal.DismissButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CampaignDetails;
