import React, {useEffect} from 'react';
import Input from '../../components/Input';
import { useForm } from 'react-hook-form';
import MultiSelect from '../../components/MultiSelect';

const options = [
  { value: 'option1', label: 'Konvey Direct' },
  { value: 'option2', label: 'MTN-Rwanda' },
  { value: 'option3', label: 'Airtel-Rwanda' },
  { value: 'option4', label: 'RBA' },
  { value: 'option5', label: 'TV1 & R1' },
  { value: 'option6', label: 'Aflink' },
  { value: 'option7', label: 'BTN' },
  { value: 'option8', label: 'Rwanda Foam' },
  { value: 'option9', label: 'Prime TV' },
  { value: 'option10', label: 'TV10 & R10' },
];

const Step2 = ({ profile, setProfile, register, errors}) => {
  return (
    <div className="bg-secondary">
      <div
        className={`flex  bg-secondary w-fit h-fit font-nunito pt-16`}
      >
        <div className="flex flex-col w-full h-full  ">
          <h1 className="text-[20px]  lg:text-[24px] xlg:text-[24px] xl:text-[24px]">
            Enter your personal information
          </h1>
          <Input
            labelText={'Name'}
            labelFor={'name'}
            name={'inputName'}
            type={'text'}
            isRequired={true}
            placeholder={'enter your name'}
            customClass="w-full mb-2 mt-4"
            onChange={(e) => {
              e.target.value.length > 0 ? errors.inputName && delete errors.inputName : errors.inputName = {message: 'Name is required'}
              setProfile((prevData) => {
                return {
                  ...prevData,
                  name: e.target.value,
                };
              });
            }}
            register={register}
            value={profile.name || ''}
            errors={errors}
          />{' '}
          <Input
            labelText={'Telephone'}
            labelFor={'telephone'}
            name={'tel'}
            type={'text'}
            isRequired={true}
            placeholder={'+250 788 888 809'}
            customClass="w-full mb-2"
            onChange={(e) => {
              e.target.value.length > 0 ? errors.tel && delete errors.tel : errors.tel = {message: 'Telephone is required'}
              setProfile((prevData) => {
                return {
                  ...prevData,
                  name: prevData.name,
                  tel: e.target.value,
                };
              });
            }}
            register={register}
            value={ profile.tel || ''}
            errors={errors}
          />{' '}
          <Input
            labelText={'Email'}
            labelFor={'email'}
            name={'email'}
            type={'email'}
            isRequired={true}
            placeholder={'example@gmail.com'}
            customClass="w-full mb-2"
            onChange={(e) => {
              e.target.value.length > 0 ? errors.email && delete errors.email : errors.email = {message: 'Email is required'}
              setProfile((prevData) => {
                return {
                  ...prevData,
                  name: prevData.name,
                  tel: prevData.tel,
                  email: e.target.value,
                };
              });
            }}
            register={register}
            value={profile.email}
            errors={errors}
          />{' '}
        </div>
      </div>
    </div>
  );
};

export default Step2;
