import React, { useState, useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Button from '../../components/Button';
import { useDispatch, useSelector } from "react-redux";
import { FaLongArrowAltLeft } from 'react-icons/fa';
import CompanyStep2 from './company/CompanyStep2';
import CompanyStep3 from './company/CompanyStep3';
import CompanyStep4 from './company/CompanyStep4';
import CompanyStep5 from './company/CompanyStep5';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";


const StepCombiner = ({ stepCount, incrementStepper, decrementStepper }) => {
  const [companyType, setCompanyType] = useState('');
  const [disabled, setDisabled] = useState(false)
  const [buttonClicked , setButtonClicked] = useState(false)

  const formSchema = Yup.object().shape({
    inputName: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Email is invalid")
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
      .required("Email is required"),
    tel: Yup.string().required("Telephone is required"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const setCompany = (type) => {
    setCompanyType(type);
  };
  const [profile, setProfile] = useState({
    name: '',
    tel: '',
    email: '',
    address: [{ name: '' }],
    socialMedia: [{ name: '', link: '' }],
    profile: [{ image: '', background: '' }],
    bio: '',
    links: [{ title: '', link: '', description: '' }],
    website: '',
    services: [{ name: '', description: '' }],
    clients: '',
  });

  const [companyProfile, setCompanyProfile] = useState({
    companyName: '',
    telephone: '',
    industryType: '',
    location:[{name:''}],
    socialMedia: [{ name: '', link: '' }],
    email: '',
    description: '',
    logo: '',
    services:[{name:'',description:''}],
    clients:'',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const onSubmit = () => {
   if((stepCount===1 || stepCount===2 || stepCount===3)  && (!buttonClicked) ){
    console.log(buttonClicked,'clicked next button')
      incrementStepper()
    }
    else{
        console.log(buttonClicked,'clicked save button')
      axios
      .post('https://jsonplaceholder.typicode.com/posts', profile)
      .then((res) => {
        // setLoading(false);
        notify('success', 'Profile created successfully');
        console.log(res,'.............................................');
        setButtonClicked(false)
        setProfile({
          name: '',
          tel: '',
          email: '',
          address: [{ name: '' }],
          socialMedia: [{ name: '', link: '' }],
          profile: [{ image: '', background: '' }],
          bio: '',
          links: [{ title: '', link: '', description: '' }],
          website: '',
          services: [{ name: '', description: '' }],
          clients: '',
        })
      })
      .catch((err) => {
        console.log(err);
      });
    }
    };  

    useEffect(() => {}, [buttonClicked]);

  return (
    <div className=" w-full h-full">
      <div
        className={`${
          stepCount === 4 ? 'w-full' : 'w-fit'
        } h-fit mx-auto px-4 `}
      >
        <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
          {stepCount === 0 && (
            <Step1
              incrementStepper={incrementStepper}
              setCompany={setCompany}
            />
          )}
          {stepCount === 1 && 
            (
              companyType === 'individual' ? (
              <Step2
                companyType={companyType}
                profile={profile}
                setProfile={setProfile}
                register={register}
                errors={errors}
                // setDisabled={setDisabled}
                // disabled={disabled}
              />
            ):(
              <CompanyStep2 companyProfile={companyProfile} setCompanyProfile={setCompanyProfile}/>
            )
            )
          }
          {stepCount === 2 && (
            companyType === 'individual' ? (
              <Step3
              companyType={companyType}
              profile={profile}
              setProfile={setProfile}
            />
            ):(
              <CompanyStep3 companyProfile={companyProfile} setCompanyProfile={setCompanyProfile}/>
            )
          )}
          {stepCount === 3 && (
            companyType === 'individual' ? (
              <Step4
              companyType={companyType}
              profile={profile}
              setProfile={setProfile}
            />
            ):(
              <CompanyStep4 companyProfile={companyProfile} setCompanyProfile={setCompanyProfile}/>
            )
          )}
          {stepCount === 4 && (
           companyType === 'individual' ? (
            <Step5
            companyType={companyType}
            profile={profile}
            setProfile={setProfile}
          />
          ):(
            <CompanyStep5 companyProfile={companyProfile} setCompanyProfile={setCompanyProfile}/>
          )
          )}
          <div
            className={`flex flex-col ${
              stepCount === 0 ? 'hidden' : ' visible'
            }`}
          >
            <div className="flex flex-row justify-between pt-8 py-4">
              <Button
                type="submit"
                content="Save and continue later"
                loading={false}
                onClick={()=>setButtonClicked(true)}
                btnColor="white"
                Style={`${
                  stepCount === 4 ? ' hidden' : ' visible'
                } text-fontColor w-fit h-[40px] mt-2 ml-0 border border-primary`}
              />
              <Button
                type={`${stepCount === 4 || stepCount===1 ? 'submit' : 'Next'}`}
                content={`${stepCount === 4 ? 'Submit' : 'Next'}`}
                loading={false}
                btnColor="primary "
                Style={`text-white w-fit h-[40px] mt-2 ml-0 ${disabled ? 'cursor-not-allowed':''}`}
                
              />
            </div>
            <Button
              type="button"
              content={
                <div className="flex flex-row gap-2 items-center">
                  <FaLongArrowAltLeft />
                  <h1 className="font-semibold text-fontColor">Back </h1>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'hover:bg-[#F0F0F0] w-fit'}
              onClick={decrementStepper}
            />
            <ToasterComponent />
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepCombiner;
