export const notifications = [
  {
    name: 'MTN',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg',
    type: 'payment',
    campaign: 'MTN Izihirwe',
    time: {
      date: 'today',
      time: '1h ago',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '18/02/23',
      time: '12:04',
    },
  },
  {
    name: 'TV1 & R1',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRbsXZCy2xuM5DZvVMWw_iiv1WIGI7LiLqxZf9mOCC3A&s',
    type: 'offer',
    campaign: 'Didoz eat and win',
    time: {
      date: '10/02/23',
      time: '08:04',
    },
  },
  {
    name: 'RBA',
    logo: 'https://www.rba.co.rw/admin/user_data/profile/40e3a.jpg',
    type: 'payment',
    campaign: 'Download RBA App',
    time: {
      date: 'today',
      time: '1h ago',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '18/02/23',
      time: '12:04',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '10/02/23',
      time: '08:04',
    },
  },
  {
    name: 'MTN',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg',
    type: 'payment',
    campaign: 'MTN Izihirwe',
    time: {
      date: 'today',
      time: '1h ago',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '18/02/23',
      time: '12:04',
    },
  },
  {
    name: 'TV1 & R1',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRbsXZCy2xuM5DZvVMWw_iiv1WIGI7LiLqxZf9mOCC3A&s',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '10/02/23',
      time: '08:04',
    },
  },
];

export const Links = [
  {
    title: 'This my first link , click me, get to link!!',
    link: 'https://www.youtube.com/watch?v=QH2-TGUlwu4',
    description:
      'lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
  {
    title: 'This my first link , click me, get to link!!',
    link: 'https://www.youtube.com/watch?v=QH2-TGUlwu4',
    description:
      'lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
  {
    title: 'This my first link , click me, get to link!!',
    link: 'https://www.youtube.com/watch?v=QH2-TGUlwu4',
    description:
      'lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
];

export const Services = [
  {
    service: 'service 1',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
  {
    service: 'service 2',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
  {
    service: 'service 3',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
  {
    service: 'service 4',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
  {
    service: 'service 5',
    description:
      'lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit lorem ipsum dolor sit amet consectetur adipiscing elit',
  },
];


export const Clients = [
  {
    name:'Bralirwa',
    logo:'https://bralirwa.co.rw/frontend/assets/img/brlogogreen.jpeg'
  },
  {
    name:'RBA',
    logo:'https://www.jobinrwanda.com/sites/default/files/styles/medium/public/employer_logos/logo_542886536.png?itok=bfQVhJWq'
  },
  {
    name:'Airtel-Rwanda',
    logo:'https://www.jobinrwanda.com/sites/default/files/styles/medium/public/employer_logos/logo_1136584422.png?itok=c8R-48ou'
  },
  {
    name:'MTN-Rwanda',
    logo:'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg'
  },
  {
    name:'Rwanda Foam Sofas',
    logo:'https://rwandafoam.com/wp-content/uploads/2022/09/aosk6Jq-600x600-2.png'
  },
]
export const campaigns = [
  {
    id: 1,
    name: 'Rwanda Foam Sofas',
    client: { name: 'Rwanda Foam', logo:'https://rwandafoam.com/wp-content/uploads/2022/09/aosk6Jq-600x600-2.png' },
    type: 'SMS Broadcast',
    space: ['Billboard','Digital Billboard','Radio'],
    amount: '723,112 Rwf',
    status: 'on going',
  },
  {
    id: 2,
    name: 'Ayoba App',
    client: { name: 'MTN-Rwanda', logo:'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg' },
    type: 'awareness',
    space: ['Billboard', 'Tv','Radio'],
    amount: '1,009,501 Rwf',
    status: 'pending',
  },
  {
    id: 3,
    name: 'MTN Izihirwe',
    client: { name: 'MTN-Rwanda', logo:'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg' },
    type: 'awareness',
    space: ['Billboard','Tv'],
    amount: '809,319 Rwf',
    status: 'completed',
  },
  {
    id: 4,
    name: 'Tour Du Rwanda',
    client: { name: 'Tour Du Rwanda', logo:'https://pbs.twimg.com/media/E0pk17RWQAEYbaY.jpg' },
    type: 'awareness',
    space: ['Billboard'],
    amount: '723,112 Rwf',
    status: 'completed',
  },
  {
    id: 5,
    name: 'Rwanda Foam Sofas',
    client: { name: 'Rwanda Foam', logo:'https://rwandafoam.com/wp-content/uploads/2022/09/aosk6Jq-600x600-2.png' },
    type: 'awareness',
    space: ['Billboard'],
    amount: '23,112 Rwf',
    status: 'rejected',
  },
  {
    id: 6,
    name: 'Primus Guma Guma',
    client: { name: 'PGGS', logo:'https://www.musicinafrica.net/sites/default/files/styles/profile_images_large/public/images/music_professional_profile/201408/primusgumagumalogoedit.jpg?itok=Y5h2MsxU' },
    type: 'awareness',
    space: ['Radio','Tv'],
    amount: '2,029,802 Rwf',
    status: 'completed',
  },
];

