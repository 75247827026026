import React from 'react';
import { HiPlus } from 'react-icons/hi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsBell } from 'react-icons/bs';
import Notification from '../modals/Notification';
import Button from './Button';
import CreateSpace from '../pages/CreateSpace';

const TopBar = ({
  activePage,
  setToggleCreateSpace,
  toggleCreateSpace,
  toggleNotification,
  showNotification,
}) => {
  const btnAction = () => {
    if (
      activePage === 'Campaigns' ||
      activePage === 'Spaces' ||
      activePage === 'Home' ||
      activePage === 'Dashboard' ||
      activePage === 'Billing'
    ) {
      return setToggleCreateSpace();
    } else {
      console.log('otherBtnAction');
    }
  };

  const btnText = () => {
    if (
      activePage === 'Spaces' ||
      activePage === 'Campaigns' ||
      activePage === 'Home' ||
      activePage === 'Dashboard' ||
      activePage === 'Billing'
    ) {
      return (
        <div className="flex gap-1 items-center" onClick={btnAction}>
          <HiPlus color="#fff" />
          <div className="hidden lg:flex">
            <h1 className="text-white">Create Space</h1>
          </div>
        </div>
      );
    } else if (activePage === 'Home') {
      return (
        <div className="flex gap-1 items-center">
          <HiPlus color="#fff" />
          <div className="hidden lg:flex">
            <h1 className="text-white">Create Space</h1>
          </div>
        </div>
      );
    } else if (activePage === 'Audience') {
      return '+ Add Audience';
    } else if (activePage === 'Billing') {
      return '+ Create Campaign';
    } else if (activePage === 'Dashboard') {
      return '+ Create Campaign';
    }
  };
  return (
    <>
      <div className="w-full">
        <CreateSpace
          toggleCreateSpace={toggleCreateSpace}
          setToggleCreateSpace={setToggleCreateSpace}
        />
      </div>
      <Notification
        toggleNotification={toggleNotification}
        showNotification={showNotification}
      />
      <div className="top-0  bg-white flex flex-row justify-between items-center h-[64px] shadow-md  topbar font-nunito px-8">
        <div className="flex flex-row gap-8 items-center">
          <h3 className="text-light font-bold g text-lg ml-8">{activePage}</h3>
        </div>
        <div className="flex items-center gap-4">
          <Button
            type="button"
            content={btnText()}
            loading={false}
            onClick={btnAction}
            btnColor="primary"
            Style="text-white h-[40px] mt-2 ml-0 "
          />

          <BsBell
            size={20}
            className="cursor-pointer"
            onClick={() => {
              toggleNotification();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TopBar;
