import React, { useState, useEffect } from "react";
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import { useForm } from "react-hook-form";
import Drag from "../../../../components/Drag";
import axios from "axios";
import { ToastComponent, ToasterComponent } from "../../../../components/Toast";
import { AiFillMinusCircle } from "react-icons/ai";
import { HiPlus } from "react-icons/hi";

const dimensions = [
  { value: 1, label: "2.6x3xm" },
  { value: 2, label: "2.7x3xm" },
  { value: 3, label: "2.8x3xm" },
  { value: 4, label: "2.9x3xm" },
  { value: 5, label: "Other" },
];

const size = [
  { value: 1, label: "Small" },
  { value: 2, label: "Medium" },
  { value: 3, label: "Large" },
  { value: 4, label: "X-large" },
];

const billboardTime = [
  { value: 1, label: "Prime time" },
  { value: 2, label: "Non-prime time" },
];

const AddDigitalBillboard = ({
  openModal,
  setOpenModal,
  setToggleCreateSpace,
}) => {
  const [videoFiles, setVideoFiles] = useState([]);
  const [data, setData] = useState({
    name: "",
    location: "",
    size: 0,
    dimension: 0,
    pricing: [{ time: "", slot: "", unit: "", pricePerUnit: "" }],
    videos: [],
  });

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const addPricing = () => {
    setData((prevData) => {
      return {
        ...prevData,
        pricing: [
          ...prevData.pricing,
          { time: "", slot: "", unit: "", pricePerUnit: "" },
        ],
      };
    });
  };

  const handlePricingTime = (e, index) => {
    const { value } = e;
    console.log(value, "value");
    setData((prevData) => {
      const editPricing = [...prevData.pricing];
      editPricing[index].time = value;
      return {
        ...prevData,
        pricing: editPricing,
      };
    });
  };

  const handlePricingSlot = (e, index) => {
    const { value } = e.target;
    console.log(value, "value");
    setData((prevData) => {
      const editPricing = [...prevData.pricing];
      editPricing[index].slot = value;
      return {
        ...prevData,
        pricing: editPricing,
      };
    });
  };

  const handlePricingUnit = (e, index) => {
    const { value } = e.target;
    console.log(value, "value");
    setData((prevData) => {
      const editPricing = [...prevData.pricing];
      editPricing[index].unit = value;
      return {
        ...prevData,
        pricing: editPricing,
      };
    });
  };

  const handlePricingPerUnit = (e, index) => {
    const { value } = e.target;
    console.log(value, "value");
    setData((prevData) => {
      const editPricing = [...prevData.pricing];
      editPricing[index].pricePerUnit = value;
      return {
        ...prevData,
        pricing: editPricing,
      };
    });
  };

  const handleRemovePrice = (index) => {
    setData((prevData) => {
      const updatedPricing = [...prevData.pricing];
      updatedPricing.splice(index, 1);
      return {
        ...prevData,
        pricing: updatedPricing,
      };
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = () => {
    axios
      .post("https://jsonplaceholder.typicode.com/posts", data)
      .then((res) => {
        console.log(res);
        notify("success", "Billboard added successfully");
        reset();
      })
      .catch((err) => {
        console.log(err);
        notify("error", "Failed to add billboard");
      });
  };

  useEffect(() => {
    setData({ ...data, videos: videoFiles });
  }, [videoFiles]);

  return (
    <Modal
      isOpen={openModal}
      onClose={() => setOpenModal(false)}
      Style={
        "scroll-modal  mt-8 flex mx-auto w-[95%] h-[90%] md:h-max md:max-h-[95%] md:w-[90%] lg:w-[60%] md:max-w-[80%] overflow-y-scroll rounded-lg font-nunito"
      }
    >
      <form
        action=""
        method="post"
        className="w-full px-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Header>Add billboard</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col items-center space-y-4 w-full">
            <Input
              key="01"
              labelText={"Name"}
              labelFor={"name"}
              id="01"
              name={"name"}
              type={"text"}
              isRequired={true}
              placeholder={"enter billboard name"}
              customClass="w-full"
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              register={register}
              errors={errors}
            />{" "}
            <Input
              key="02"
              labelText={"Location"}
              labelFor={"location"}
              id="02"
              name={"location"}
              type={"text"}
              isRequired={true}
              placeholder={"enter billboard location"}
              customClass="w-full"
              onChange={(e) => {
                setData({ ...data, location: e.target.value });
              }}
              register={register}
              errors={errors}
            />{" "}
            <div className="w-full">
              <Select
                key="03"
                className="w-full"
                label={"Size type"}
                isLoading={false}
                placeholder={"select size"}
                defaultValue={""}
                onChange={(e) => {
                  console.log(e);
                  setData({ ...data, size: e.value });
                }}
                options={size}
                error={errors["size"]?.message}
              />
            </div>
            <div className="w-full flex flex-col space-y-4">
              <Select
                key="04"
                className="w-full"
                label={"Dimension"}
                isLoading={false}
                placeholder={"select dimension"}
                defaultValue={""}
                onChange={(e) => {
                  setData({ ...data, dimension: e.value });
                }}
                options={dimensions}
                error={errors["dimension"]?.message}
              />
              {data.dimension === 5 && (
                <Input
                key="05"
                labelText={"Dimension"}
                labelFor={"dimension"}
                id="05"
                name={"dimension"}
                type={"text"}
                isRequired={true}
                placeholder={"ex: height x width"}
                customClass="w-full"
                onChange={(e) => {
                  setData({ ...data, dimension: e.target.value });
                }}
                register={register}
                errors={errors}
              />
              )}
            </div>
            <div className="flex flex-col w-full">
              <div>Pricing</div>
              {data.pricing.map((item, index) => {
                return (
                  <div className="flex flex-col md:flex-row md:space-x-4 w-full">
                    <Select
                      key="04"
                      className="w-full"
                      label={"Time"}
                      isLoading={false}
                      placeholder={"select"}
                      defaultValue={""}
                      onChange={(e) =>{ 
                        handlePricingTime(e, index)
                        billboardTime.splice(index, 1)
                      }}
                      options={billboardTime}
                      error={errors["time"]?.message}
                    />
                    <Input
                      labelText={"slot name"}
                      labelFor={`slot name`}
                      name={`slot name ${index}}`}
                      type={"text"}
                      isRequired={true}
                      placeholder={"display"}
                      value={item.slot}
                      customClass="md:w-1/4 w-full"
                      register={register}
                      errors={errors}
                      onChange={(e) => handlePricingSlot(e, index)}
                    />{" "}
                    <Input
                      labelText={"unit"}
                      labelFor={`unit `}
                      name={`unit ${index}`}
                      type={"text"}
                      isRequired={true}
                      placeholder={"seconds"}
                      value={item.unit}
                      customClass="md:w-1/4 w-full"
                      register={register}
                      errors={errors}
                      onChange={(e) => handlePricingUnit(e, index)}
                    />{" "}
                    <Input
                      labelText={"price/ unit"}
                      labelFor={`price per unit`}
                      name={`price per unit ${index}`}
                      type={"text"}
                      isRequired={true}
                      placeholder={"100"}
                      value={item.pricePerUnit}
                      customClass="md:w-1/4 w-full"
                      register={register}
                      errors={errors}
                      onChange={(e) => handlePricingPerUnit(e, index)}
                    />{" "}
                    <div className="w-full md:w-max flex justify-end items-end p-0">
                    <AiFillMinusCircle
                      color="#BDBDBD"
                      size={25}
                      className={`cursor-pointer ${
                        index === 0 ? "hidden" : "visible"
                      }`}
                      onClick={() => handleRemovePrice(index)}
                    />
                    <Button
                      type="button"
                      content={<HiPlus color="#FBFBFB" size={20} />}
                      loading={false}
                      onClick={addPricing}
                      btnColor="primary"
                      Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] h-8 m-2 my-8 ${
                        index >= 1 || data.pricing.length === 2 ? "hidden" : "visible"
                      }`}
                    />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col w-full">
              <div>
                <h2 className="font-bold">Upload video:</h2>
                <Drag
                  label={"Upload one or more videos of a billboard"}
                  files={videoFiles}
                  setFiles={setVideoFiles}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <div onClick={() => setToggleCreateSpace()}>Close</div>
          </Modal.DismissButton>
          <Button
            content="Save"
            loading={false}
            type="submit"
            btnColor="primary"
            Style="text-white w-max h-[40px]  mx-4"
          />
        </Modal.Footer>
        <ToasterComponent />
      </form>
    </Modal>
  );
};

export default AddDigitalBillboard;
