import React, { useState } from 'react';
import DifLocation from './DifLocation';
import SameLocation from './SameLocation';
const Multidays = ({ event, setEvent, sameLocationValue,setSameLocationValue }) => {
  const [eventLocation, setEventLocation] = useState('same');

  const handleChangeEventLocation = (event) => {
    setEventLocation(event.target.value);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between lg:justify-start lg:gap-12 mt-4">
        <div className="flex items-center gap-1">
          <input
            type="radio"
            id="scheduleChoice1"
            name="scheduleChoice1"
            value="same"
            checked={eventLocation === 'same'}
            onChange={handleChangeEventLocation}
          />
          <label for="scheduleChoice1">Same location</label>
        </div>
        <div className="flex items-center gap-1">
          <input
            type="radio"
            id="scheduleChoice1"
            name="scheduleChoice1"
            value="different"
            checked={eventLocation === 'different'}
            onChange={handleChangeEventLocation}
          />
          <label for="scheduleChoice1">Different location</label>
        </div>
      </div>
      {eventLocation === 'same' ? (
        <SameLocation event={event} setEvent={setEvent} sameLocationValue={sameLocationValue} setSameLocationValue={setSameLocationValue}/>
      ) : eventLocation === 'different' ? (
        <DifLocation event={event} setEvent={setEvent} />
      ) : (
        ''
      )}
    </div>
  );
};

export default Multidays;
