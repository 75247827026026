import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/modal/Modal';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import { useForm } from 'react-hook-form';
import Drag from '../../../../components/Drag';
import AccountDetails from './AccountDetails';
import axios from 'axios';
import { ToastComponent, ToasterComponent } from '../../../../components/Toast';

const dimensions = [
  { value: 1, label: '2.6x3xm' },
  { value: 2, label: '2.7x3xm' },
  { value: 3, label: '2.8x3xm' },
  { value: 4, label: '2.9x3xm' },
];

const size = [
  { value: 1, label: 'Twitter' },
  { value: 2, label: 'Instagram' },
  { value: 3, label: 'Facebook' },
  { value: 4, label: 'linkedIn' },
  { value: 5, label: 'TikTok' },
  { value: 6, label: 'Youtube' },
];

const AddAccount = ({ show, setShow }) => {
  const [accountDescriptionModal, setAccountDescriptionModal] = useState(false);
  const [billboardImage, setBillboardImage] = useState([]);
  const [data, setData] = useState({
    name: '',
    location: '',
    size: 0,
    dimension: 0,
    images: [],
  });

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const onSubmit = () => {
    axios
      .post('https://jsonplaceholder.typicode.com/posts', data)
      .then((res) => {
        console.log(res);
        notify('success', 'Billboard added successfully');
        reset();
      })
      .catch((err) => {
        console.log(err);
        notify('error', 'Failed to add billboard');
      });
  };

  useEffect(() => {
    setData({ ...data, images: billboardImage });
  }, [billboardImage]);

  return (
    <>
      <Modal
        isOpen={show}
        onClose={() => setShow()}
        Style={
          'm-auto max-h-[90%] w-[95%] lg:w-[30%] max-w-[60%] overflow-y-auto rounded-lg font-nunito'
        }
      >
        <form
          action=""
          method="post"
          className="w-full px-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header>
            <h1 className="font-bold text-xl">Add Account</h1>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col items-center w-full">
              <p className="pb-4">
                Choose your platform in the dropdown below and continue to
                authenticate yourself.
              </p>
              <div className="w-full">
                <Select
                  key="03"
                  className="w-full"
                  label={'Select Platform'}
                  isLoading={false}
                  placeholder={'select ...'}
                  defaultValue={''}
                  onChange={(e) => {
                    setData({ ...data, size: e.value });
                  }}
                  options={size}
                  error={errors['size']?.message}
                />
              </div>
              {/* <div className="flex flex-col w-full">
              <div>
                <h2 className="font-bold">Upload image:</h2>
                <Drag
                  label={'Upload one or more images of a billboard'}
                  files={billboardImage}
                  setFiles={setBillboardImage}
                />
              </div>
            </div> */}

              <div className="flex flex-row w-full justify-end mt-16">
                <div className="flex flex-row gap-4">
                  <Button
                    content="Close"
                    loading={false}
                    btnColor="white"
                    Style="text-black w-max h-[40px]"
                    onClick={() => setShow()}
                  />
                  <Button
                    content="Continue"
                    loading={false}
                    btnColor="primary"
                    Style="text-white w-max h-[40px]"
                    onClick={() =>
                      setAccountDescriptionModal(!accountDescriptionModal)
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <ToasterComponent />
        </form>
      </Modal>
      <AccountDetails
        show={accountDescriptionModal}
        closeFirstModal={setShow}
        setShow={() => setAccountDescriptionModal(!accountDescriptionModal)}
      />
    </>
  );
};

export default AddAccount;
