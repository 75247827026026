import React from 'react';
import Select from '../../../../../components/Select';
import Button from '../../../../../components/Button';
import { AiFillMinusCircle } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';
import Input from '../../../../../components/Input';
import { useForm } from 'react-hook-form';

const DifDays = ({ createShow, setCreateShow }) => {
  const days = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
  ];

  const addDifDays = () => {
    setCreateShow((prevData) => {
      return {
        ...prevData,
        difDaySchedule: [
          ...prevData.difDaySchedule,
          { day: '', from: '', to: '' },
        ],
      };
    });
  };
  const handleDifDay = (day, index) => {
    setCreateShow((prevData) => {
      const editDay = [...prevData.difDaySchedule];
      editDay[index].day = day;
      return {
        ...prevData,
        difDaySchedule: editDay,
      };
    });
  };

  const handleDifStart = (e, index) => {
    const { value } = e.target;
    setCreateShow((prevData) => {
      const editStart = [...prevData.difDaySchedule];
      editStart[index].start = value;
      return {
        ...prevData,
        difDaySchedule: editStart,
      };
    });
  };
  const handleDifEnd = (e, index) => {
    const { value } = e.target;
    setCreateShow((prevData) => {
      const editEnd = [...prevData.difDaySchedule];
      editEnd[index].end = value;
      return {
        ...prevData,
        difDaySchedule: editEnd,
      };
    });
  };

  const handleRemoveDifDays = (index) => {
    setCreateShow((prevData) => {
      const updatedDifDays = [...prevData.difDaySchedule];
      updatedDifDays.splice(index, 1);
      return {
        ...prevData,
        difDaySchedule: updatedDifDays,
      };
    });
  };

  const {
    register,
    formState: { errors },
    reset,
    control,
  } = useForm();
  return (
    <div>
      {createShow.difDaySchedule.map((item, index) => {
        return (
          <div className="flex flex-col w-full md:flex-row justify-between" key={index}>
            <div className="flex items-center">
              <Select
                className="w-[100%]"
                label={'Days'}
                isLoading={false}
                placeholder={'Select day'}
                options={days}
                register={{
                  control,
                  name: 'days',
                  rules: { required: 'Select days' },
                }}
                error={errors['days']?.message}
                onChange={(e) => handleDifDay(e, index)}
                value={item.day}
              />
            </div>
            <div class="flex items-center">
              <Input
                labelText={'start time'}
                labelFor={'start'}
                name={'start'}
                type={'time'}
                isRequired={true}
                placeholder={'start time'}
                customClass="w-full md:w-max"
                onChange={(e) => {
                  handleDifStart(e, index);
                }}
                register={register}
                errors={errors}
                value={item.start}
              />{' '}
            </div>
            <div class="flex items-center">
              <Input
                labelText={'end time'}
                labelFor={'to'}
                name={'to'}
                type={'time'}
                isRequired={true}
                placeholder={'end time'}
                customClass="w-full md:w-max"
                onChange={(e) => {
                  handleDifEnd(e, index);
                }}
                register={register}
                errors={errors}
                value={item.end}
              />{' '}
            </div>
            <div className="flex w-full flex-row space-x-1 items-end justify-end">
              <AiFillMinusCircle
                color="#BDBDBD"
                size={25}
                className={`cursor-pointer ${
                  index === 0 ? 'hidden' : 'visible'
                }`}
                onClick={() => handleRemoveDifDays(index)}
              />
              <Button
                type="button"
                content={<HiPlus color="#FBFBFB" size={20} />}
                loading={false}
                onClick={addDifDays}
                btnColor="primary"
                Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit h-fit px-[2px] m-2 ${
                  index !== createShow.difDaySchedule.length - 1
                    ? 'hidden'
                    : 'visible'
                }`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DifDays;
